import { useEffect } from "react";
import { getImage } from "../../Utils";
import { Tab } from '@headlessui/react'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className="mt-24 mb-24 md:mb-48 md:mt-32">
            <div className="container mx-auto px-6 md:px-12">
                <div className="text-left">
                    <div className="mt-12 md:text-lg">
                        <h1 className="font-semibold text-[#068FB0]">GLOBAL PRIVACY POLICY</h1>

                        Fish Global LTD, doing business as PayWithFish, and our subsidiaries and affiliates (collectively “PayWithFish”, “we”, “us”, “our”) respect your privacy. Our Privacy Policy explains: (1) how we may collect, use, and disclose information we obtain through our websites, networks, and applications (collectively, our “Platform”) and other services we provide to institutional clients (together with the Platform, our "Services"); and (2) the choices you can make about the handling of the information. We also describe how you can contact us about our privacy practices. This Privacy Policy is incorporated by reference into the PayWithFish Terms of Use.
                        On our Platform, you may choose to use certain features which are provided by other entities. These features, which include social networking buttons (which will be prominently displayed on our webpage), are operated by third parties that are not affiliated with PayWithFish, and they may collect information directly from you. These third parties may use your information in accordance with their own privacy policies and practices.

                        <div className="mt-8"> A. Information We Collect</div>
                        <p className=" !leading-loose"> We may obtain information about you in a variety of ways, such as when you voluntarily provide it to us, in our role as a processor of payment transactions, or when it is automatically sent to us by the device you use to access the Platform.</p>
                        <p className=" !leading-loose"> Information We Collect From You</p>
                        <p className=" !leading-loose"> When you use the Service, you may provide us with the following types of information:</p>
                        <ul className="list-disc">
                            <li className="ml-8 !leading-loose"> Your Account information. To create an account, you will need to provide us with your name, phone number, BVN, email address, and password. We will also ask about your relationship with the person on whose behalf you are making a payment, if different from you.</li>
                            <li className="ml-8 !leading-loose"> Payment Information. When submitting a payment through our Platform, we ask the payer for information such as the payer's name, address, phone number, and government identification number. You will also need to provide information about the person for whom you are submitting a payment. For example, when submitting a payment to an educational institution, we will also ask for the student's name, student ID, invoice number, date of birth, year of graduation, educational institution, and student email address. When submitting a payment to a healthcare organization, we will ask the payer to provide information such as the patient's name, medical record number or patient account number, bill identification number, invoice and health care organization. As part of the payment process, or to process a refund or charge-back, we and/or our partners may need the payer's credit card information, financial account information, such as bank account numbers, names, and routing codes.</li>
                            <li className="ml-8 !leading-loose"> Referrals. To invite others to use the Platform, you may submit their names, emails, and, for students, their educational institution.</li>
                            <li className="ml-8 !leading-loose"> Job Application Information. If you apply for a job with us, you may provide us with employment-related information such as your C.V. or resume.</li>
                            <li className="ml-8 !leading-loose"> Messages and Support Requests. We collect the information you submit when you communicate with us by email, chat, or other methods. This includes payer support where you may choose to submit information regarding a problem or whether you speak to one of our representatives directly or otherwise engage with our support team. A summary of the problem you are experiencing, screen shots, documentation or information that would be helpful in resolving the issue.</li>
                            <li className="ml-8 !leading-loose"> Use of the Platform. We collect information about you when browsing our website and taking certain actions. This information includes links you click on; the type, size and filenames of attachments you upload to the Platform, content using analytics techniques that hash, filter or otherwise scrub the information and we collect clickstream data about how you interact with and use features of the Platform.</li>
                            <li className="ml-8 !leading-loose"> Device Information. We collect information about your computer, phone, tablet or other devices you use to access the Platform. Including browser type, IP address, device identifiers and crash data. We will also use your IP address and/or country preference to provide you with a better user experience.</li>
                        </ul>
                        <p className=" !leading-loose"> Information We Collect From Others</p>
                        <p className=" !leading-loose"> We may receive information from others, including:</p>
                        <p className=" !leading-loose"> Financial Institutions and Service Providers. In the course of processing your payment transaction we may work with a number of institutions, who we have partnered with, to help us provide our Platform, including banks and non-bank financial institutions such as card processors, electronic money institutions and payment service providers. To process a payment, a financial institution or service provider may share with us information about the payer's account such as account name, number, routing code and other identifying information.</p>
                        <p className=" !leading-loose"> Designated Entities. We may be provided with your name and email address, before you create an account, from the educational institutions, healthcare providers and other entities for which we serve as a payment agent, in order to contact you to encourage you to make a payment through our Platform. Furthermore, these Designated Entities may use the Platform to communicate with you and manage the receipt of payments.</p>
                        <p className=" !leading-loose"> Information We Collect Using Cookies and Similar Technologies</p>
                        <p className=" !leading-loose"> When you visit our Platform or open our emails, we and our third-party service providers may collect certain information by automated means, such as cookies, web beacons and web server logs. The information collected in this manner includes IP address, browser characteristics, device IDs and characteristics, operating system version, language preferences, referring URLs, and information about the usage of our Platform. We may link this data to your profile. To learn more about the use of cookies by us and third parties, including your opt out options, please visit our Cookie Policy.</p>
                        <p className=" !leading-loose"> We work with third party partners such as analytics and advertising partners, who may collect information about your use of other websites and online services over time. To learn more about Google Analytics and the choices Google provides regarding your information, please visit https://policies.google.com/technologies/partner-sites.</p>
                        <p className=" !leading-loose"> Our advertising partners may collect your information in order to show you ads that may interest you. Where required under applicable law, we will request your consent to such collection and use of your information. You may opt out of receiving personalized advertisements from us and our advertising partners who are members of the Network Advertising Initiative or who subscribe to the Digital Advertising Alliance's Self-Regulatory Principles for Online Behavioral Advertising by visiting the opt-out sections on the websites of each of those organizations. Links to those sites are here:</p>
                        <p className=" !leading-loose"> Network Advertising Initiative: http://www.network org/choices/advertising. .</p>
                        <p className=" !leading-loose"> Digital Advertising Alliance: http://www.aboutads.info/choices/.</p>

                        <div className="mt-8"> B. How We Use the Information We Collect</div>
                        <p className=" !leading-loose">  We may use the information we obtain about you, as permitted by applicable law, to:</p>
                        <ul className="list-disc">
                            <li className="ml-8 !leading-loose">  Provide and improve the Service;</li>
                            <li className="ml-8 !leading-loose">  Authentication detail when logging in;</li>
                            <li className="ml-8 !leading-loose">  We use information about you to verify accounts and activities, to monitor suspicious or fraudulent activities and to identify violations of policy.</li>
                            <li className="ml-8 !leading-loose">  Process your payment transaction and keep you informed about the status of your payment;</li>
                            <li className="ml-8 !leading-loose">  Comply with and enforce applicable legal requirements, industry standards and our policies;</li>
                            <li className="ml-8 !leading-loose">  Prevent potentially illegal or prohibited activities and enforce our Terms of Use;</li>
                            <li className="ml-8 !leading-loose">  Respond to your inquiries, resolve disputes and provide support;</li>
                            <li className="ml-8 !leading-loose">  We use collective learnings about how people use our Platform to troubleshoot and to identify trends, usage, patterns and areas of integration to better analyze, operate and improve our business and the Service (including enhancing the user experience, managing communications and functionality, and developing new products and services);</li>
                            <li className="ml-8 !leading-loose">  Communicate with you for Platform-related purposes, such as sending payment reminders;</li>
                            <li className="ml-8 !leading-loose">  Compare information for accuracy and verify it with third parties;</li>
                            <li className="ml-8 !leading-loose">  Further our business relationship with you, if we have collected your personal information in the context of an actual or potential business relationship;</li>
                            <li className="ml-8 !leading-loose">  Evaluate your application for employment and contact you regarding possible employment at PayWithFish, if you have applied for a job;</li>
                            <li className="ml-8 !leading-loose">  De-identify or aggregate data collected through the Platform and use and disclose it for any purpose; and</li>
                            <li className="ml-8 leading-loose">  Fulfill other purposes to which you have consented, which would be reasonably expected by you, or which are otherwise authorised or required by law. Where required by law or where we believe it is necessary to protect or legal rights; we will use information about you in connection to legal claims, regulatory issues, audit function, merger or funding.</li>
                        </ul>
                        <div className="mt-8"> C. Disclosure of Information</div>
                        <p className=" !leading-loose">  We may share your information, as permitted by applicable law, in connection with the purposes described in Section B of this privacy policy. This includes sharing your information in the following ways:</p>

                        <ul className="list-disc pl-8">
                            <li className="leading-loose">  To process your payment, we may share some of your information with the Designated Entity, for whom we serve as the payment agent.</li>
                            <li className="leading-loose">  In order to perform our services or meet our legal and regulatory obligations, we may have to transfer your personal information to organizations outside of your local country.</li>
                            <li className="leading-loose">  We may share your information with service providers and vendors who assist us with the delivery of our Service. In some cases, to successfully process your payment or refund, we may share bank/payment receipt documents that you have sent to us with financial institutions under contract with whom we work, to assist with the processing and/or refund of that payment. Our contracts oblige these financial institutions to only use your personal information in connection with the services they provide to us and not for their own benefit.</li>
                            <li className="leading-loose">  Additionally, your information may be shared with other financial institutions, trade bodies, anti-fraud organizations and law enforcement agencies for the purposes of identifying and preventing fraud, money laundering, terrorist financing and other financial crimes.</li>
                            <li className="leading-loose">  PayWithFish is a business incorporated in the United States, which also operates several international affiliates and subsidiaries (for a list of local PayWithFish entities, please visit us atwww.PayWithFish.com. We may share personal information with these, or future, affiliates.</li>
                            <li className="leading-loose">  If we are required to do so by law or legal process or to comply with the law, or when we believe, in our sole discretion, that the disclosure of personal information is appropriate to prevent physical harm or financial loss, or in connection with an investigation of suspected or actual fraudulent or illegal activity or to investigate violations of our Terms of Use and other agreements.</li>
                            <li className="leading-loose">  If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, sale of company assets, or transition of service to another provider, your information may be sold or transferred as part of such a transaction.</li>
                        </ul>
                        <p className=" !leading-loose">  We do not rent, sell, or share personal information (as defined by California Civil Code § 1798.83) about you with other people or non affiliated companies for their direct marketing purposes, unless we have your permission.</p>
                        <p className=" !leading-loose">  We will not collect, hold, use or disclose your personal information (as defined by Australia Privacy Act 1988) other than for the primary purpose the personal information was collected, or for a reasonably related secondary purpose. A secondary purpose may include, for example, instances where our service providers and third party contractors (e.g. technology and support service providers) require access to your personal information in order to provide services to us.</p>

                        <div className="mt-8"> D. Legal basis for processing</div>

                        <ul className="list-disc pl-8">

                            <li className=" !leading-loose">      We collect and process information about you only when we have a legal basis for doing so under applicable laws. This means we collect and use your information:</li>
                            <li className=" !leading-loose">      To allow you to access and use the Platform, to provide support to payers and clients, and to fulfill other obligations related to your agreement with us and/or to take steps at your request prior to entering such agreement (please see our Terms of Use at https://www.PayWithFish.com/legal/terms-of-use);</li>
                            <li className=" !leading-loose">      To comply with a legal and/or regulatory requirement to which PayWithFish is subject;</li>
                            <li className=" !leading-loose">      To pursue our legitimate interests, provided that these legitimate interests are not overridden by your interests or fundamental rights and freedoms</li>
                            <ul className="list-disc pl-8">

                                <li className=" !leading-loose">      Our legitimate interests include:</li>
                                <li className=" !leading-loose">      Protecting the security and integrity of the Service, our payers, our clients, and employees</li>
                                <li className=" !leading-loose">      Monitoring, identifying, preventing, and reporting fraud, money laundering, terrorist financing, other illegal activity, and prohibited use of our Platform</li>
                                <li className=" !leading-loose">      Establishing, exercising, or defending legal rights and claims</li>
                                <li className=" !leading-loose">      Improving the Platform and the delivery and effectiveness of our Services</li>
                                <li className=" !leading-loose">      Measuring and understanding the effectiveness of advertising and/or providing you with information about other similar goods and services we offer</li>

                            </ul>
                        </ul>

                        <div className="mt-8"> E. Your Rights and Choices</div>
                        <p className=" !leading-loose">  You may have certain rights regarding the personal information we collect and maintain about you and how we communicate with you.</p>
                        <ul className="list-disc pl-8">
                            <li className=" !leading-loose">  When we request information from you on the Platform, you may always choose not to provide us with that information. However, if you decline to provide us with certain information, this may affect the functionality of the Platform.</li>
                            <li className=" !leading-loose">  If you are located in a particular country (Europe, UK etc.) where your privacy rights are more restrictive you may direct us not to share your personal information with third parties, except (i) with the service providers and financial institutions under contract we have retained to perform services on our behalf, (ii) in the event we are acquired or we transfer all or a portion of our business or assets, (iii) if a legal process or law requires processing, (iv) with other financial institutions, trade bodies, anti-fraud organisations and law enforcement agencies for the purposes of identifying and preventing fraud, money laundering, terrorist financing and other financial crimes, or (v) when we believe, in our sole discretion, that the disclosure of personal information is appropriate to prevent physical harm or financial loss, or in connection with an investigation of suspected or actual fraudulent or illegal activity.</li>
                            <li className=" !leading-loose">  You can access, modify or update certain personal information submitted on our Platform by logging into your account and changing your preferences. Subject to applicable law, you may have the right to request access to and receive other personal information we hold about you, update and correct inaccuracies in your personal information, and have the information deleted, as appropriate or to request a copy of your information. To exercise these rights, contact us as described below (see section K of this privacy policy). Your right to access, update, correct or delete personal information may be limited in some circumstances by local law requirements.</li>
                            <li className=" !leading-loose">  Where your data is needed to be shared with third parties for payment, for example, payment partners, you will need to contact those third-party service providers directly to enforce your rights.</li>
                            <li className=" !leading-loose">  You may ask us to stop using or processing your data where you have given us consent.</li>
                            <li className=" !leading-loose">  You may contact us to withdraw your consent, but this will not affect any processing that has already taken place at the time.</li>
                        </ul>
                        <div className="mt-8">  F. Data Transfer</div>
                        <p className=" !leading-loose"> To facilitate our global operations, we transfer and store information in the Nigeria and allow access to that information by employees from other countries in which PayWithFish operates. These countries may not have the equivalent privacy laws as those of the EU or UK. When we share information about you within and among our corporate affiliates we make use of standard contractual data protection clauses, which have been approved by the European Commission.</p>
                        <p className=" !leading-loose">  We commit to cooperate with EU data protection authorities (DPAs) and the Swiss Federal Data Protection and Information Commissioner (FDPIC) and comply with the advice given by such authorities with regard to human resources data transferred from the EU and Switzerland in the context of the employment relationship.</p>

                        <div className="mt-8">  G. How We Protect Your Personal Information</div>
                        <p className=" !leading-loose">  PayWithFish maintains reasonable safeguards combining administrative, technical, and physical measures to provide protection to the personal information you provide against accidental, unlawful or unauthorized destruction, loss, alteration, access, interference, modification, disclosure or misuse.</p>
                        <p className=" !leading-loose">  We use data hosting service providers in the US and Ireland to host the information we collect and we use technical controls to secure that data</p>
                        <p className=" !leading-loose">  We use Transport Layer Security (TLS) encryption on our website when transmitting information and use other commercially reasonable efforts to protect your information. We continue to assess new technology for protection of information and upgrade our information security systems where appropriate. Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security of any information you provide to us.</p>

                        <div className="mt-8">  H. Security Practices</div>
                        <p className=" !leading-loose"> PayWithFish makes sure that our employees know and adhere to our security policies. We require periodic training on our security policies for all personnel, no matter their department. Personnel who work directly with customers receive extra training on emerging risks, such as identity theft.</p>
                        <p className=" !leading-loose"> All PayWithFish resources agree when joining the Company to a form of confidentiality/non-disclosure agreement or specific confidentiality undertaking in their agreements of employment. PayWithFish resources must understand and comply fully with these terms upon commencing work at PayWithFish, and keep information confidential that comes into their possession or control in connection with employment with PayWithFish. This includes internal PayWithFish information, as well as information relating to clients and third parties, and applies at any time during and after employment.</p>

                        <div className="mt-8">  I. Notice</div>
                        <p className=" !leading-loose"> Every user of the Platform is provided with our Global Privacy Policy, which provides details regarding our collection and handling of Personal Information.</p>

                        <div className="mt-8">  J. Retention</div>
                        <p className=" !leading-loose"> We retain your account information for as long as your account is active and as necessary to comply with our legal obligations. As a regulated business, we comply with statutory retention periods contained in regulations applicable to financial transactions, including those in anti-money laundering, anti-terrorist financing and other laws to which we are subject. After such time we will either delete or anonymize your information or if it is not possible then we will securely store your information and isolate it from any further use until deletion is possible.</p>

                        <div className="mt-8">  K. Children's Privacy</div>
                        <p className=" !leading-loose"> PayWithFish is not directed to children, and we do not knowingly collect personal information from children under 13. If we find out that a child under 13 has given us personal information, we will take steps to delete that information. If you believe that a child under the age of 13 has given us personal information, please let us know.</p>

                        <div className="mt-8">  L. Links to Other Websites</div>
                        <p className=" !leading-loose">  Our site may include links to other websites. When you access another website, we strongly suggest you review the privacy policy or notices on those external websites. PayWithFish is not responsible for the content or privacy practices of external websites.</p>

                        <div className="mt-8">   M. Changes to Our Privacy Policy</div>
                        <p className=" !leading-loose"> PayWithFish may periodically make changes to this privacy policy to reflect any changes in our information practices. We will notify you by revising the “Last Updated” date at the top of this Privacy Policy. If we make any material changes, we will let you know through the Platform, by email, or other communication. We encourage users to read this Privacy Policy periodically to stay up-to-date about our privacy practices. As long as you use the Platform, you are agreeing to this Privacy Policy and any updates we make to it.</p>

                        <div className="mt-8">   N. Questions, Comments and Complaints about Our Handling of Personal Information</div>
                        <p className=" !leading-loose"> If you have any questions, comments or complaints about our collection, use, storage or disclosure of personal information, please contact us as set forth below. PayWithFish will take any privacy complaint seriously and we will aim to resolve any issue in a timely and efficient manner. We request that you cooperate with us during this process and provide us with any relevant information that we may need.</p>
                        <p className=" !leading-loose"> PayWithFish commits to resolve complaints about our collection or use of your personal information. Individuals with inquiries or complaints regarding our policy should first contact PayWithFish using the contact information details below.</p>

                        <div className="mt-8">   O. Contact Information</div>
                        <p className=" !leading-loose">  If you have any questions or concerns related to this privacy policy, or if you wish to request access to, or correction of, your personal information or make a privacy complaint you may contact us, or write to us at:</p>
                        <p className=" !leading-loose">  PayWithFishAttn: Privacy Officer7a Milverton Road, Ikoyi Lagos NigeriaInfo@paywithfish.com</p>
                        <p className=" !leading-loose">  Once you lodge a complaint with us, we will send you a written receipt acknowledging your concern unless the complaint has already been resolved within that time frame. We will try to resolve your complaint within 30 days from the date you lodge your complaint and keep you informed about the progress of the complaint throughout the process. We will endeavor to resolve any issue to your satisfaction and notify you of the outcome of our investigation as soon as possible.</p>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy;