import ExchangeCard from "../../../Components/ExchangeCard"
import { formatToCurrency, getIcon, getImage } from "../../../Utils"
import { Menu, Tab, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { NGNTransactionItem } from "../Components/TransactionItem"
import { useAppDispatch, useAppSelector } from "../../../Hooks"
import { FundNairaWalletModal, FundWalletModal, InternationalTransferModal, LocalTransferModal, TransferFundsModal, TransferNairaFundsModal, WithdrawFundsModal } from "../Components"
import { WalletModel } from "../../../Models/General"
import { updateWalletsAsync } from "../../../Redux/Wallets"
import { NGNTransactionHistory, WalletTransactionHistory } from "../Dashboard"
import { useSelector } from "react-redux"
import getSymbolFromCurrency from "currency-symbol-map"


const NairaWalletCard = () => {
    const [showFundNairaWallet, setShowFundNairaWallet] = useState(false);
    const [showTransferNaira, setShowTransferNaira] = useState(false);
    const virtualAccountBalance = useAppSelector(state => state.virtualWallet.account);

    return (
        <div>
            <FundNairaWalletModal open={showFundNairaWallet} onClose={() => setShowFundNairaWallet(false)} />
            <TransferNairaFundsModal open={showTransferNaira} onClose={() => setShowTransferNaira(false)} />

            <ExchangeCard size="lg">
                <div className="md:px-10 px-6 py-4 md:py-10">
                    <div className="">
                        <div className="font-futura font-semibold text-white">
                            Available to spend
                        </div>
                    </div>
                    <div className="mt-3 text-white flex items-center gap-1">
                        <div>
                            {<img className="w-8" src={getIcon("naira.png")} alt="" />}
                        </div>
                        <div className="text-[35px] font-semibold">
                            {
                                virtualAccountBalance?.available ? formatToCurrency(virtualAccountBalance?.available / 100) : "0.00"
                            }

                        </div>
                    </div>
                    <div className="md:mt-12 text-[15px] font-medium  font-futura">
                        <div className="flex gap-2">
                            <div className="font-futura font-semibold text-white flex items-center">
                                <div>
                                    Ledger balance:
                                </div>
                                <span className="inline-flex items-center ml-2">
                                    <div>
                                        {<img className="w-[0.8rem] mr-1" src={getIcon("naira.png")} alt="" />}
                                    </div>
                                    <div className="">
                                        {
                                            virtualAccountBalance?.ledger ? formatToCurrency(virtualAccountBalance?.ledger / 100) : "0.00"
                                        }
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="text-xs text-[#FFB9FF]">
                                <div>
                                    What is this?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ExchangeCard>
            <div className="mt-8 px-1">
                <button onClick={() => setShowFundNairaWallet(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-success">
                    <div className="flex items-center gap-2">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="white" />
                        </svg>
                        <span className="">Fund wallet</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferNaira(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-primary ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>

                        <span className="">Transfer</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

const EuroWalletCard = () => {
    const [showFundWallet, setShowFundWallet] = useState(false);
    const [showIntTransferModal, setShowIntTransferModal] = useState(false);
    const [showLocalTransferModal, setShowLocalTransferModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    const dispatch = useAppDispatch();
    const wallet = useAppSelector(state => {
        return state.wallets.wallets.find((val) => val.currency == "EUR") as WalletModel
    })

    const handleGetWallets = async () => {
        try {
            dispatch(updateWalletsAsync());
        } catch (err) {

        }
    }


    return (
        <div>
            <FundWalletModal
                open={showFundWallet}
                walletId={wallet?.id as number}
                walletName="Euro"
                walletCurrency="EUR"
                onClose={() => {
                    setShowFundWallet(false);
                }} />
            <WithdrawFundsModal
                open={showWithdrawModal}
                walletId={wallet?.id as number}
                walletName="Euro"
                walletCurrency="EUR"
                onClose={() => {
                    setShowWithdrawModal(false);
                }} />
            <TransferFundsModal
                open={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                onInternationalTransfer={() => setShowIntTransferModal(true)}
                onLocalTransfer={() => setShowLocalTransferModal(true)} />
            <InternationalTransferModal walletId={wallet?.id as number} open={showIntTransferModal} onClose={() => setShowIntTransferModal(false)} />
            <LocalTransferModal walletId={wallet?.id as number} open={showLocalTransferModal} onClose={() => setShowLocalTransferModal(false)} />


            <ExchangeCard size="lg">
                <div className="px-10 py-10">
                    <div className="">
                        <div className="font-futura font-semibold text-white">
                            Available to spend
                        </div>
                    </div>
                    <div className="mt-3 text-white flex items-center gap-1">
                        <div>
                            {<img className="w-8" src={getIcon("euro.png")} alt="" />}
                        </div>
                        <div className="text-[35px] font-semibold">
                            {
                                wallet ? formatToCurrency(wallet.amount / 100) : "0.00"
                            }
                        </div>
                    </div>

                </div>
            </ExchangeCard>
            <div className="mt-8 px-1">
                <button onClick={() => setShowFundWallet(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-success">
                    <div className="flex items-center gap-2">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="white" />
                        </svg>
                        <span className="">Fund wallet</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-primary ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Transfer</span>
                    </div>
                </button>
                <button onClick={() => setShowWithdrawModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-pink-700 bg-pink-500 ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Withdraw</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm border border-[#282828] px-3 rounded-xl bg-white ml-2">
                    <div className="flex items-center gap-2 font-semibold">
                        <span>Euro Wallet ID:</span>
                        <span className="">00{wallet?.id}</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

const AUDWalletCard = () => {
    const [showFundWallet, setShowFundWallet] = useState(false);
    const [showIntTransferModal, setShowIntTransferModal] = useState(false);
    const [showLocalTransferModal, setShowLocalTransferModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    const dispatch = useAppDispatch();
    const wallet = useAppSelector(state => {
        return state.wallets.wallets.find((val) => val.currency == "AUD") as WalletModel
    })

    const handleGetWallets = async () => {
        try {
            dispatch(updateWalletsAsync());
        } catch (err) {

        }
    }


    return (
        <div>
            <FundWalletModal
                open={showFundWallet}
                walletId={wallet?.id as number}
                walletName="Australian Dollar"
                walletCurrency="AUD"
                onClose={() => {
                    setShowFundWallet(false);
                }} />
            <WithdrawFundsModal
                open={showWithdrawModal}
                walletId={wallet?.id as number}
                walletName="Australian Dollar"
                walletCurrency="AUD"
                onClose={() => {
                    setShowWithdrawModal(false);
                }} />
            <TransferFundsModal
                open={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                onInternationalTransfer={() => setShowIntTransferModal(true)}
                onLocalTransfer={() => setShowLocalTransferModal(true)} />
            <InternationalTransferModal walletId={wallet?.id as number} open={showIntTransferModal} onClose={() => setShowIntTransferModal(false)} />
            <LocalTransferModal walletId={wallet?.id as number} open={showLocalTransferModal} onClose={() => setShowLocalTransferModal(false)} />


            <ExchangeCard size="lg">
                <div className="px-10 py-10">
                    <div className="">
                        <div className="font-futura font-semibold text-white">
                            Available to spend
                        </div>
                    </div>
                    <div className="mt-3 text-white flex items-center gap-1">
                        <div>
                            {<img className="w-6" src={getIcon("dollar.png")} alt="" />}
                        </div>
                        <div className="text-[35px] font-semibold">
                            {
                                wallet ? formatToCurrency(wallet.amount / 100) : "0.00"
                            }
                        </div>
                    </div>

                </div>
            </ExchangeCard>
            <div className="mt-8 px-1">
                <button onClick={() => setShowFundWallet(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-success">
                    <div className="flex items-center gap-2">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="white" />
                        </svg>
                        <span className="">Fund wallet</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-primary ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Transfer</span>
                    </div>
                </button>
                <button onClick={() => setShowWithdrawModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-pink-700 bg-pink-500 ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Withdraw</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm border border-[#282828] px-3 rounded-xl bg-white ml-2">
                    <div className="flex items-center gap-2 font-semibold">
                        <span>AUD Wallet ID:</span>
                        <span className="">00{wallet?.id}</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

const BGNWalletCard = () => {
    const [showFundWallet, setShowFundWallet] = useState(false);
    const [showIntTransferModal, setShowIntTransferModal] = useState(false);
    const [showLocalTransferModal, setShowLocalTransferModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    const dispatch = useAppDispatch();
    const wallet = useAppSelector(state => {
        return state.wallets.wallets.find((val) => val.currency == "BGN") as WalletModel
    })

    const handleGetWallets = async () => {
        try {
            dispatch(updateWalletsAsync());
        } catch (err) {

        }
    }


    return (
        <div>
            <FundWalletModal
                open={showFundWallet}
                walletId={wallet?.id as number}
                walletName="BGN"
                walletCurrency="BGN"
                onClose={() => {
                    setShowFundWallet(false);
                }} />
            <WithdrawFundsModal
                open={showWithdrawModal}
                walletId={wallet?.id as number}
                walletName="BGN"
                walletCurrency="BGN"
                onClose={() => {
                    setShowWithdrawModal(false);
                }} />
            <TransferFundsModal
                open={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                onInternationalTransfer={() => setShowIntTransferModal(true)}
                onLocalTransfer={() => setShowLocalTransferModal(true)} />
            <InternationalTransferModal walletId={wallet?.id as number} open={showIntTransferModal} onClose={() => setShowIntTransferModal(false)} />
            <LocalTransferModal walletId={wallet?.id as number} open={showLocalTransferModal} onClose={() => setShowLocalTransferModal(false)} />


            <ExchangeCard size="lg">
                <div className="px-10 py-10">
                    <div className="">
                        <div className="font-futura font-semibold text-white">
                            Available to spend
                        </div>
                    </div>
                    <div className="mt-3 text-white flex items-center gap-1">
                        <div>
                            {<img className="w-6" src={getIcon("dollar.png")} alt="" />}
                        </div>
                        <div className="text-[35px] font-semibold">
                            {
                                wallet ? formatToCurrency(wallet.amount / 100) : "0.00"
                            }
                        </div>
                    </div>

                </div>
            </ExchangeCard>
            <div className="mt-8 px-1">
                <button onClick={() => setShowFundWallet(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-success">
                    <div className="flex items-center gap-2">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="white" />
                        </svg>
                        <span className="">Fund wallet</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-primary ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Transfer</span>
                    </div>
                </button>
                <button onClick={() => setShowWithdrawModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-pink-700 bg-pink-500 ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Withdraw</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm border border-[#282828] px-3 rounded-xl bg-white ml-2">
                    <div className="flex items-center gap-2 font-semibold">
                        <span>BGN Wallet ID:</span>
                        <span className="">00{wallet?.id}</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

const USDWalletCard = () => {
    const [showFundWallet, setShowFundWallet] = useState(false);
    const [showIntTransferModal, setShowIntTransferModal] = useState(false);
    const [showLocalTransferModal, setShowLocalTransferModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    const dispatch = useAppDispatch();
    const wallet = useAppSelector(state => {
        return state.wallets.wallets.find((val) => val.currency == "USD") as WalletModel
    })

    const handleGetWallets = async () => {
        try {
            dispatch(updateWalletsAsync());
        } catch (err) {

        }
    }


    return (
        <div>
            <FundWalletModal
                open={showFundWallet}
                walletId={wallet?.id as number}
                walletName="Dollar"
                walletCurrency="USD"
                onClose={() => {
                    setShowFundWallet(false);
                }} />
            <WithdrawFundsModal
                open={showWithdrawModal}
                walletId={wallet?.id as number}
                walletName="Dollar"
                walletCurrency="USD"
                onClose={() => {
                    setShowWithdrawModal(false);
                }} />
            <TransferFundsModal
                open={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                onInternationalTransfer={() => setShowIntTransferModal(true)}
                onLocalTransfer={() => setShowLocalTransferModal(true)} />
            <InternationalTransferModal walletId={wallet?.id as number} open={showIntTransferModal} onClose={() => setShowIntTransferModal(false)} />
            <LocalTransferModal walletId={wallet?.id as number} open={showLocalTransferModal} onClose={() => setShowLocalTransferModal(false)} />

            <ExchangeCard size="lg">
                <div className="px-10 py-10">
                    <div className="">
                        <div className="font-futura font-semibold text-white">
                            Available to spend
                        </div>
                    </div>
                    <div className="mt-3 text-white flex items-center gap-1">
                        <div>
                            {<img className="w-6" src={getIcon("dollar.png")} alt="" />}
                        </div>
                        <div className="text-[35px] font-semibold">
                            {
                                wallet ? formatToCurrency(wallet.amount / 100) : "0.00"
                            }
                        </div>
                    </div>
                    <div className="mt-12 text-[15px] font-medium  font-futura">

                    </div>
                </div>
            </ExchangeCard>
            <div className="mt-8 px-1">
                <button onClick={() => setShowFundWallet(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-success">
                    <div className="flex items-center gap-2">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="white" />
                        </svg>
                        <span className="">Fund wallet</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-primary ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Transfer</span>
                    </div>
                </button>
                <button onClick={() => setShowWithdrawModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-pink-700 bg-pink-500 ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Withdraw</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm border border-[#282828] px-3 rounded-xl bg-white ml-2">
                    <div className="flex items-center gap-2 font-semibold">
                        <span>USD Wallet ID:</span>
                        <span className="">00{wallet?.id}</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

const GBPWalletCard = () => {
    const [showFundWallet, setShowFundWallet] = useState(false);
    const [showIntTransferModal, setShowIntTransferModal] = useState(false);
    const [showLocalTransferModal, setShowLocalTransferModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    const dispatch = useAppDispatch();
    const wallet = useAppSelector(state => {
        return state.wallets.wallets.find((val) => val.currency == "GBP") as WalletModel
    })

    const handleGetWallets = async () => {
        try {
            dispatch(updateWalletsAsync());
        } catch (err) {

        }
    }


    return (
        <div>
            <FundWalletModal
                open={showFundWallet}
                walletId={wallet?.id as number}
                walletName="Pounds"
                walletCurrency="GBP"
                onClose={() => {
                    setShowFundWallet(false);
                }} />
            <WithdrawFundsModal
                open={showWithdrawModal}
                walletId={wallet?.id as number}
                walletName="Pounds"
                walletCurrency="GBP"
                onClose={() => {
                    setShowWithdrawModal(false);
                }} />
            <TransferFundsModal
                open={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                onInternationalTransfer={() => setShowIntTransferModal(true)}
                onLocalTransfer={() => setShowLocalTransferModal(true)} />
            <InternationalTransferModal walletId={wallet?.id as number} open={showIntTransferModal} onClose={() => setShowIntTransferModal(false)} />
            <LocalTransferModal walletId={wallet?.id as number} open={showLocalTransferModal} onClose={() => setShowLocalTransferModal(false)} />


            <ExchangeCard size="lg">
                <div className="px-10 py-10">
                    <div className="">
                        <div className="font-futura font-semibold text-white">
                            Available to spend
                        </div>
                    </div>
                    <div className="mt-3 text-white flex items-center gap-1">
                        <div>
                            {<img className="w-6" src={getIcon("pounds.png")} alt="" />}
                        </div>
                        <div className="text-[35px] font-semibold">
                            {
                                wallet ? formatToCurrency(wallet.amount / 100) : "0.00"
                            }
                        </div>
                    </div>
                    <div className="mt-12 text-[15px] font-medium  font-futura">

                    </div>
                </div>
            </ExchangeCard>
            <div className="mt-8 px-1">
                <button onClick={() => setShowFundWallet(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-success">
                    <div className="flex items-center gap-2">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="white" />
                        </svg>
                        <span className="">Fund wallet</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-primary ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Transfer</span>
                    </div>
                </button>
                <button onClick={() => setShowWithdrawModal(true)} className="py-2 text-sm text-white px-3 rounded-xl  bg-pink-700 ml-2">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Withdraw</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm border border-[#282828] px-3 rounded-xl bg-white ml-2">
                    <div className="flex items-center gap-2 font-semibold">
                        <span>GBP Wallet ID:</span>
                        <span className="">00{wallet?.id}</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

export const VExchangeCard: React.FC<{ wallet: WalletModel }> = ({ wallet }) => {
    const [showFundWallet, setShowFundWallet] = useState(false);
    const [showIntTransferModal, setShowIntTransferModal] = useState(false);
    const [showLocalTransferModal, setShowLocalTransferModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    const dispatch = useAppDispatch();


    return (
        <div>
            <FundWalletModal
                open={showFundWallet}
                walletId={wallet?.id as number}
                walletName={wallet.currency}
                walletCurrency={wallet.currency}
                onClose={() => {
                    setShowFundWallet(false);
                }} />
            <WithdrawFundsModal
                open={showWithdrawModal}
                walletId={wallet?.id as number}
                walletName={wallet.currency}
                walletCurrency={wallet.currency}
                onClose={() => {
                    setShowWithdrawModal(false);
                }} />
            <TransferFundsModal
                open={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                onInternationalTransfer={() => setShowIntTransferModal(true)}
                onLocalTransfer={() => setShowLocalTransferModal(true)} />
            <InternationalTransferModal walletId={wallet?.id as number} open={showIntTransferModal} onClose={() => setShowIntTransferModal(false)} />
            <LocalTransferModal walletId={wallet?.id as number} open={showLocalTransferModal} onClose={() => setShowLocalTransferModal(false)} />

            <ExchangeCard size="lg">
                <div className="md:px-10 px-6 py-4 md:py-10">
                    <div className="">
                        <div className="font-futura font-semibold text-white">
                            Available to spend
                        </div>
                    </div>
                    <div className="mt-3 text-white flex items-center gap-1">
                        <div>
                            <div className="text-4xl font-semibold">{getSymbolFromCurrency(wallet.currency)}</div>
                        </div>
                        <div className="text-[35px] font-semibold">
                            {
                                wallet ? formatToCurrency(wallet.amount / 100) : "0.00"
                            }
                        </div>
                    </div>

                </div>
            </ExchangeCard >
            <div className="mt-8 px-1 flex flex-wrap gap-2">
                <button onClick={() => setShowFundWallet(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-success">
                    <div className="flex items-center gap-2">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="white" />
                        </svg>
                        <span className="">Fund wallet</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm text-white px-3 rounded-xl bg-primary">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Transfer</span>
                    </div>
                </button>
                <button onClick={() => setShowWithdrawModal(true)} className="py-2 text-sm text-white px-3 rounded-xl  bg-pink-600">
                    <div className="flex items-center gap-2">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86479 5.33194L6.74578 1.17755C6.64778 0.816345 6.41083 0.508593 6.08668 0.321509C5.76254 0.134426 5.37752 0.0832141 5.01574 0.179059L0.858763 1.2884C0.67816 1.33739 0.524284 1.45587 0.430742 1.61794C0.337201 1.78002 0.311595 1.97252 0.359517 2.15341C0.383362 2.24383 0.424873 2.32863 0.481656 2.40293C0.538439 2.47722 0.609371 2.53953 0.690358 2.58628C0.771346 2.63302 0.860787 2.66326 0.953522 2.67526C1.04626 2.68726 1.14045 2.68079 1.23067 2.6562L4.41395 1.80273L0.519123 8.5511C0.425216 8.7138 0.399791 8.90715 0.44844 9.08861C0.497089 9.27006 0.615828 9.42475 0.778535 9.51866C0.941242 9.61257 1.13459 9.63799 1.31604 9.58934C1.4975 9.54069 1.65219 9.42195 1.7461 9.25925L5.64093 2.51088L6.49439 5.69417C6.54243 5.87589 6.66068 6.03109 6.82315 6.12563C6.98561 6.22016 7.17898 6.24629 7.36071 6.19825C7.54243 6.15022 7.69763 6.03196 7.79217 5.86949C7.8867 5.70703 7.91282 5.51366 7.86479 5.33194Z" fill="white" />
                        </svg>
                        <span className="">Withdraw</span>
                    </div>
                </button>
                <button onClick={() => setShowTransferModal(true)} className="py-2 text-sm border border-[#282828] px-3 rounded-xl bg-white">
                    <div className="flex items-center gap-2 font-semibold">
                        <span>{wallet.currency} Wallet ID:</span>
                        <span className="">00{wallet?.id}</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

export const WalletsTab = () => {
    const wallets = useAppSelector(state => state.wallets.wallets);
    const [selectedCurrency, setSelectedCurrency] = useState("");

    return (
        <div>
            <Tab.Group>
                <Tab.List as="div" className={"flex gap-2 flex-wrap"}>
                    <Tab>
                        {({ selected }) => (
                            /* Use the `selected` state to conditionally style the selected tab. */
                            <button
                                onClick={() => { setSelectedCurrency("NGN") }}
                                className={
                                    `${selected ? 'border-[#EE2365]' : 'border-[#828282]'} border px-2 py-2 rounded-lg bg-[#F5F5F5] outline-none`
                                }
                            >
                                <div className="flex gap-1 items-center">
                                    <div>
                                        <span className={`fi fi-ng object-fill fis !w-6 !h-6 rounded-full border border-white shadow-lg`}></span>
                                    </div>
                                    <div className=" font-semibold text-sm">
                                        NGN
                                    </div>
                                </div>
                            </button>
                        )}
                    </Tab>
                    {
                        wallets.map((wallet) => {
                            return <Tab>
                                {({ selected }) => (
                                    /* Use the `selected` state to conditionally style the selected tab. */
                                    <button
                                        onClick={() => { setSelectedCurrency(wallet.currency) }}

                                        className={
                                            `${selected ? 'border-[#EE2365]' : 'border-[#828282]'} border px-2 py-2 rounded-lg bg-[#F5F5F5] outline-none`
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <div>
                                                <span className={`fi fi-${wallet.currency.substring(0, 2).toLowerCase()} object-fill fis !w-6 !h-6 rounded-full border border-white shadow-lg`}></span>
                                            </div>
                                            <div className=" font-semibold text-sm">
                                                {wallet.currency}
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </Tab>
                        })
                    }

                </Tab.List>
                <Tab.Panels as="div" className={"mt-8"}>
                    <Tab.Panel>
                        <NairaWalletCard />
                        <div className="mt-24">
                            <NGNTransactionHistory />
                        </div>
                    </Tab.Panel>

                    {
                        wallets.map((wallet) => {
                            return <Tab.Panel>
                                <VExchangeCard wallet={wallet} />
                                <div className="mt-24">
                                    <WalletTransactionHistory currency={selectedCurrency} />
                                </div>
                            </Tab.Panel>
                        })
                    }
                </Tab.Panels>
            </Tab.Group>

        </div>
    )
}


const UserWallets = () => {
    return (
        <div id="dashboard">
            <div className="mt-8">
                <div>
                    <div className="text-sm font-bold">
                        Your wallets
                    </div>
                    <div className="mt-2 font-futura text-sm">
                        All activities and transactions on your wallets.
                    </div>
                </div>

                <div className="mt-12">
                    <WalletsTab />
                </div>
            </div>
        </div>
    )
}

export default UserWallets