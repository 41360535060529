import { useState } from "react";
import { PayRentModal, PayStudentVisaModal, PayTuitionFeeModal } from "../Components";

const Services = () => {
    const [showPayTuitionModal, setShowPayTuitionModal] = useState(false);
    const [showPayStudentModal, setShowPayStudentModal] = useState(false);
    const [showPayRentModal, setShowPayRentModal] = useState(false);

    return (
        <div id="dashboard">
            <PayTuitionFeeModal open={showPayTuitionModal} onClose={() => setShowPayTuitionModal(false)} />
            <PayStudentVisaModal open={showPayStudentModal} onClose={() => setShowPayStudentModal(false)} />
            <PayRentModal open={showPayRentModal} onClose={() => setShowPayRentModal(false)} />

            <div className="mt-8">
                <div className="md:px-4">
                    <div className="text-xl font-bold">
                        All services
                    </div>
                    <div className="mt-2 font-futura text-sm">
                        Do more with Paywithfish
                    </div>
                </div>

                <div className="mt-12">
                    <div className="border rounded-lg bg-white p-6 md:w-10/12">
                        <div className="md:w-11/12">
                            <div className="grid md:grid-cols-2 gap-9">
                                <div className="border border-[#C8C8C8] drop-shadow-[0px_0px_4px_rgba(0,0,0,0.08)] rounded-2xl py-4 px-5 from-[#F2F5FF] to-[#FDE0FA] bg-gradient-to-r">
                                    <div className="flex items-center gap-1">
                                        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.9999 8.47994V19.9999C23.9999 20.2652 23.8946 20.5195 23.707 20.707C23.5195 20.8946 23.2651 20.9999 22.9999 20.9999C22.7347 20.9999 22.4803 20.8946 22.2928 20.707C22.1053 20.5195 21.9999 20.2652 21.9999 19.9999V11.7519L14.5999 15.2879C13.8197 15.7485 12.9289 15.9884 12.0229 15.9819C11.0728 15.9834 10.1399 15.7281 9.32291 15.2429L1.94291 11.7299C1.36279 11.418 0.876721 10.9565 0.535132 10.3933C0.193543 9.83012 0.00888329 9.18579 0.000312565 8.52717C-0.00825816 7.86855 0.159573 7.21964 0.486391 6.64776C0.813209 6.07588 1.28711 5.60188 1.85891 5.27494C1.88591 5.25894 1.91491 5.24394 1.94291 5.22994L9.39991 1.67194C10.205 1.2096 11.1187 0.970064 12.047 0.977973C12.9754 0.985882 13.8848 1.24095 14.6819 1.71694L22.0569 5.22994C22.6388 5.55192 23.1252 6.02223 23.4664 6.59306C23.8077 7.1639 23.9918 7.81491 23.9999 8.47994ZM12.0219 17.9799C10.7425 17.9828 9.48504 17.6475 8.37691 17.0079L3.99991 14.9189V17.6189C4.00051 18.6865 4.34228 19.7258 4.97534 20.5854C5.6084 21.445 6.49962 22.0797 7.51891 22.3969C8.97524 22.8151 10.4849 23.0182 11.9999 22.9999C13.5152 23.0169 15.0248 22.8124 16.4809 22.3929C17.5002 22.0757 18.3914 21.441 19.0245 20.5814C19.6575 19.7218 19.9993 18.6825 19.9999 17.6149V14.9239L15.5409 17.0539C14.4706 17.6689 13.2563 17.9891 12.0219 17.9819V17.9799Z" fill="#068FB0" />
                                        </svg>
                                        <div className="text-lg font-semibold">
                                            Tuition & School Fees
                                        </div>
                                    </div>
                                    <div className="mt-2 font-futura text-sm">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum erat ipsum, pretium eget tortor id, pulvinar tincidunt.
                                    </div>
                                    <div className="mt-1 text-[13px]  leading-[20px] text-[#ED2DF0]">
                                        Platform fee: 5% of total fee, capped at 20
                                    </div>
                                    <div className="mt-8">
                                        <div onClick={() => setShowPayTuitionModal(true)} className="flex items-center gap-2 text-sm text-primary font-bold cursor-pointer">
                                            Pay Now
                                            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5114 2.22849H0.401785C0.179899 2.22849 0 2.39929 0 2.60994V4.39003C0 4.60068 0.179899 4.77148 0.401785 4.77148H10.5114V6.23557C10.5114 6.91524 11.3769 7.25562 11.8832 6.77503L14.7646 4.03945C15.0785 3.7415 15.0785 3.25846 14.7646 2.96055L11.8832 0.224972C11.377 -0.25562 10.5114 0.0847589 10.5114 0.764435V2.22849Z" fill="#068FB0" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="border border-[#C8C8C8] drop-shadow-[0px_0px_4px_rgba(0,0,0,0.08)] rounded-2xl py-4 px-5 from-[#F2F5FF] to-[#FDE0FA] bg-gradient-to-r">
                                    <div className="flex items-center gap-1">
                                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23 7C23.553 7 24 6.553 24 6V5C24 2.243 21.757 0 19 0H6V2C6 2.553 5.553 3 5 3C4.447 3 4 2.553 4 2V0.101C1.721 0.566 0 2.586 0 5V13C0 15.414 1.721 17.434 4 17.899V16C4 15.447 4.447 15 5 15C5.553 15 6 15.447 6 16V18H19C21.757 18 24 15.757 24 13V12C24 11.447 23.553 11 23 11C21.897 11 21 10.103 21 9C21 7.897 21.897 7 23 7ZM6 12C6 12.553 5.553 13 5 13C4.447 13 4 12.553 4 12V11C4 10.447 4.447 10 5 10C5.553 10 6 10.447 6 11V12ZM6 7C6 7.553 5.553 8 5 8C4.447 8 4 7.553 4 7V6C4 5.447 4.447 5 5 5C5.553 5 6 5.447 6 6V7ZM17 10H15.5L13.589 12.421C13.394 12.778 13.02 13 12.614 13H12.294C11.912 13 11.65 12.616 11.788 12.26L12.667 10H11.347C10.905 10 10.485 9.805 10.2 9.467L9.104 8.167C8.807 7.647 9.182 7 9.781 7C9.988 7 10.186 7.082 10.332 7.228L11.442 8H12.683L11.792 5.742C11.651 5.386 11.914 5 12.297 5H12.595C13.002 5 13.376 5.222 13.57 5.579L15.499 8H16.999C17.551 8 17.999 8.448 17.999 9C17.999 9.552 17.552 10 17 10Z" fill="#EE2365" />
                                        </svg>

                                        <div className="text-lg font-semibold">
                                            Euro Visa Fee
                                        </div>
                                    </div>
                                    <div className="mt-2 font-futura text-sm">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum erat ipsum, pretium eget tortor id, pulvinar tincidunt.
                                    </div>
                                    <div className="mt-1 text-[13px]  leading-[20px] text-[#ED2DF0]">
                                        Platform fee: 5% of total fee, capped at 20
                                    </div>
                                    <div className="mt-8">
                                        <div onClick={() => setShowPayStudentModal(true)} className="flex items-center gap-2 text-sm text-primary font-bold">
                                            Pay Now
                                            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5114 2.22849H0.401785C0.179899 2.22849 0 2.39929 0 2.60994V4.39003C0 4.60068 0.179899 4.77148 0.401785 4.77148H10.5114V6.23557C10.5114 6.91524 11.3769 7.25562 11.8832 6.77503L14.7646 4.03945C15.0785 3.7415 15.0785 3.25846 14.7646 2.96055L11.8832 0.224972C11.377 -0.25562 10.5114 0.0847589 10.5114 0.764435V2.22849Z" fill="#068FB0" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="border border-[#C8C8C8] drop-shadow-[0px_0px_4px_rgba(0,0,0,0.08)] rounded-2xl py-4 px-5 from-[#F2F5FF] to-[#FDE0FA] bg-gradient-to-r">
                                    <div className="flex items-center gap-1">
                                        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 5.97L14.08 9H11.92L13 5.97ZM3 16H4V0.1C1.67 0.58 0 2.62 0 5V17.02C0.7 16.49 1.53 16.16 2.4 16.06C2.6 16.02 2.8 16 3 16ZM0 21C0 22.66 1.34 24 3 24H15C17.76 24 20 21.76 20 19V18H3C1.34 18 0 19.34 0 21ZM20 5V16H6V0H15C17.76 0 20 2.24 20 5ZM17.16 11.66L14.42 3.97C14.2 3.37 13.65 2.98 13.01 2.98C12.37 2.98 11.82 3.37 11.6 3.98L8.86 11.67C8.63 12.32 9.11 13.01 9.8 13.01C10.22 13.01 10.6 12.74 10.74 12.35L11.22 11.01H14.81L15.29 12.35C15.43 12.75 15.81 13.01 16.23 13.01C16.92 13.01 17.4 12.33 17.17 11.67L17.16 11.66Z" fill="#3AB473" />
                                        </svg>

                                        <div className="text-lg font-semibold">
                                            Application Fee
                                        </div>
                                    </div>
                                    <div className="mt-2 font-futura text-sm">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum erat ipsum, pretium eget tortor id, pulvinar tincidunt.
                                    </div>
                                    <div className="mt-1 text-[13px]  leading-[20px] text-[#ED2DF0]">
                                        Platform fee: 5% of total fee, capped at 20
                                    </div>
                                    <div className="mt-8">
                                        <div className="flex items-center gap-2 text-sm text-primary font-bold">
                                            Pay Now
                                            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5114 2.22849H0.401785C0.179899 2.22849 0 2.39929 0 2.60994V4.39003C0 4.60068 0.179899 4.77148 0.401785 4.77148H10.5114V6.23557C10.5114 6.91524 11.3769 7.25562 11.8832 6.77503L14.7646 4.03945C15.0785 3.7415 15.0785 3.25846 14.7646 2.96055L11.8832 0.224972C11.377 -0.25562 10.5114 0.0847589 10.5114 0.764435V2.22849Z" fill="#068FB0" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="border border-[#C8C8C8] drop-shadow-[0px_0px_4px_rgba(0,0,0,0.08)] rounded-2xl py-4 px-5 from-[#F2F5FF] to-[#FDE0FA] bg-gradient-to-r">
                                    <div className="flex items-center gap-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.082 11.41L11.082 9.06104C9.26798 7.64304 6.73298 7.64104 4.91898 9.06104L1.91898 11.409C0.699977 12.364 0.000976562 13.799 0.000976562 15.347V19.499C0.000976562 21.98 2.01998 23.999 4.50098 23.999H11.501C13.982 23.999 16.001 21.98 16.001 19.499V15.347C16.001 13.799 15.302 12.365 14.083 11.409L14.082 11.41ZM9.99998 18C9.99998 18.552 9.55198 19 8.99998 19H6.99998C6.44798 19 5.99998 18.552 5.99998 18V16C5.99998 15.448 6.44798 15 6.99998 15H8.99998C9.55198 15 9.99998 15.448 9.99998 16V18ZM23.604 22.253C23.027 23.33 21.909 24 20.687 24H16.179C17.304 22.831 18 21.246 18 19.5V15.348C18 13.182 17.021 11.173 15.315 9.83604L12.313 7.48504C11.298 6.69204 10.122 6.23104 8.91498 6.07104C8.97198 5.90404 9.04298 5.74104 9.14098 5.58804C9.16498 5.55004 13.274 0.753039 13.274 0.753039C14.264 -0.256961 15.784 -0.203961 16.678 0.700039C16.678 0.700039 20.835 5.55104 20.859 5.58904C21.298 6.27004 21.342 7.14104 20.943 7.84504C20.616 8.42204 19.962 8.74904 19.962 8.74904L22.628 12.644C23.032 13.242 23.068 14.109 22.689 14.832C22.328 15.519 21.597 15.885 21.597 15.885L23.475 18.907C24.121 19.918 24.173 21.194 23.605 22.254L23.604 22.253Z" fill="#7B721F" />
                                        </svg>


                                        <div className="text-lg font-semibold">
                                            Accomodation & Rent
                                        </div>
                                    </div>
                                    <div className="mt-2 font-futura text-sm">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum erat ipsum, pretium eget tortor id, pulvinar tincidunt.
                                    </div>
                                    <div className="mt-1 text-[13px]  leading-[20px] text-[#ED2DF0]">
                                        Platform fee: 5% of total fee, capped at 20
                                    </div>
                                    <div className="mt-8">
                                        <div onClick={() => setShowPayRentModal(true)} className="flex items-center gap-2 text-sm text-primary font-bold">
                                            Pay Now
                                            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5114 2.22849H0.401785C0.179899 2.22849 0 2.39929 0 2.60994V4.39003C0 4.60068 0.179899 4.77148 0.401785 4.77148H10.5114V6.23557C10.5114 6.91524 11.3769 7.25562 11.8832 6.77503L14.7646 4.03945C15.0785 3.7415 15.0785 3.25846 14.7646 2.96055L11.8832 0.224972C11.377 -0.25562 10.5114 0.0847589 10.5114 0.764435V2.22849Z" fill="#068FB0" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="border border-[#C8C8C8] drop-shadow-[0px_0px_4px_rgba(0,0,0,0.08)] rounded-2xl py-4 px-5 from-[#F2F5FF] to-[#FDE0FA] bg-gradient-to-r">
                                    <div className="flex items-center gap-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.717 9.549C22.768 7.45173 21.2724 5.64831 19.3868 4.32773C17.5013 3.00714 15.2953 2.218 13 2.043V1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1053 0.48043 11 0.734784 11 1V2.043C8.70213 2.21616 6.49319 3.00439 4.60478 4.32502C2.71637 5.64566 1.21808 7.45005 0.266991 9.549C0.0279387 10.149 -0.0601475 10.7985 0.0104679 11.4405C0.0810832 12.0825 0.30824 12.6973 0.671991 13.231C1.03959 13.7757 1.53527 14.2218 2.11552 14.5303C2.69577 14.8387 3.34286 15 3.99999 15H11V21C11 21.2652 10.8946 21.5196 10.7071 21.7071C10.5196 21.8946 10.2652 22 9.99999 22C9.73477 22 9.48042 21.8946 9.29288 21.7071C9.10535 21.5196 8.99999 21.2652 8.99999 21C8.99999 20.7348 8.89463 20.4804 8.7071 20.2929C8.51956 20.1054 8.26521 20 7.99999 20C7.73478 20 7.48042 20.1054 7.29288 20.2929C7.10535 20.4804 6.99999 20.7348 6.99999 21C6.99999 21.7956 7.31606 22.5587 7.87867 23.1213C8.44128 23.6839 9.20434 24 9.99999 24C10.7956 24 11.5587 23.6839 12.1213 23.1213C12.6839 22.5587 13 21.7956 13 21V15H19.983C20.6403 15 21.2875 14.8387 21.8679 14.5303C22.4483 14.2218 22.9442 13.7757 23.312 13.231C23.6755 12.6972 23.9025 12.0824 23.9731 11.4404C24.0437 10.7985 23.9558 10.149 23.717 9.549Z" fill="#282828" />
                                        </svg>
                                        <div className="text-lg font-semibold">
                                            Insurance
                                        </div>
                                    </div>
                                    <div className="mt-2 font-futura text-sm">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum erat ipsum, pretium eget tortor id, pulvinar tincidunt.
                                    </div>
                                    <div className="mt-1 text-[13px]  leading-[20px] text-[#ED2DF0]">
                                        Platform fee: 5% of total fee, capped at 20
                                    </div>
                                    <div className="mt-8">
                                        <div className="flex items-center gap-2 text-sm text-primary font-bold">
                                            Pay Now
                                            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5114 2.22849H0.401785C0.179899 2.22849 0 2.39929 0 2.60994V4.39003C0 4.60068 0.179899 4.77148 0.401785 4.77148H10.5114V6.23557C10.5114 6.91524 11.3769 7.25562 11.8832 6.77503L14.7646 4.03945C15.0785 3.7415 15.0785 3.25846 14.7646 2.96055L11.8832 0.224972C11.377 -0.25562 10.5114 0.0847589 10.5114 0.764435V2.22849Z" fill="#068FB0" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="border border-[#C8C8C8] drop-shadow-[0px_0px_4px_rgba(0,0,0,0.08)] rounded-2xl py-4 px-5 from-[#F2F5FF] to-[#FDE0FA] bg-gradient-to-r">
                                    <div className="flex items-center gap-1">
                                        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 0H5C2.243 0 0 2.243 0 5V15C0 17.757 2.243 20 5 20H19C21.757 20 24 17.757 24 15V5C24 2.243 21.757 0 19 0ZM4 9H10C10.552 9 11 9.448 11 10C11 10.552 10.552 11 10 11H4C3.448 11 3 10.552 3 10C3 9.448 3.448 9 4 9ZM20 15H4C3.448 15 3 14.552 3 14C3 13.448 3.448 13 4 13H20C20.552 13 21 13.448 21 14C21 14.552 20.552 15 20 15ZM21 9C21 10.103 20.103 11 19 11H15C13.897 11 13 10.103 13 9V7C13 5.897 13.897 5 15 5H19C20.103 5 21 5.897 21 7V9ZM19 7V9H15V7H19Z" fill="#3F1A6B" />
                                        </svg>

                                        <div className="text-lg font-semibold">
                                            Payment & Subscription
                                        </div>
                                    </div>
                                    <div className="mt-2 font-futura text-sm">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum erat ipsum, pretium eget tortor id, pulvinar tincidunt.
                                    </div>
                                    <div className="mt-1 text-[13px]  leading-[20px] text-[#ED2DF0]">
                                        Platform fee: 5% of total fee, capped at 20
                                    </div>
                                    <div className="mt-8">
                                        <div className="flex items-center gap-2 text-sm text-primary font-bold">
                                            Pay Now
                                            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5114 2.22849H0.401785C0.179899 2.22849 0 2.39929 0 2.60994V4.39003C0 4.60068 0.179899 4.77148 0.401785 4.77148H10.5114V6.23557C10.5114 6.91524 11.3769 7.25562 11.8832 6.77503L14.7646 4.03945C15.0785 3.7415 15.0785 3.25846 14.7646 2.96055L11.8832 0.224972C11.377 -0.25562 10.5114 0.0847589 10.5114 0.764435V2.22849Z" fill="#068FB0" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services