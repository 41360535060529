import { Link, useNavigate } from "react-router-dom"
import { Modal } from "../../../Components/Modal"
import { PasswordInput } from "../../../Components/PasswordInput";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OtpInput from 'react-otp-input';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { verifyUser, resendUserOTP, register } from "../../../Api/Auth";
import { APICall } from "../../../Utils";
import moment from "moment";



const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;


const signupValidationSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, 'First name is too short!')
        .max(35, 'First name is too Long!')
        .required('First name is required'),
    last_name: Yup.string()
        .min(2, 'Last name is too short!')
        .max(35, 'Last name is too Long!')
        .required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email Required!'),
    phone: (Yup.string() as any)?.phone("Phone number is invalid!").required("Phone number is required!"),
    date_of_birth: Yup.date().min(moment().subtract(65, "years").toDate(), "Must be over 65 years!").max(moment().subtract(16, "years").toDate(), "Must be over 16 years!").required('Date of birth is required!'),
    password: Yup.string()
        .matches(passwordRules, { message: "Please create a stronger password!" })
        .required("Password is required"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match!')
        .required("Confirm password is required!")

});


const SignUpPage: React.FC<{
    open: boolean;
    onClose: (val: boolean) => void;
}> = ({ open, onClose }) => {
    const [registered, setRegistered] = useState(false);
    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const navigate = useNavigate();
    const [timer, setTimer] = useState(0);
    const [verifying, setVerifying] = useState(false);
    const [resent, setResent] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer == 0)
                return;
            const t = timer - 1;
            setTimer(t)
        }, 1000)

        return () => clearInterval(interval);
    });


    useEffect(() => {
        if (otp.length == 6) {
            setVerifying(true);
            handleVerifyOTP();
        }
    }, [otp]);

    const handleVerifyOTP = async () => {
        try {
            const response = await APICall(verifyUser, { phone, otp }, true);
            navigate("/login", { replace: true });
            setVerifying(false);
        } catch (error) {
            setVerifying(false);

        }
    }

    const handleResendOtp = async () => {
        try {
            const response = await APICall(resendUserOTP, { phone }, true);
            setTimer(60);
            toast(response.data.message);
        } catch (error) {

        }
    }

    const handleRegister = async (values: any, setSubmitting: (val: boolean) => void) => {
        try {
            setSubmitting(true);
            setPhone(values.phone)
            const response = await APICall(register, values, true);
            setTimer(60);
            setRegistered(true);
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
        }

    }
    return (
        <>
            <Modal open={open} onClose={onClose} size="sm">
                {registered ?
                    <>
                        <div className="px-8 py-5">
                            <div className="text-center">
                                <h1 className="font-semibold text-3xl">
                                    Enter OTP
                                </h1>
                                <p className="mt-2 ">
                                    A verification code has been sent to your phone {phone}
                                </p>
                            </div>
                            <div className="mt-12">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputType="password"
                                    renderSeparator={" "}
                                    containerStyle={"justify-center"}
                                    inputStyle={"border border-slate-600 outline-none text-5xl mx-1 !w-10 h-10 md:!w-12 md:h-12 disabled:opacity-70"}
                                    renderInput={(props) => <input  {...props} autoComplete="off" name="otp" disabled={verifying} />}
                                />
                                {timer !== 0 ? <div className="text-center mt-4">
                                    resend otp in {timer} seconds
                                </div> :
                                    <div onClick={handleResendOtp} className="font-semibold cursor-pointe mt-4">resend</div>
                                }
                            </div>
                        </div>
                    </>
                    : <Formik
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                            date_of_birth: null as unknown as Date,
                            password: '',
                            password_confirmation: ''
                        }}
                        validationSchema={signupValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {

                            handleRegister(values, setSubmitting)
                        }}
                    >
                        {({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => (
                            <Form>
                                <div className="px-8 py-5">
                                    <div>
                                        <div className="text-2xl text-left font-semibold text-black">
                                            Create an account
                                        </div>
                                    </div>
                                    <div className="text-sm text-left">
                                        Begin your cross border payments with Paywithfish.
                                    </div>

                                    <div className="mt-6">
                                        <div className="space-y-6">
                                            <div className="flex gap-4">
                                                <div className="flex-1 relative">
                                                    <div className="relative">
                                                        <div className="absolute h-full flex items-center px-3">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.4" d="M9 1.5C7.035 1.5 5.4375 3.0975 5.4375 5.0625C5.4375 6.99 6.945 8.55 8.91 8.6175C8.97 8.61 9.03 8.61 9.075 8.6175C9.09 8.6175 9.0975 8.6175 9.1125 8.6175C9.12 8.6175 9.12 8.6175 9.1275 8.6175C11.0475 8.55 12.555 6.99 12.5625 5.0625C12.5625 3.0975 10.965 1.5 9 1.5Z" fill="black" />
                                                                <path d="M12.81 10.6125C10.7175 9.2175 7.30497 9.2175 5.19747 10.6125C4.24497 11.25 3.71997 12.1125 3.71997 13.035C3.71997 13.9575 4.24497 14.8125 5.18997 15.4425C6.23997 16.1475 7.61997 16.5 8.99997 16.5C10.38 16.5 11.76 16.1475 12.81 15.4425C13.755 14.805 14.28 13.95 14.28 13.02C14.2725 12.0975 13.755 11.2425 12.81 10.6125Z" fill="black" />
                                                            </svg>
                                                        </div>
                                                        <Field type="text" name="first_name" placeholder="First name" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                    </div>
                                                    <ErrorMessage name="first_name" component={"div"} className="text-red-600 text-sm text-left" />

                                                </div>
                                                <div className="flex-1 relative">
                                                    <div className="relative">
                                                        <div className="absolute h-full flex items-center px-3">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.4" d="M9 1.5C7.035 1.5 5.4375 3.0975 5.4375 5.0625C5.4375 6.99 6.945 8.55 8.91 8.6175C8.97 8.61 9.03 8.61 9.075 8.6175C9.09 8.6175 9.0975 8.6175 9.1125 8.6175C9.12 8.6175 9.12 8.6175 9.1275 8.6175C11.0475 8.55 12.555 6.99 12.5625 5.0625C12.5625 3.0975 10.965 1.5 9 1.5Z" fill="black" />
                                                                <path d="M12.81 10.6125C10.7175 9.2175 7.30497 9.2175 5.19747 10.6125C4.24497 11.25 3.71997 12.1125 3.71997 13.035C3.71997 13.9575 4.24497 14.8125 5.18997 15.4425C6.23997 16.1475 7.61997 16.5 8.99997 16.5C10.38 16.5 11.76 16.1475 12.81 15.4425C13.755 14.805 14.28 13.95 14.28 13.02C14.2725 12.0975 13.755 11.2425 12.81 10.6125Z" fill="black" />
                                                            </svg>
                                                        </div>
                                                        <Field type="text" name="last_name" placeholder="Last name" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                    </div>
                                                    <ErrorMessage name="last_name" component={"div"} className="text-red-600 text-sm text-left" />

                                                </div>
                                            </div>
                                            <div className="flex-1 relative">
                                                <div className="relative">
                                                    <div className="absolute h-full flex items-center px-3">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.4" d="M8.8425 10.6575L6.39 13.11C6.12 12.87 5.8575 12.6225 5.6025 12.3675C4.83 11.5875 4.1325 10.77 3.51 9.915C2.895 9.06 2.4 8.205 2.04 7.3575C1.68 6.5025 1.5 5.685 1.5 4.905C1.5 4.395 1.59 3.9075 1.77 3.4575C1.95 3 2.235 2.58 2.6325 2.205C3.1125 1.7325 3.6375 1.5 4.1925 1.5C4.4025 1.5 4.6125 1.545 4.8 1.635C4.995 1.725 5.1675 1.86 5.3025 2.055L7.0425 4.5075C7.1775 4.695 7.275 4.8675 7.3425 5.0325C7.41 5.19 7.4475 5.3475 7.4475 5.49C7.4475 5.67 7.395 5.85 7.29 6.0225C7.1925 6.195 7.05 6.375 6.87 6.555L6.3 7.1475C6.2175 7.23 6.18 7.3275 6.18 7.4475C6.18 7.5075 6.1875 7.56 6.2025 7.62C6.225 7.68 6.2475 7.725 6.2625 7.77C6.3975 8.0175 6.63 8.34 6.96 8.73C7.2975 9.12 7.6575 9.5175 8.0475 9.915C8.3175 10.1775 8.58 10.4325 8.8425 10.6575Z" fill="black" />
                                                            <path d="M16.4775 13.7475C16.4775 13.9575 16.44 14.175 16.365 14.385C16.3425 14.445 16.32 14.505 16.29 14.565C16.1625 14.835 15.9975 15.09 15.78 15.33C15.4125 15.735 15.0075 16.0275 14.55 16.215C14.5425 16.215 14.535 16.2225 14.5275 16.2225C14.085 16.4025 13.605 16.5 13.0875 16.5C12.3225 16.5 11.505 16.32 10.6425 15.9525C9.77995 15.585 8.91745 15.09 8.06245 14.4675C7.76995 14.25 7.47745 14.0325 7.19995 13.8L9.65245 11.3475C9.86245 11.505 10.05 11.625 10.2075 11.7075C10.245 11.7225 10.29 11.745 10.3425 11.7675C10.4025 11.79 10.4625 11.7975 10.53 11.7975C10.6575 11.7975 10.755 11.7525 10.8375 11.67L11.4075 11.1075C11.595 10.92 11.775 10.7775 11.9475 10.6875C12.12 10.5825 12.2925 10.53 12.48 10.53C12.6225 10.53 12.7725 10.56 12.9375 10.6275C13.1025 10.695 13.275 10.7925 13.4625 10.92L15.945 12.6825C16.14 12.8175 16.275 12.975 16.3575 13.1625C16.4325 13.35 16.4775 13.5375 16.4775 13.7475Z" fill="black" />
                                                        </svg>
                                                    </div>

                                                    <Field type="date" name="date_of_birth" placeholder="Date of birth" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                </div>
                                                <ErrorMessage name="date_of_birth" component={"div"} className="text-red-600 text-sm text-left" />
                                            </div>
                                            <div className="flex-1 relative">
                                                <div className="relative">
                                                    <div className="absolute h-full flex items-center px-3">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.4" d="M8.8425 10.6575L6.39 13.11C6.12 12.87 5.8575 12.6225 5.6025 12.3675C4.83 11.5875 4.1325 10.77 3.51 9.915C2.895 9.06 2.4 8.205 2.04 7.3575C1.68 6.5025 1.5 5.685 1.5 4.905C1.5 4.395 1.59 3.9075 1.77 3.4575C1.95 3 2.235 2.58 2.6325 2.205C3.1125 1.7325 3.6375 1.5 4.1925 1.5C4.4025 1.5 4.6125 1.545 4.8 1.635C4.995 1.725 5.1675 1.86 5.3025 2.055L7.0425 4.5075C7.1775 4.695 7.275 4.8675 7.3425 5.0325C7.41 5.19 7.4475 5.3475 7.4475 5.49C7.4475 5.67 7.395 5.85 7.29 6.0225C7.1925 6.195 7.05 6.375 6.87 6.555L6.3 7.1475C6.2175 7.23 6.18 7.3275 6.18 7.4475C6.18 7.5075 6.1875 7.56 6.2025 7.62C6.225 7.68 6.2475 7.725 6.2625 7.77C6.3975 8.0175 6.63 8.34 6.96 8.73C7.2975 9.12 7.6575 9.5175 8.0475 9.915C8.3175 10.1775 8.58 10.4325 8.8425 10.6575Z" fill="black" />
                                                            <path d="M16.4775 13.7475C16.4775 13.9575 16.44 14.175 16.365 14.385C16.3425 14.445 16.32 14.505 16.29 14.565C16.1625 14.835 15.9975 15.09 15.78 15.33C15.4125 15.735 15.0075 16.0275 14.55 16.215C14.5425 16.215 14.535 16.2225 14.5275 16.2225C14.085 16.4025 13.605 16.5 13.0875 16.5C12.3225 16.5 11.505 16.32 10.6425 15.9525C9.77995 15.585 8.91745 15.09 8.06245 14.4675C7.76995 14.25 7.47745 14.0325 7.19995 13.8L9.65245 11.3475C9.86245 11.505 10.05 11.625 10.2075 11.7075C10.245 11.7225 10.29 11.745 10.3425 11.7675C10.4025 11.79 10.4625 11.7975 10.53 11.7975C10.6575 11.7975 10.755 11.7525 10.8375 11.67L11.4075 11.1075C11.595 10.92 11.775 10.7775 11.9475 10.6875C12.12 10.5825 12.2925 10.53 12.48 10.53C12.6225 10.53 12.7725 10.56 12.9375 10.6275C13.1025 10.695 13.275 10.7925 13.4625 10.92L15.945 12.6825C16.14 12.8175 16.275 12.975 16.3575 13.1625C16.4325 13.35 16.4775 13.5375 16.4775 13.7475Z" fill="black" />
                                                        </svg>
                                                    </div>
                                                    <Field type="text" name="phone" placeholder="Phone number" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                </div>
                                                <ErrorMessage name="phone" component={"div"} className="text-red-600 text-sm text-left" />
                                            </div>
                                            <div className="flex-1 relative">
                                                <div className="relative">
                                                    <div className="absolute h-full flex items-center px-3">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.4" d="M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z" fill="#292D32" />
                                                            <path d="M9.00008 9.6525C8.37008 9.6525 7.73258 9.4575 7.24508 9.06L4.89758 7.185C4.65758 6.99 4.61258 6.6375 4.80758 6.3975C5.00258 6.1575 5.35508 6.1125 5.59508 6.3075L7.94257 8.1825C8.51257 8.64 9.48007 8.64 10.0501 8.1825L12.3976 6.3075C12.6376 6.1125 12.9976 6.15 13.1851 6.3975C13.3801 6.6375 13.3426 6.9975 13.0951 7.185L10.7476 9.06C10.2676 9.4575 9.63008 9.6525 9.00008 9.6525Z" fill="#292D32" />
                                                        </svg>

                                                    </div>
                                                    <Field type="email" name="email" placeholder="Email" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                </div>
                                                <ErrorMessage name="email" component={"div"} className="text-red-600 text-sm text-left" />

                                            </div>
                                            <div className="flex-1 relative">
                                                <PasswordInput placeholder="Create password (min. of 8 characters)" name="password"
                                                    value={values.password}
                                                    onChange={(e: any) => handleChange(e)}
                                                    onBlur={handleBlur} />
                                                <ErrorMessage name="password" component={"div"} className="text-red-600 text-sm text-left" />

                                            </div>
                                            <div className="flex-1 relative">
                                                <PasswordInput placeholder="Confirm password" name="password_confirmation"
                                                    value={values.password_confirmation}
                                                    onChange={(e: any) => handleChange(e)}
                                                    onBlur={handleBlur} />
                                                <ErrorMessage name="password_confirmation" component={"div"} className="text-red-600 text-sm text-left" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-left text-[13px]">
                                            By clicking Sign up, you agree with Paywithfish <a href="" className="text-pink-600 underline"> Terms or service & Privacy policy.</a>
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <button type="submit" className="text-white rounded-lg py-2 bg-[#282828] w-full">

                                            {isSubmitting ? <svg className="w-5 h-5" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                                <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                    <animateTransform
                                                        attributeName="transform"
                                                        attributeType="XML"
                                                        type="rotate"
                                                        dur="1s"
                                                        from="0 50 50"
                                                        to="360 50 50"
                                                        repeatCount="indefinite" />
                                                </path>
                                            </svg> : "Sign up"}
                                        </button>
                                        <div className="mt-6">
                                            <p className="text-center">
                                                Already have an account, <Link to="/login" className="text-blue-600">Log in</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Form>)}
                    </Formik>}
            </Modal>
        </ >
    )
}
export default SignUpPage