const HelpCenter = () => {
    return (
        <div>
            <div className="mt-8">
                <div className="md:px-4">
                    <div className="text-2xl font-bold">
                        Help & Support
                    </div>
                </div>
            </div>
            <div className="mt-12 md:w-10/12">
                <div className="grid md:grid-cols-3 gap-4">
                    <div className="rounded-xl bg-white drop-shadow-[0px_0px_4px_rgba(0,0,0,0.25)] px-5 py-3 h-[176px] relative flex-1 z-10">
                        <div className="flex gap-2 items-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" fill="#EE2365" />
                                <path d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z" fill="#EE2365" />
                            </svg>
                            <div className="text-sm font-semibold">
                                Send us  a mail
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="text-sm">
                                Got a request or enquiry? shoot us a mail at <a href="mailto:" className="text-blue-800"> info@paywithfish.com</a>
                            </p>
                        </div>
                    </div>
                    <div className="rounded-xl bg-white drop-shadow-[0px_0px_4px_rgba(0,0,0,0.25)] px-5 py-3 h-[176px] relative flex-1 z-10">
                        <div className="flex gap-2 items-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 6.75C21.5188 6.75 22.75 5.51878 22.75 4C22.75 2.48122 21.5188 1.25 20 1.25C18.4812 1.25 17.25 2.48122 17.25 4C17.25 5.51878 18.4812 6.75 20 6.75Z" fill="#1D079F" />
                                <path opacity="0.4" d="M20 8C17.79 8 16 6.21 16 4C16 3.27 16.21 2.59 16.56 2H7C4.24 2 2 4.23 2 6.98V12.96V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V7.44C21.41 7.79 20.73 8 20 8Z" fill="#1D079F" />
                                <path d="M12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12Z" fill="#1D079F" />
                                <path d="M16 12C15.44 12 15 11.55 15 11C15 10.45 15.45 10 16 10C16.55 10 17 10.45 17 11C17 11.55 16.56 12 16 12Z" fill="#1D079F" />
                                <path d="M8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12Z" fill="#1D079F" />
                            </svg>

                            <div className="text-sm font-semibold">
                                Chat with us
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="text-sm">
                                Send us a message in app and we will respond to you as soon as possible.
                            </p>
                        </div>
                    </div>
                    <div className="rounded-xl bg-white drop-shadow-[0px_0px_4px_rgba(0,0,0,0.25)] px-5 py-3 h-[176px] relative flex-1 z-10">
                        <div className="flex gap-2 items-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 6.75C21.5188 6.75 22.75 5.51878 22.75 4C22.75 2.48122 21.5188 1.25 20 1.25C18.4812 1.25 17.25 2.48122 17.25 4C17.25 5.51878 18.4812 6.75 20 6.75Z" fill="#058240" />
                                <path opacity="0.4" d="M20 8C17.79 8 16 6.21 16 4C16 3.27 16.21 2.59 16.56 2H7C4.24 2 2 4.23 2 6.98V12.96V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V7.44C21.41 7.79 20.73 8 20 8Z" fill="#058240" />
                                <path d="M12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12Z" fill="#058240" />
                                <path d="M16 12C15.44 12 15 11.55 15 11C15 10.45 15.45 10 16 10C16.55 10 17 10.45 17 11C17 11.55 16.56 12 16 12Z" fill="#058240" />
                                <path d="M8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12Z" fill="#058240" />
                            </svg>

                            <div className="text-sm font-semibold">
                                FAQs
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="text-sm">
                                We have answers to some of the questions you may have. Visit our FAQ centre.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpCenter;