import ExchangeCard from "../../../Components/ExchangeCard"
import { APICall, dataURLtoFile, formatToCurrency, getIcon, getImage } from "../../../Utils"
import { Listbox, Menu, Popover, Transition } from '@headlessui/react'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { NGNTransactionItem, TransactionItem } from "../Components/TransactionItem"
import { Modal } from "../../../Components/Modal"
import { CreateBeneficiaryModal, DeleteModal, FundNairaWalletModal, FundWalletModal, InternationalTransferModal, LocalTransferModal, TransferFundsModal, TransferNairaFundsModal, WithdrawFundsModal } from "../Components"
import { confirmBVN, confirmBVNImage, getProfile, resendBVNOTP, verifyBVN } from "../../../Api/Auth"
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import Webcam from "react-webcam";
import { toast } from "react-toastify"
import { loadingBarRef } from "../../../App"
import { calculateFX, deleteBeneficiary, getAllBanks, getAllWallets, getBeneficiaries, getNGNTransactionHistory, getVirtualAccount, getVirtualAccountBalance, getWalletTransactionHistory, verifyIdentity } from "../../../Api/General"
import { WalletModel } from "../../../Models/General"
import { BeneficiariesModel } from "../../../Models/Beneficiaries"
import { NGNTransactionHistoryModel, TransactionModel } from "../../../Models/Transactions"
import { updateVirtualAccountAsync } from "../../../Redux/VirtualWallet"
import { updateWalletsAsync } from "../../../Redux/Wallets"
import { updateBanksAsync } from "../../../Redux/Banks"
import { updateBeneficiariesAsync } from "../../../Redux/Beneficiary"
import { useFilePicker } from "use-file-picker";
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from "formik";
import { updatePurposesAsync } from "../../../Redux/Purpose";
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { usePopper } from "react-popper"
import moment from "moment"
import { updateUser } from "../../../Redux/Auth"
import "/node_modules/flag-icons/css/flag-icons.min.css";
import getSymbolFromCurrency from 'currency-symbol-map'


const GetStartedModal: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {
    const user = useAppSelector(state => state.auth.user);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [verificationPic, setVerificationPic] = useState<any>("");
    const [otpVerified, setOtpVerified] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpRequired, setOtpRequired] = useState(false);

    const [bvn, setBVN] = useState("");

    const [timer, setTimer] = useState(0);
    const [verifying, setVerifying] = useState(false);
    const [resent, setResent] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer == 0)
                return;
            const t = timer - 1;
            setTimer(t)
        }, 1000)

        return () => clearInterval(interval);
    });

    const handleResendOtp = async () => {
        try {
            const response = await APICall(resendBVNOTP, { bvn }, true);
            setTimer(60);
            toast(response.data.message);
        } catch (error) {

        }
    }

    const [progress, setProgress] = useState({
        stepOne: "pending",
        stepTwo: "pending",
        stepThree: "pending",

    });

    const videoConstraints = {
        width: 320,
        height: 320,
        facingMode: "user"
    };

    const [openUtilityBillPicker, { plainFiles: utilityBill, clear: clearUtilityBill }] = useFilePicker({
        readAs: 'DataURL',
        accept: ['image/*', "pdf"],
        multiple: false,
        limitFilesConfig: { max: 1 },
        maxFileSize: 1,
    });

    const [openIdBillPicker, { plainFiles: idFile, clear: clearIdFile }] = useFilePicker({
        readAs: 'DataURL',
        accept: ['image/*', "pdf"],
        multiple: false,
        limitFilesConfig: { max: 1 },
        maxFileSize: 1,
    });

    const validationSchema = Yup.object().shape({
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("state is required"),
        city: Yup.string().required("City is required"),
        zipcode: Yup.string().required("Zip Code is required"),
        home_address: Yup.string().required("Home address is required"),
        identification_type: Yup.string().required("identification type is required"),
        identification_number: Yup.string().required("identification number is required"),
    });

    const [verificationData, setVerificationData] = useState({
        country: "",
        state: "",
        city: "",
        zipcode: "",
        home_address: "",
        utility_bill_image: "",
        identification_image: "",
        identification_type: "",
        identification_number: "",

    })

    useEffect(() => {
        if (utilityBill.length) {
            setVerificationData({ ...verificationData, utility_bill_image: utilityBill[0] as any });
        }
    }, [utilityBill]);

    useEffect(() => {
        if (idFile.length) {
            setVerificationData({ ...verificationData, identification_image: idFile[0] as any });
        }
    }, [idFile])


    const webcamRef = useRef<Webcam>(null);
    const capture = useCallback(
        async () => {
            try {
                loadingBarRef.current?.continuousStart();
                const imageSrc = webcamRef.current?.getScreenshot();
                setVerificationPic(imageSrc)
                const file = await dataURLtoFile(imageSrc as string, "profile")
                const response = await confirmBVNImage({ image: file });
                toast(response.data.message, { type: "success" });
                loadingBarRef.current?.complete();
                setStep(3);

                // onClose();

            } catch (error: any) {
                if (error.response) {
                    if (error.response.data.message.toLowerCase().includes("already"))
                        onClose();
                    toast(error.response.data.message, { type: "error" });
                }
                loadingBarRef.current?.complete();

            }
        },
        [webcamRef]
    );


    const handleVerifyIdentity = async (values: any, setSubmitting: (val: boolean) => void) => {
        try {
            if (loading)
                return;
            setLoading(true);
            const data = { ...values, utility_bill_image: verificationData.utility_bill_image, identification_image: verificationData.identification_image }
            const formData = new FormData();
            setProgress({ ...progress, stepTwo: "completed" });

            for (let val in data) {
                formData.set(val, data[val]);
            }
            const response = await APICall(verifyIdentity, formData, true);
            setLoading(false);
            onClose();

        } catch (error: any) {
            console.log(error)
            setLoading(false);
            if (error.response) {
                if (error.response.data.message.includes("already"))
                    onClose();
            }
        }
    }

    const handleVerifyBVN = async () => {
        try {
            setLoading(true);
            if (loading)
                return;
            const resposne = await APICall(confirmBVN, { bvn }, true);
            setProgress({ ...progress, stepOne: "completed" });
            if (resposne.data.message.includes("OTP")) {
                setTimer(60);
                setOtpRequired(true);
                setLoading(false);
                return;
            }
            setStep(2);
            setLoading(false);

        } catch (error) {

            setLoading(false);
        }
    }

    const handleVerifyOTP = async () => {
        try {
            setLoading(true);
            if (loading)
                return;
            const resposne = await APICall(verifyBVN, { otp }, true);
            setProgress({ ...progress, stepOne: "completed" });
            setStep(2);
            setOtpRequired(false);
            setOtpVerified(true);
            setLoading(false);

        } catch (error) {

            setLoading(false);
        }
    }

    const handleConfirm = () => {
        switch (step) {
            case 1: {

                otpRequired ? handleVerifyOTP() : handleVerifyBVN();
            }
        }
    }

    useEffect(() => {
        if (user?.bvn_verified_at) {
            setProgress({ ...progress, stepOne: "completed" });
            setStep(2);
        }

    }, [])



    return (
        <Modal open={open} onClose={onClose} size="sm">
            <div className="p-4 text-left h-[90vh] flex flex-col justify-between overflow-auto">
                <div>
                    <div className="flex gap-2">
                        <div className={`flex items-center gap-2 cursor-pointer ${step == 1 ? "text-black pb-1 border-b-2 border-neutral" : "text-neutral"}`}>
                            <div>
                                {
                                    progress.stepOne == "completed" ? <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="30" height="29" rx="11.5" fill="#FFCAFF" stroke="#ED2DF0" />
                                        <path d="M14.0757 18.8232L10.1757 14.9008C9.94142 14.6652 9.94142 14.2831 10.1757 14.0474L11.0242 13.194C11.2585 12.9583 11.6385 12.9583 11.8728 13.194L14.5 15.8363L20.1272 10.1767C20.3615 9.94109 20.7415 9.94109 20.9758 10.1767L21.8243 11.0301C22.0586 11.2658 22.0586 11.6479 21.8243 11.8836L14.9243 18.8233C14.6899 19.0589 14.31 19.0589 14.0757 18.8232Z" fill="#ED2DF0" />
                                    </svg> :
                                        <span className="text-sm font-medium bg-[#F5F5F5] border border-neutral px-3 py-1.5 rounded-xl">
                                            1
                                        </span>
                                }

                            </div>


                            <div className={`text-sm font-semibold text-left ${progress.stepTwo == "completed" ? "text-[#ED2DF0]" : ""}`}>
                                BVN verification
                            </div>
                        </div>

                        <div className={`flex items-center gap-2 cursor-pointer ${step == 2 ? "text-black pb-1 border-b-2 border-neutral" : "text-neutral"}`}>
                            <div>
                                {
                                    progress.stepTwo == "completed" ? <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="30" height="29" rx="11.5" fill="#FFCAFF" stroke="#ED2DF0" />
                                        <path d="M14.0757 18.8232L10.1757 14.9008C9.94142 14.6652 9.94142 14.2831 10.1757 14.0474L11.0242 13.194C11.2585 12.9583 11.6385 12.9583 11.8728 13.194L14.5 15.8363L20.1272 10.1767C20.3615 9.94109 20.7415 9.94109 20.9758 10.1767L21.8243 11.0301C22.0586 11.2658 22.0586 11.6479 21.8243 11.8836L14.9243 18.8233C14.6899 19.0589 14.31 19.0589 14.0757 18.8232Z" fill="#ED2DF0" />
                                    </svg> :
                                        <span className="text-sm font-medium bg-[#F5F5F5] border border-neutral px-3 py-1.5 rounded-xl">
                                            3
                                        </span>
                                }

                            </div>
                            <div className={`text-sm font-semibold text-left ${progress.stepTwo == "completed" ? "text-[#ED2DF0]" : ""}`}>
                                <div className="text-sm font-semibold text-left">
                                    Facial verification
                                </div>
                            </div>
                        </div>
                        <div className={`flex items-center gap-2 cursor-pointer ${step == 3 ? "text-black pb-1 border-b-2 border-neutral" : "text-neutral"}`}>
                            <div>
                                {
                                    progress.stepThree == "completed" ? <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="30" height="29" rx="11.5" fill="#FFCAFF" stroke="#ED2DF0" />
                                        <path d="M14.0757 18.8232L10.1757 14.9008C9.94142 14.6652 9.94142 14.2831 10.1757 14.0474L11.0242 13.194C11.2585 12.9583 11.6385 12.9583 11.8728 13.194L14.5 15.8363L20.1272 10.1767C20.3615 9.94109 20.7415 9.94109 20.9758 10.1767L21.8243 11.0301C22.0586 11.2658 22.0586 11.6479 21.8243 11.8836L14.9243 18.8233C14.6899 19.0589 14.31 19.0589 14.0757 18.8232Z" fill="#ED2DF0" />
                                    </svg> :
                                        <span className="text-sm font-medium bg-[#F5F5F5] border border-neutral px-3 py-1.5 rounded-xl">
                                            3
                                        </span>
                                }

                            </div>
                            <div className={`text-sm font-semibold text-left ${progress.stepThree == "completed" ? "text-[#ED2DF0]" : ""}`}>
                                Identity verification
                            </div>
                        </div>
                    </div>

                    {
                        (() => {
                            switch (step) {
                                case 1:
                                    return <div className="p-4 mt-4">
                                        {!otpRequired ? <>
                                            <div>
                                                <span className="text-lg font-semibold text-black">
                                                    Verify your BVN
                                                </span>
                                            </div>
                                            <div className="text-sm">
                                                <span>We need your bvn to confirm your identity</span>
                                            </div>
                                            <div className="mt-12">
                                                <div className="text-sm font-medium mb-2 text-neutral">
                                                    Enter your BVN
                                                </div>
                                                <input value={bvn} onChange={(e) => setBVN(e.target.value)} type="text" className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" />

                                                <div className="mt-3 text-neutral text-sm">
                                                    You will receive a 6 digits verification pin as an sms on your BVN registered phone number.
                                                </div>
                                            </div>
                                        </> :
                                            <>
                                                <div>
                                                    <span className="text-lg font-semibold text-black">
                                                        Verify your OTP
                                                    </span>
                                                </div>

                                                <div className="mt-12">
                                                    <div className="text-sm font-medium mb-2 text-neutral">
                                                        Enter your OTP
                                                    </div>
                                                    <input value={otp} onChange={(e) => { if (e.target.value.length <= 6) setOtp(e.target.value) }} type="text" className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" />

                                                    <div className="mt-3 text-neutral text-sm">
                                                        Enter your 6 digits verification pin sent to your BVN registered phone number.
                                                    </div>
                                                    {timer !== 0 ? <div className="text-center mt-4">
                                                        resend otp in {timer} seconds
                                                    </div> :
                                                        <div onClick={handleResendOtp} className="font-semibold cursor-pointe mt-4">resend</div>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                case 3:
                                    return <div className="p-4 mt-4">
                                        <div>
                                            <span className="text-lg font-semibold text-black">
                                                One last step left to finish up
                                            </span>
                                        </div>
                                        <div className="text-sm">
                                            <span>
                                                please fill in your details accordingly.
                                            </span>
                                        </div>
                                        <Formik
                                            initialValues={{
                                                country: "",
                                                state: "",
                                                city: "",
                                                zipcode: "",
                                                home_address: "",
                                                identification_type: "",
                                                identification_number: "",
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={(values, { setSubmitting }) => {
                                                handleVerifyIdentity(values, setSubmitting)
                                            }}
                                        >
                                            {({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => (
                                                <Form>
                                                    <div className="mt-12">
                                                        <div className="space-y-4">
                                                            <div className="flex gap-3">
                                                                <div>
                                                                    <div className="text-sm font-medium mb-2 text-neutral">
                                                                        Select country
                                                                    </div>
                                                                    <select
                                                                        value={values.country}
                                                                        name="country"
                                                                        onChange={(e) => {
                                                                            setVerificationData({ ...verificationData, country: e.target.value })
                                                                            handleChange(e)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full">
                                                                        <option value=""></option>
                                                                        <option value="Nigeria">Nigeria</option>

                                                                    </select>
                                                                    <ErrorMessage name="country" component={"div"} className="text-red-600 text-sm text-left" />
                                                                </div>
                                                                <div>
                                                                    <div className="text-sm font-medium mb-2 text-neutral">
                                                                        State
                                                                    </div>
                                                                    <input
                                                                        value={values.state}
                                                                        name="state"
                                                                        onChange={(e) => {
                                                                            setVerificationData({ ...verificationData, state: e.target.value })
                                                                            handleChange(e)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        type="text" className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" />
                                                                    <ErrorMessage name="state" component={"div"} className="text-red-600 text-sm text-left" />
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-3">
                                                                <div>
                                                                    <div className="text-sm font-medium mb-2 text-neutral">
                                                                        City
                                                                    </div>
                                                                    <input
                                                                        value={values.city}
                                                                        name="city"
                                                                        onChange={(e) => {
                                                                            setVerificationData({ ...verificationData, city: e.target.value })
                                                                            handleChange(e)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        type="text" className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" />
                                                                    <ErrorMessage name="city" component={"div"} className="text-red-600 text-sm text-left" />
                                                                </div>
                                                                <div>
                                                                    <div className="text-sm font-medium mb-2 text-neutral">
                                                                        Zip code
                                                                    </div>
                                                                    <input
                                                                        value={values.zipcode}
                                                                        name="zipcode"
                                                                        onChange={(e) => {
                                                                            setVerificationData({ ...verificationData, zipcode: e.target.value })
                                                                            handleChange(e)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        type="text" className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" />
                                                                    <ErrorMessage name="zipcode" component={"div"} className="text-red-600 text-sm text-left" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="text-sm font-medium mb-2 text-neutral">
                                                                    Home address
                                                                </div>
                                                                <input
                                                                    value={values.home_address}
                                                                    name="home_address"
                                                                    onChange={(e) => {
                                                                        setVerificationData({ ...verificationData, home_address: e.target.value })
                                                                        handleChange(e)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    type="text" className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" />
                                                                <ErrorMessage name="home_address" component={"div"} className="text-red-600 text-sm text-left" />
                                                            </div>
                                                            <div className="text-neutral">
                                                                <div>
                                                                    Upload address utility bill

                                                                    <ul className="text-xs list-disc ml-6">
                                                                        <li>
                                                                            Not more than 3 months old
                                                                        </li>
                                                                        <li>
                                                                            Max file of 5MB
                                                                        </li>
                                                                        <li>
                                                                            Address must be same with your home address
                                                                        </li>
                                                                    </ul>
                                                                    <div onClick={() => openUtilityBillPicker()} className="flex items-center justify-center w-full cursor-pointer">
                                                                        <label className="flex flex-col items-center justify-center w-full py-4 border mt-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ">
                                                                            <p className="text-sm text-primary bg-transparent drop-shadow-lg shadow-primary">
                                                                                {utilityBill.length ? utilityBill[0].name : "Upload file"}
                                                                            </p>
                                                                            {/* <input id="dropzone-file" type="file" className="hidden" /> */}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="text-sm font-medium mb-2 text-neutral">
                                                                    Identification type
                                                                </div>
                                                                <select
                                                                    value={values.identification_type}
                                                                    name="identification_type"
                                                                    onChange={(e) => {
                                                                        setVerificationData({ ...verificationData, identification_type: e.target.value })
                                                                        handleChange(e)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" >
                                                                    <option value=""></option>
                                                                    <option value="drivers license">Drivers License</option>
                                                                    <option value="International passport">International Passport</option>
                                                                    <option value="nin">NIN</option>
                                                                    <option value="voters card">Voters card</option>
                                                                </select>
                                                                <ErrorMessage name="identification_type" component={"div"} className="text-red-600 text-sm text-left" />
                                                            </div>
                                                            <div>
                                                                <div className="text-sm font-medium mb-2 text-neutral">
                                                                    Identification number
                                                                </div>
                                                                <input
                                                                    value={values.identification_number}
                                                                    name="identification_number"
                                                                    onChange={(e) => {
                                                                        setVerificationData({ ...verificationData, identification_number: e.target.value })
                                                                        handleChange(e)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    type="text" className="border bg-[#F5F5F5] border-[#BEBEBE] rounded-lg py-4 px-6 text-sm text-[#5A5A5A] w-full" />
                                                                <ErrorMessage name="identification_number" component={"div"} className="text-red-600 text-sm text-left" />
                                                            </div>

                                                            <div className="text-neutral">
                                                                <div>
                                                                    Upload a valid ID

                                                                    <ul className="text-xs list-disc ml-6">
                                                                        <li>
                                                                            Federal govt. issues ID e.g int. passport, National ID.
                                                                        </li>
                                                                        <li>
                                                                            Max file of 5MB
                                                                        </li>
                                                                        <li>
                                                                            Original document and not photocopy
                                                                        </li>
                                                                    </ul>
                                                                    <div onClick={() => openIdBillPicker()} className="flex items-center justify-center w-full cursor-pointer">
                                                                        <label className="flex flex-col items-center justify-center w-full py-4 border mt-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ">
                                                                            <p className="text-sm text-primary bg-transparent drop-shadow-lg shadow-primary">
                                                                                {idFile.length ? idFile[0].name : "Upload file"}

                                                                            </p>

                                                                            {/* <input id="dropzone-file" type="file" className="hidden" /> */}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-12 top-2/4 p-4">
                                                        <div className="flex gap-3">
                                                            <button
                                                                type="submit"
                                                                className="text-sm border-2 bg-primary border-primary rounded-xl text-white py-3 px-12">
                                                                {
                                                                    loading ? <svg className="w-5 h-5" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                                                        <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                            <animateTransform
                                                                                attributeName="transform"
                                                                                attributeType="XML"
                                                                                type="rotate"
                                                                                dur="1s"
                                                                                from="0 50 50"
                                                                                to="360 50 50"
                                                                                repeatCount="indefinite" />
                                                                        </path>
                                                                    </svg> : "Verify"
                                                                }
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={onClose}
                                                                className="text-sm border border-[#282828] rounded-xl text-[#282828] py-3 px-6">
                                                                Continue later
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>)}
                                        </Formik>
                                    </div>
                                case 2:
                                    return <div className="p-4 mt-4">
                                        <div>
                                            <span className="text-lg font-semibold text-black">
                                                Final stage!
                                            </span>
                                        </div>
                                        <div className="text-sm">
                                            <span>This last stage will require you to upload your live selfie with your device camera. This is an important requirement for organizations like ours providing cross boarder payments.</span>
                                        </div>
                                        <div className="mt-6 text-center">
                                            <div className="mx-auto bg-slate-100 w-[320px] h-[320px]">
                                                {verificationPic ?
                                                    <img src={verificationPic} className="w-[320px]" alt="" /> :
                                                    <Webcam
                                                        ref={webcamRef}
                                                        audio={false}
                                                        mirrored
                                                        height={320}
                                                        screenshotFormat="image/png"
                                                        width={320}
                                                        videoConstraints={videoConstraints}
                                                    />}
                                            </div>
                                            <div className="mt-2 w-10/12 mx-auto">
                                                Ensure you are doing this in a bright environment and show your face clearly.
                                            </div>

                                        </div>
                                        <div className="mt-12">
                                            <div className="w-10/12 mx-auto">
                                                <button
                                                    onClick={() => {
                                                        capture();
                                                        // setStep(3)
                                                    }}
                                                    className="text-sm border-2 bg-primary border-primary rounded-xl text-white py-3 w-full">
                                                    Verify
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        })()
                    }
                </div>
                {
                    step == 1 && <div className="mt-12 top-2/4 p-4">
                        <div className="flex gap-3">
                            <button
                                onClick={handleConfirm}
                                className="text-sm border-2 bg-primary border-primary rounded-xl text-white py-3 px-12">
                                {
                                    loading ? <svg className="w-5 h-5" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                        <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                            <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="rotate"
                                                dur="1s"
                                                from="0 50 50"
                                                to="360 50 50"
                                                repeatCount="indefinite" />
                                        </path>
                                    </svg> : "Verify"
                                }
                            </button>
                            <button
                                onClick={onClose}
                                className="text-sm border border-[#282828] rounded-xl text-[#282828] py-3 px-6">
                                Continue later
                            </button>
                        </div>
                    </div>
                }

            </div>
        </Modal>
    )
}

export const CompleteKYC = () => {
    const [showModal, setShowModal] = useState(false);
    const user = useAppSelector(state => state.auth.user);
    return (
        <>
            <GetStartedModal open={showModal} onClose={() => setShowModal(false)} />
            <div className="bg-[#FFEFEF] rounded-lg px-6 pt-6 pb-8 font-futura">
                <div>
                    <div className="text-xl text-black font-semibold">
                        <span className="font-sans">Complete your KYC to verify your account.</span>
                        <span className="bg-blue-100 text-blue-800 font-medium ml-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 text-sm">
                            High priority
                        </span>
                    </div>
                </div>
                <div className="mt-8">
                    <div className="flex gap-4 items-center">
                        <div className="flex gap-2 items-center">
                            <div>
                                {
                                    user?.bvn_verified_at ? <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="30" height="29" rx="11.5" fill="#FFCAFF" stroke="#ED2DF0" />
                                        <path d="M14.0757 18.8232L10.1757 14.9008C9.94142 14.6652 9.94142 14.2831 10.1757 14.0474L11.0242 13.194C11.2585 12.9583 11.6385 12.9583 11.8728 13.194L14.5 15.8363L20.1272 10.1767C20.3615 9.94109 20.7415 9.94109 20.9758 10.1767L21.8243 11.0301C22.0586 11.2658 22.0586 11.6479 21.8243 11.8836L14.9243 18.8233C14.6899 19.0589 14.31 19.0589 14.0757 18.8232Z" fill="#ED2DF0" />
                                    </svg> :
                                        <span className="text-sm font-medium bg-[#F5F5F5] border border-neutral px-3 py-1.5 rounded-xl">
                                            1
                                        </span>
                                }
                            </div>
                            <div className="text-sm font-medium">
                                BVN verification
                            </div>
                        </div>
                        <div>
                            <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.0076 1.59178H0.267857C0.119933 1.59178 0 1.71378 0 1.86424V3.13573C0 3.2862 0.119933 3.4082 0.267857 3.4082H7.0076V4.45398C7.0076 4.93946 7.58463 5.18259 7.92213 4.83931L9.84309 2.88532C10.0523 2.6725 10.0523 2.32747 9.84309 2.11468L7.92213 0.160695C7.58465 -0.182585 7.0076 0.0605421 7.0076 0.546025V1.59178Z" fill="#282828" />
                            </svg>
                        </div>
                        <div className="flex gap-2 items-center">
                            <div>
                                <span className="text-sm font-medium bg-white border border-neutral px-3 py-1.5 rounded-xl">
                                    2
                                </span>
                            </div>
                            <div className="text-sm font-medium">
                                Identity verification
                            </div>
                        </div>
                        <div>
                            <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.0076 1.59178H0.267857C0.119933 1.59178 0 1.71378 0 1.86424V3.13573C0 3.2862 0.119933 3.4082 0.267857 3.4082H7.0076V4.45398C7.0076 4.93946 7.58463 5.18259 7.92213 4.83931L9.84309 2.88532C10.0523 2.6725 10.0523 2.32747 9.84309 2.11468L7.92213 0.160695C7.58465 -0.182585 7.0076 0.0605421 7.0076 0.546025V1.59178Z" fill="#282828" />
                            </svg>
                        </div>
                        <div className="flex gap-2 items-center">
                            <div>
                                <span className="text-sm font-medium bg-white border border-neutral px-3 py-1.5 rounded-xl">
                                    3
                                </span>
                            </div>
                            <div className="text-sm font-medium">
                                Documents upload
                            </div>
                        </div>
                        <div>
                            <a href="#" onClick={() => setShowModal(true)} className="ml-4 text-[#EE2365] font-medium border-b border-[#EE2356]">
                                <span>
                                    Get Started
                                </span>
                                <span>
                                    <svg className="inline ml-2" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.97063 5.79241C6.49064 5.3921 6.49064 4.6079 5.97063 4.20759L1.85999 1.04322C1.20243 0.537025 0.25 1.00579 0.25 1.83563L0.25 8.16437C0.25 8.99421 1.20243 9.46298 1.85999 8.95678L5.97063 5.79241Z" fill="#EE2365" />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const NairaExchangeCard = () => {
    const [showFundNairaWallet, setShowFundNairaWallet] = useState(false);
    const [showTransferNaira, setShowTransferNaira] = useState(false);
    const virtualAccountBalance = useAppSelector(state => state.virtualWallet.account);

    return (
        <>
            <FundNairaWalletModal open={showFundNairaWallet} onClose={() => setShowFundNairaWallet(false)} />
            <TransferNairaFundsModal open={showTransferNaira} onClose={() => setShowTransferNaira(false)} />

            <ExchangeCard size="sm">
                <div className="px-4 py-3">
                    <div className="flex justify-between items-center text-white">
                        <div>
                            <div className="text-4xl font-semibold">{getSymbolFromCurrency("NGN")}</div>
                        </div>
                        <div className="flex gap-1 items-center text-white">
                            <div>
                                <span className="fi fi-ng object-fill fis !w-6 !h-6 rounded-full border border-white"></span>
                            </div>
                            <div className="text-white font-semibold text-sm">
                                NGN
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 text-white">
                        <div className="text-[35px] font-semibold">

                            {
                                virtualAccountBalance?.available ? formatToCurrency(virtualAccountBalance?.available / 100) : "0.00"
                            }

                        </div>
                    </div>
                    <div className="mt-4 text-sm font-medium text-[#FFB9FF] font-futura">
                        <div className="flex gap-4">
                            <div onClick={() => setShowFundNairaWallet(true)} className="flex gap-1 items-center cursor-pointer">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="#FFB9FF" />
                                </svg>
                                <div>
                                    Fund wallet
                                </div>
                            </div>
                            <div onClick={() => setShowTransferNaira(true)} className="flex gap-1 items-center cursor-pointer">
                                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.8643 6.03823L6.74529 1.88385C6.64729 1.52264 6.41035 1.21489 6.0862 1.02781C5.76205 0.840725 5.37704 0.789513 5.01525 0.885357L0.858275 1.9947C0.677672 2.04369 0.523796 2.16217 0.430254 2.32424C0.336713 2.48632 0.311106 2.67882 0.359029 2.85971C0.382873 2.95013 0.424385 3.03493 0.481168 3.10922C0.537951 3.18352 0.608882 3.24583 0.68987 3.29258C0.770858 3.33932 0.860299 3.36956 0.953034 3.38156C1.04577 3.39356 1.13996 3.38708 1.23018 3.3625L4.41346 2.50903L0.518634 9.2574C0.424728 9.4201 0.399302 9.61345 0.447952 9.7949C0.496601 9.97636 0.61534 10.1311 0.778047 10.225C0.940754 10.3189 1.1341 10.3443 1.31555 10.2956C1.49701 10.247 1.6517 10.1283 1.74561 9.96555L5.64044 3.21718L6.4939 6.40047C6.54194 6.58219 6.6602 6.73739 6.82266 6.83193C6.98513 6.92646 7.17849 6.95258 7.36022 6.90455C7.54194 6.85651 7.69715 6.73826 7.79168 6.57579C7.88621 6.41333 7.91234 6.21996 7.8643 6.03823Z" fill="#FFB9FF" />
                                </svg>

                                <div>
                                    Transfer
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ExchangeCard>
        </>
    )
}

export const VExchangeCard: React.FC<{ wallet: WalletModel }> = ({ wallet }) => {
    const [showFundWallet, setShowFundWallet] = useState(false);
    const [showIntTransferModal, setShowIntTransferModal] = useState(false);
    const [showLocalTransferModal, setShowLocalTransferModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const dispatch = useAppDispatch();


    return (
        <>
            <FundWalletModal
                open={showFundWallet}
                walletId={wallet?.id as number}
                walletName={wallet.currency}
                walletCurrency={wallet.currency}
                onClose={() => {
                    setShowFundWallet(false);
                }} />
            <WithdrawFundsModal
                open={showWithdrawModal}
                walletId={wallet?.id as number}
                walletName={wallet.currency}
                walletCurrency={wallet.currency}
                onClose={() => {
                    setShowWithdrawModal(false);
                }} />
            <TransferFundsModal
                open={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                onInternationalTransfer={() => setShowIntTransferModal(true)}
                onLocalTransfer={() => setShowLocalTransferModal(true)} />
            <InternationalTransferModal walletId={wallet?.id as number} open={showIntTransferModal} onClose={() => setShowIntTransferModal(false)} />
            <LocalTransferModal walletId={wallet?.id as number} open={showLocalTransferModal} onClose={() => setShowLocalTransferModal(false)} />

            <ExchangeCard size="sm">
                <div className="px-4 py-3">
                    <div className="flex justify-between items-center">
                        <div className="text-white">
                            {/* {<img className="w-6" src={getIcon("dollar.png")} alt="" />} */}
                            <div className="text-4xl font-semibold">{getSymbolFromCurrency(wallet.currency)}</div>
                        </div>
                        <div className="flex gap-1 items-center">
                            <div>
                                <span className={`fi fi-${wallet.currency.substring(0, 2).toLowerCase()} object-fill fis !w-6 !h-6 rounded-full border border-white`}></span>
                            </div>
                            <div className="text-white font-semibold text-sm">
                                {wallet.currency}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 text-white">
                        <div className="text-[35px] font-semibold">

                            {
                                wallet ? formatToCurrency(wallet.amount / 100) : "0.00"
                            }

                        </div>
                    </div>
                    <div className="mt-4 text-sm font-medium text-[#FFB9FF] font-futura">
                        <div className="flex gap-4">
                            <div onClick={() => setShowFundWallet(true)} className="flex gap-1 items-center cursor-pointer">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z" fill="#FFB9FF" />
                                </svg>
                                <div>
                                    Fund wallet
                                </div>
                            </div>
                            <div onClick={() => setShowTransferModal(true)} className="flex gap-1 items-center cursor-pointer">
                                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.8643 6.03823L6.74529 1.88385C6.64729 1.52264 6.41035 1.21489 6.0862 1.02781C5.76205 0.840725 5.37704 0.789513 5.01525 0.885357L0.858275 1.9947C0.677672 2.04369 0.523796 2.16217 0.430254 2.32424C0.336713 2.48632 0.311106 2.67882 0.359029 2.85971C0.382873 2.95013 0.424385 3.03493 0.481168 3.10922C0.537951 3.18352 0.608882 3.24583 0.68987 3.29258C0.770858 3.33932 0.860299 3.36956 0.953034 3.38156C1.04577 3.39356 1.13996 3.38708 1.23018 3.3625L4.41346 2.50903L0.518634 9.2574C0.424728 9.4201 0.399302 9.61345 0.447952 9.7949C0.496601 9.97636 0.61534 10.1311 0.778047 10.225C0.940754 10.3189 1.1341 10.3443 1.31555 10.2956C1.49701 10.247 1.6517 10.1283 1.74561 9.96555L5.64044 3.21718L6.4939 6.40047C6.54194 6.58219 6.6602 6.73739 6.82266 6.83193C6.98513 6.92646 7.17849 6.95258 7.36022 6.90455C7.54194 6.85651 7.69715 6.73826 7.79168 6.57579C7.88621 6.41333 7.91234 6.21996 7.8643 6.03823Z" fill="#FFB9FF" />
                                </svg>

                                <div>
                                    Transfer
                                </div>
                            </div>
                            <div onClick={() => setShowWithdrawModal(true)} className="flex gap-1 items-center cursor-pointer">
                                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.8643 6.03823L6.74529 1.88385C6.64729 1.52264 6.41035 1.21489 6.0862 1.02781C5.76205 0.840725 5.37704 0.789513 5.01525 0.885357L0.858275 1.9947C0.677672 2.04369 0.523796 2.16217 0.430254 2.32424C0.336713 2.48632 0.311106 2.67882 0.359029 2.85971C0.382873 2.95013 0.424385 3.03493 0.481168 3.10922C0.537951 3.18352 0.608882 3.24583 0.68987 3.29258C0.770858 3.33932 0.860299 3.36956 0.953034 3.38156C1.04577 3.39356 1.13996 3.38708 1.23018 3.3625L4.41346 2.50903L0.518634 9.2574C0.424728 9.4201 0.399302 9.61345 0.447952 9.7949C0.496601 9.97636 0.61534 10.1311 0.778047 10.225C0.940754 10.3189 1.1341 10.3443 1.31555 10.2956C1.49701 10.247 1.6517 10.1283 1.74561 9.96555L5.64044 3.21718L6.4939 6.40047C6.54194 6.58219 6.6602 6.73739 6.82266 6.83193C6.98513 6.92646 7.17849 6.95258 7.36022 6.90455C7.54194 6.85651 7.69715 6.73826 7.79168 6.57579C7.88621 6.41333 7.91234 6.21996 7.8643 6.03823Z" fill="#FFB9FF" />
                                </svg>
                                <div>
                                    Withdraw
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ExchangeCard >
        </>
    )
}

export const BalanceOverview = () => {
    const dispatch = useAppDispatch();
    const wallets = useAppSelector(state => state.wallets.wallets);
    const handleFetchVirtualAccount = async () => {
        try {
            const response = await getVirtualAccount();
            // setVirtualAccount(response.data.data);
        } catch (error) {

        }
    }

    useEffect(() => {
        handleFetchVirtualAccount()
        dispatch(updateVirtualAccountAsync());
        dispatch(updateWalletsAsync());
        dispatch(updateBanksAsync())
        dispatch(updatePurposesAsync());
    })

    return (
        <div>

            <div className="text-[20px] px-2">
                Balance Overview
            </div>
            <div className="flex gap-4 mt-4 overflow-x-auto overflow-y-hidden">
                <div>
                    <NairaExchangeCard />
                </div>
                {
                    wallets.map(wallet => {
                        return <div className="" key={"wallet" + wallet.id}>
                            <VExchangeCard wallet={wallet} />
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export const RateCalculator = () => {
    const wallets = useAppSelector(state => state.wallets.wallets);
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(wallets.length ? wallets[1] : {id: "1", currency: "USD"});
    const [to, setTo] = useState({ currency: "NGN", id: 0 } as any);

    const [fromVal, setFromVal] = useState("0");
    const [toVal, setToVal] = useState("0");
    const [rate, setRate] = useState("0.00");


    const handleRateChange = async () => {
        try {
            setLoading(true);
            const response = await calculateFX(from.currency, to.currency);
            setRate(response.data.data.rate);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleRateChange();
    }, [from, to]);

    return (
        <div className="rounded-xl bg-white drop-shadow-[0px_0px_4px_rgba(0,0,0,0.25)] px-5 py-6 relative flex-1 z-10">
            <div className="absolute top-0 right-0">
                <img src={getImage("cash.png")} alt="" className="w-[200px]" />
            </div>
            <div>
                <span className="text-lg font-semibold">
                    Rate Calculator (FX)
                </span>
            </div>
            <div className="mt-6">
                <div>
                    <span className="font-futura text-sm">
                        Today's rate
                    </span>
                    <span>
                        <svg className="inline ml-1" width="48" height="22" viewBox="0 0 48 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_65_2055)">
                                <path d="M5 8.5L14 1L21.5 13L27.5 4.5L35 8.5L43.5 1" stroke="#058240" />
                            </g>
                            <defs>
                                <filter id="filter0_d_65_2055" x="0.679932" y="0.248901" width="47.1509" height="21.6539" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.555156 0 0 0 0 0.870833 0 0 0 0 0.704156 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_65_2055" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_65_2055" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                    </span>
                </div>
                <div className="mt-3 flex items-center">
                    <span className="font-futura text-2xl font-semibold uppercase">
                        1 {from.currency} = {Number(rate).toFixed(2)} {to.currency}
                    </span>
                    {
                        loading && <svg className="w-8 h-8" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                            <path fill="black" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </div>
            </div>
            <div className="px-2 mt-5">
                <div className="space-y-4">
                    <div className="space-y-1">
                        <div className="text-sm text-[#5A5A5A]">
                            Convert from
                        </div>
                        <div className="relative">
                            <input type="text" value={fromVal} onChange={(e) => {
                                const val = e.target.value.replaceAll(",", "");
                                if (!isNaN(Number(val))) {
                                    setFromVal(formatToCurrency(val));
                                    if (!isNaN(Number(rate)))
                                        setToVal(formatToCurrency((Number(val) * Number(rate)).toFixed(2)));
                                }
                            }} className="relative w-full py-2 rounded-sm border border-[#969696] px-4" />
                            <div className="absolute right-0 top-0 flex justify-end py-1 h-full items-center">
                                <Listbox as="div" value={from} onChange={setFrom} className="relative inline-block text-left">
                                    <div className="flex items-center">
                                        <Listbox.Button className="inline-flex gap-2 w-full pl-12 border-l border-[#969696] justify-center pr-4 text-sm font-medium items-center">
                                            <div className="flex gap-1 items-center">
                                                <div>
                                                    <span className={`fi fi-${from.currency.substring(0, 2).toLowerCase()} object-fill fis !w-5 !h-5 rounded-full border border-white`}></span>
                                                </div>
                                                <div className="font-semibold text-sm">
                                                    {from.currency}
                                                </div>
                                            </div>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 7.5L0.303848 0.75L10.6962 0.75L5.5 7.5Z" fill="#A0A0A0" />
                                            </svg>

                                        </Listbox.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Listbox.Options
                                            className="absolute right-0 mt-2 w-24 origin-top-right divide-y divide-gray-100 space-y-1 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                                        >
                                            {to.id != 0 && <Listbox.Option
                                                key={"from" + 0}
                                                value={{ currency: "NGN", id: 0 } as any}
                                                className="px-2 cursor-pointer"
                                            >
                                                <div className="flex items-center py-1">
                                                    <span className={`fi fi-ng object-fill fis !w-5 !h-5 rounded-full border border-white`}></span>
                                                    <div>
                                                        NGN
                                                    </div>
                                                </div>
                                            </Listbox.Option>}
                                            {wallets.filter(v => v.id != to.id).map((wallet) => (
                                                <Listbox.Option
                                                    key={"from" + wallet.id}
                                                    value={wallet}
                                                    disabled={from.id == wallet.id}
                                                    className="px-2 cursor-pointer"
                                                >
                                                    <div className="flex items-center py-1">
                                                        <span className={`fi fi-${wallet.currency.substring(0, 2).toLowerCase()} object-fill fis !w-5 !h-5 rounded-full border border-white`}></span>
                                                        <div>
                                                            {wallet.currency}
                                                        </div>
                                                    </div>
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </Listbox>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-1">
                        <div className="text-sm text-[#5A5A5A]">
                            Convert to
                        </div>
                        <div className="relative">
                            <input type="text" value={toVal} onChange={(e) => {
                                const val = e.target.value.replaceAll(",", "");

                                if (!isNaN(Number(val))) {
                                    setToVal(formatToCurrency(val));
                                    if (!isNaN(Number(rate)))
                                        setFromVal(formatToCurrency((Number(val) / Number(rate)).toFixed(2)));
                                }
                            }} className="relative w-full py-2 rounded-sm border border-[#969696] px-4" />
                            <div className="absolute right-0 top-0 flex justify-end py-1 h-full items-center">
                                <Listbox onChange={setTo} as="div" value={to} className="relative inline-block text-left">
                                    <div className="flex items-center">
                                        <Listbox.Button className="inline-flex gap-2 w-full pl-12 border-l border-[#969696] justify-center pr-4 text-sm font-medium items-center">
                                            <div className="flex gap-1 items-center">
                                                <div>
                                                    <span className={`fi fi-${to.currency.substring(0, 2).toLowerCase()} object-fill fis !w-5 !h-5 rounded-full border border-white`}></span>
                                                </div>
                                                <div className="font-semibold text-sm">
                                                    {to.currency}
                                                </div>
                                            </div>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 7.5L0.303848 0.75L10.6962 0.75L5.5 7.5Z" fill="#A0A0A0" />
                                            </svg>

                                        </Listbox.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Listbox.Options className="absolute right-0 mt-2 w-24 origin-top-right divide-y divide-gray-100 space-y-1 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {from.id != 0 && <Listbox.Option
                                                key={"to" + 0}
                                                value={{ currency: "NGN", id: 0 } as any}
                                                className="px-2 cursor-pointer"
                                            >
                                                <div className="flex items-center py-1">
                                                    <span className={`fi fi-ng object-fill fis !w-5 !h-5 rounded-full border border-white`}></span>
                                                    <div>
                                                        NGN
                                                    </div>
                                                </div>
                                            </Listbox.Option>}
                                            {wallets.filter(v => v.id != from.id).map((wallet) => (
                                                <Listbox.Option
                                                    key={"to" + wallet.id}
                                                    value={wallet}
                                                    disabled={to.id == wallet.id}
                                                    className="px-2 cursor-pointer"
                                                >
                                                    <div className="flex items-center py-1">
                                                        <span className={`fi fi-${wallet.currency.substring(0, 2).toLowerCase()} object-fill fis !w-5 !h-5 rounded-full border border-white`}></span>
                                                        <div>
                                                            {wallet.currency}
                                                        </div>
                                                    </div>
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </Listbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Beneficiaries = () => {
    const beneficiaries = useAppSelector(state => state.beneficiaries.beneficairies);
    const [selectedBeneficiary, setSelectedBeneficairy] = useState<BeneficiariesModel | null>(null)
    const [showCreateBeneficiary, setShowCreateBeneficiary] = useState(false);
    const [showDeleteBeneficiary, setShowDeleteBeneficiary] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const handleGetBeneficiaries = async () => {
        try {
            dispatch(updateBeneficiariesAsync());
        } catch (error) {

        }
    }

    const handleUpdateBeneficiary = async () => {
        try {

        } catch (error) {

        }
    }

    const handleDeleteBeneficiary = async () => {
        try {
            if (!selectedBeneficiary || loading) return;
            const response = await APICall(deleteBeneficiary, selectedBeneficiary.id, true)
            setShowDeleteBeneficiary(false);
            handleGetBeneficiaries();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleGetBeneficiaries();
    }, [showCreateBeneficiary])

    return (
        <>
            <CreateBeneficiaryModal beneficiary={selectedBeneficiary as any} open={showCreateBeneficiary} onClose={() => { setSelectedBeneficairy(null); setShowCreateBeneficiary(false) }} />
            <DeleteModal open={showDeleteBeneficiary} onClose={() => { setSelectedBeneficairy(null); setShowDeleteBeneficiary(false) }} message="Delete Beneficiary" onConfirm={handleDeleteBeneficiary} />

            <div className="rounded-xl bg-white drop-shadow-[0px_0px_4px_rgba(0,0,0,0.25)] px-5 py-6 relative md:flex-1 overflow-hidden md:h-auto h-96">
                <div className="absolute top-0 right-0">
                    <img src={getImage("at.png")} alt="" className="w-[200px]" />
                </div>
                <div className="flex justify-between relative z-10">
                    <span className="text-lg font-semibold">
                        Saved Beneficiaries
                    </span>
                    <div>
                        <button onClick={() => setShowCreateBeneficiary(true)} className="outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                            </svg>
                        </button>
                    </div>
                </div>
                {
                    beneficiaries.length ? <div className="mt-6 max-h-[226px] overflow-y-auto">
                        <div className="space-y-4">

                            {
                                beneficiaries.map((val) => {
                                    return (
                                        <div key={val.account_number} className="flex gap-2">
                                            <div>
                                                <div className="w-16 h-16 rounded-full bg-[#DFF2F6] flex text-[#2F5C67] items-center justify-center text-lg uppercase">
                                                    {
                                                        val.entity_type == "individual" ? val.first_name[0] + val.last_name[0] : val.company_name[0]
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-lg font-semibold capitalize">
                                                    {
                                                        val.entity_type == "individual" ? val.first_name + " " + val.last_name : val.company_name
                                                    }
                                                </div>
                                                <div className="text-sm">
                                                    ACCT: {val.account_number}
                                                </div>
                                            </div>
                                            <div className="flex-1 flex justify-end relative z-10">
                                                <div>
                                                    <button onClick={() => { setSelectedBeneficairy(val); setShowCreateBeneficiary(true) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                        </svg>

                                                    </button>
                                                </div>
                                                <div>
                                                    <button onClick={() => { setSelectedBeneficairy(val); setShowDeleteBeneficiary(true) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> :
                        <div className="flex items-center justify-center h-full">
                            <div className="text-center">
                                <svg className="inline" width="75" height="37" viewBox="0 0 75 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_59_217)">
                                        <path d="M37.5 26.0625C41.3818 26.0625 44.5312 22.9131 44.5312 19.0312C44.5312 15.1494 41.3818 12 37.5 12C33.6182 12 30.4688 15.1494 30.4688 19.0312C30.4688 22.9131 33.6182 26.0625 37.5 26.0625ZM43.75 27.625H41.0596C39.9756 28.123 38.7695 28.4062 37.5 28.4062C36.2305 28.4062 35.0293 28.123 33.9404 27.625H31.25C27.7979 27.625 25 30.4229 25 33.875V34.6562C25 35.9502 26.0498 37 27.3438 37H47.6562C48.9502 37 50 35.9502 50 34.6562V33.875C50 30.4229 47.2021 27.625 43.75 27.625Z" fill="#FFE6FF" />
                                    </g>
                                    <g clip-path="url(#clip1_59_217)">
                                        <path d="M62.5 14.0625C66.3818 14.0625 69.5312 10.9131 69.5312 7.03125C69.5312 3.14941 66.3818 0 62.5 0C58.6182 0 55.4688 3.14941 55.4688 7.03125C55.4688 10.9131 58.6182 14.0625 62.5 14.0625ZM68.75 15.625H66.0596C64.9756 16.123 63.7695 16.4062 62.5 16.4062C61.2305 16.4062 60.0293 16.123 58.9404 15.625H56.25C52.7979 15.625 50 18.4229 50 21.875V22.6562C50 23.9502 51.0498 25 52.3438 25H72.6562C73.9502 25 75 23.9502 75 22.6562V21.875C75 18.4229 72.2021 15.625 68.75 15.625Z" fill="#FFE6FF" />
                                    </g>
                                    <g clip-path="url(#clip2_59_217)">
                                        <path d="M12.5 14.0625C16.3818 14.0625 19.5312 10.9131 19.5312 7.03125C19.5312 3.14941 16.3818 0 12.5 0C8.61816 0 5.46875 3.14941 5.46875 7.03125C5.46875 10.9131 8.61816 14.0625 12.5 14.0625ZM18.75 15.625H16.0596C14.9756 16.123 13.7695 16.4062 12.5 16.4062C11.2305 16.4062 10.0293 16.123 8.94043 15.625H6.25C2.79785 15.625 0 18.4229 0 21.875V22.6562C0 23.9502 1.0498 25 2.34375 25H22.6562C23.9502 25 25 23.9502 25 22.6562V21.875C25 18.4229 22.2021 15.625 18.75 15.625Z" fill="#FFE6FF" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_59_217">
                                            <rect width="25" height="25" fill="white" transform="translate(25 12)" />
                                        </clipPath>
                                        <clipPath id="clip1_59_217">
                                            <rect width="25" height="25" fill="white" transform="translate(50)" />
                                        </clipPath>
                                        <clipPath id="clip2_59_217">
                                            <rect width="25" height="25" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className="text-center text-[#5A5A5A] mt-2 text-lg">
                                    You have not added any beneficiary
                                </div>
                            </div>
                        </div>
                }

            </div>
        </>
    )
}

export const NGNTransactionHistory = () => {
    const [dateVisible, setDateVisible] = useState(false);
    let [referenceElement, setReferenceElement] = useState<any>()
    let [popperElement, setPopperElement] = useState<any>()
    let { styles, attributes } = usePopper(referenceElement, popperElement);
    const [transactions, setTransactions] = useState<NGNTransactionHistoryModel[]>([]);
    const [dateRange, setDateRange] = useState([{ startDate: moment().subtract(1, "months").toDate(), endDate: moment().toDate(), key: "selection" }]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterBy, setFilterBy] = useState("");

    const goToPrev = () => {
        setCurrentPage(currentPage - 1);
    }

    const goToNext = () => {
        setCurrentPage(currentPage + 1);

    }

    const onChange = (ranges: RangeKeyDict) => {
        const [start, end] = [ranges.selection.startDate as Date, ranges.selection.endDate as Date];
        setDateRange([{ startDate: start, endDate: end, key: "selection" }]);
    }


    const handleGetTransactions = async () => {
        try {
            setLoading(true);
            const response = await getNGNTransactionHistory(currentPage, perPage, moment(dateRange[0].startDate).format("YYYY-MM-DD"), moment(dateRange[0].endDate).format("YYYY-MM-DD"));
            setTransactions(response.data.data.results);
            setTotal(response.data.data.total);
            setLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        handleGetTransactions();
    }, [currentPage, dateRange])

    return (
        <div className="rounded-xl bg-white border px-5 py-6 relative flex-1 overflow-hidden">
            <div className="flex justify-between md:flex-row flex-col md:gap-0 gap-2">
                <div className="flex items-center">
                    <span className="text-lg font-semibold">
                        NGN Transactions History
                    </span>
                    {
                        loading && <svg className="w-8 h-8" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                            <path fill="black" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </div>

                <div className="flex gap-4 md:flex-row flex-col">
                    <Menu as="div" className="relative inline-block text-left">
                        <div className="flex items-center">
                            <Menu.Button className="inline-flex gap-2 w-full px-3 py-3 border border-[#969696] justify-center rounded text-sm font-medium items-center">
                                <div className="flex gap-1 items-center">
                                    <div>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9414 4.32656L10.7555 0.76875C10.6031 0.309375 10.1742 0 9.68906 0H6.375V4.5H11.9695C11.9602 4.44141 11.9602 4.38281 11.9414 4.32656ZM5.625 0H2.31094C1.82578 0 1.39687 0.309375 1.24453 0.76875L0.0585938 4.32656C0.0398437 4.38281 0.0398437 4.44141 0.0304687 4.5H5.625V0ZM0 5.25V10.875C0 11.4961 0.503906 12 1.125 12H10.875C11.4961 12 12 11.4961 12 10.875V5.25H0Z" fill="#282828" />
                                        </svg>
                                    </div>
                                    <div className="text-neutral md:text-sm">
                                        Filter by status
                                    </div>
                                </div>
                                <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 7.5L0.303848 0.75L10.6962 0.75L5.5 7.5Z" fill="#A0A0A0" />
                                </svg>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                <Menu.Item as="button" onClick={() => setFilterBy("debit")} className={"w-full"}>
                                    {({ active }) => (
                                        <div
                                            className={`flex items-center gap-4 px-2 py-2  ${active ? "bg-zinc-100" : ""}`}

                                        >
                                            <div className="w-[42px] h-[33px] rounded-xl bg-[#FFEDF3] flex  items-center justify-center text-lg">
                                                <svg className="w-[14px]" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                                    <g clipPath="url(#clip0_137_849)">
                                                        <path d="M17 33.9167V8.91675" stroke="#EC3662" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M26.9163 17L16.9997 7.08333L7.08301 17" stroke="#EC3662" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_137_849">
                                                            <rect width="34" height="34" fill="white" transform="matrix(1 0 0 -1 0 34)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="text-xl font-semibold text-red-600">
                                                Debit
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                                <Menu.Item as="button" onClick={() => setFilterBy("credit")} className={"w-full"}>
                                    {({ active }) => (
                                        <div
                                            className={`flex items-center gap-4 px-2 py-2 ${active ? "bg-zinc-100" : ""}`}

                                        >
                                            <div className="w-[42px] h-[33px] rounded-xl bg-green-100 text-green-600 flex  items-center justify-center text-lg">
                                                <svg className="rotate-180 w-[14px]" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                                    <g clipPath="url(#clip0_137_849)">
                                                        <path d="M17 33.9167V8.91675" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M26.9163 17L16.9997 7.08333L7.08301 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_137_849">
                                                            <rect width="34" height="34" fill="white" transform="matrix(1 0 0 -1 0 34)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="md:text-xl font-semibold text-green-600">
                                                Credit
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                                <Menu.Item as="button" onClick={() => setFilterBy("")} className={"w-full"}>
                                    {({ active }) => (
                                        <div
                                            className={`flex items-center gap-4 px-2 py-2 ${active ? "bg-zinc-100" : ""}`}

                                        >
                                            <div className="w-[42px] h-[33px] rounded-xl bg-sky-200 text-sky-600 flex  items-center justify-center text-lg">
                                                <svg className="w-[14px] inline" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                                    <g clipPath="url(#clip0_137_849)">
                                                        <path d="M17 33.9167V8.91675" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M26.9163 17L16.9997 7.08333L7.08301 17" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_137_849">
                                                            <rect width="34" height="34" fill="white" transform="matrix(1 0 0 -1 0 34)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <svg className="rotate-180 w-[14px] inline" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                                    <g clipPath="url(#clip0_137_849)">
                                                        <path d="M17 33.9167V8.91675" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M26.9163 17L16.9997 7.08333L7.08301 17" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_137_849">
                                                            <rect width="34" height="34" fill="white" transform="matrix(1 0 0 -1 0 34)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="md:text-xl font-semibold text-sky-600">
                                                All
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <Popover className="relative z-10">
                        <div className="flex items-center">
                            <Popover.Button className={"relative inline-flex gap-2 w-full px-3 py-3 border border-[#969696] justify-center rounded text-sm font-medium items-center"} ref={setReferenceElement} onClick={() => setDateVisible(!dateVisible)} >
                                <div className="flex gap-1 items-center">
                                    <div>
                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12.6875C0 13.4121 0.575893 14 1.28571 14H10.7143C11.4241 14 12 13.4121 12 12.6875V5.25H0V12.6875ZM8.57143 7.32812C8.57143 7.14766 8.71607 7 8.89286 7H9.96429C10.1411 7 10.2857 7.14766 10.2857 7.32812V8.42188C10.2857 8.60234 10.1411 8.75 9.96429 8.75H8.89286C8.71607 8.75 8.57143 8.60234 8.57143 8.42188V7.32812ZM8.57143 10.8281C8.57143 10.6477 8.71607 10.5 8.89286 10.5H9.96429C10.1411 10.5 10.2857 10.6477 10.2857 10.8281V11.9219C10.2857 12.1023 10.1411 12.25 9.96429 12.25H8.89286C8.71607 12.25 8.57143 12.1023 8.57143 11.9219V10.8281ZM5.14286 7.32812C5.14286 7.14766 5.2875 7 5.46429 7H6.53571C6.7125 7 6.85714 7.14766 6.85714 7.32812V8.42188C6.85714 8.60234 6.7125 8.75 6.53571 8.75H5.46429C5.2875 8.75 5.14286 8.60234 5.14286 8.42188V7.32812ZM5.14286 10.8281C5.14286 10.6477 5.2875 10.5 5.46429 10.5H6.53571C6.7125 10.5 6.85714 10.6477 6.85714 10.8281V11.9219C6.85714 12.1023 6.7125 12.25 6.53571 12.25H5.46429C5.2875 12.25 5.14286 12.1023 5.14286 11.9219V10.8281ZM1.71429 7.32812C1.71429 7.14766 1.85893 7 2.03571 7H3.10714C3.28393 7 3.42857 7.14766 3.42857 7.32812V8.42188C3.42857 8.60234 3.28393 8.75 3.10714 8.75H2.03571C1.85893 8.75 1.71429 8.60234 1.71429 8.42188V7.32812ZM1.71429 10.8281C1.71429 10.6477 1.85893 10.5 2.03571 10.5H3.10714C3.28393 10.5 3.42857 10.6477 3.42857 10.8281V11.9219C3.42857 12.1023 3.28393 12.25 3.10714 12.25H2.03571C1.85893 12.25 1.71429 12.1023 1.71429 11.9219V10.8281ZM10.7143 1.75H9.42857V0.4375C9.42857 0.196875 9.23571 0 9 0H8.14286C7.90714 0 7.71429 0.196875 7.71429 0.4375V1.75H4.28571V0.4375C4.28571 0.196875 4.09286 0 3.85714 0H3C2.76429 0 2.57143 0.196875 2.57143 0.4375V1.75H1.28571C0.575893 1.75 0 2.33789 0 3.0625V4.375H12V3.0625C12 2.33789 11.4241 1.75 10.7143 1.75Z" fill="#282828" />
                                        </svg>
                                    </div>
                                    <div className="text-neutral text-sm">
                                        Filter by date
                                    </div>
                                </div>
                                <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 7.5L0.303848 0.75L10.6962 0.75L5.5 7.5Z" fill="#A0A0A0" />
                                </svg>
                            </Popover.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Popover.Panel
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper}
                                className="absolute z-40 text-black shadow-md">
                                <DateRangePicker
                                    ranges={dateRange}
                                    onChange={onChange}
                                />
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                </div>
            </div>
            <div className="mt-6">
                <div className="space-y-8">

                    {
                        transactions?.length ? <>
                            {transactions.filter(v => {
                                if (filterBy == "")
                                    return true;
                                return (v.type.toLowerCase() == filterBy);
                            }).map((t) => {
                                return <>
                                    <NGNTransactionItem key={t.reference_number} transaction={t} />
                                </>
                            })}
                            <div className="flex gap-2 items-center">
                                <div className="flex">
                                    <button disabled={currentPage == 1} onClick={goToPrev} className="disabled:opacity-60 outline-none bg-zinc-200 px-1 py-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                        </svg>
                                    </button>
                                    <button disabled={(currentPage * perPage) >= total} onClick={goToNext} className="disabled:opacity-60 outline-none bg-zinc-200 px-1 py-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </button>
                                </div>
                                {
                                    loading && <svg className="w-8 h-8" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                        <path fill="black" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                            <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="rotate"
                                                dur="1s"
                                                from="0 50 50"
                                                to="360 50 50"
                                                repeatCount="indefinite" />
                                        </path>
                                    </svg>
                                }
                            </div>
                        </>
                            :
                            <div>
                                <div className="w-5/12 text-center mx-auto">
                                    <img src={getImage("no-transactions.png")} alt="" className="inline w-full" />
                                </div>
                                <div className="text-lg text-[#5A5A5A] text-center">
                                    There has been no transaction on your account
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export const WalletTransactionHistory: React.FC<{ currency: string }> = ({ currency }) => {
    const [dateVisible, setDateVisible] = useState(false);
    let [referenceElement, setReferenceElement] = useState<any>()
    let [popperElement, setPopperElement] = useState<any>()
    let { styles, attributes } = usePopper(referenceElement, popperElement);
    const [transactions, setTransactions] = useState<TransactionModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([{ startDate: moment().subtract(1, "months").toDate(), endDate: moment().toDate(), key: "selection" }]);

    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);

    const goToPrev = () => {
        setCurrentPage(currentPage - 1);
    }

    const goToNext = () => {
        setCurrentPage(currentPage + 1);

    }

    const onChange = (ranges: RangeKeyDict) => {
        const [start, end] = [ranges.selection.startDate as Date, ranges.selection.endDate as Date];
        setDateRange([{ startDate: start, endDate: end, key: "selection" }]);
    }


    const handleGetTransactions = async () => {
        try {
            setLoading(true);
            const response = await getWalletTransactionHistory({
                page: currentPage,
                per_page: perPage,
                start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
                end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
                currency
            });
            setTransactions(response.data.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleGetTransactions();
    }, [currentPage, dateRange])

    return (
        <div className="rounded-xl bg-white border px-5 py-6 relative flex-1 overflow-hidden">
            <div className="text-left">
                <div className="flex items-center gap-2">
                    <span className="text-lg font-semibold">
                        {currency} Transactions History
                    </span>
                    {
                        loading && <svg className="w-8 h-8" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                            <path fill="black" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </div>
                <div className="flex gap-4">
                    {/* <Menu as="div" className="relative inline-block text-left">
                        <div className="flex items-center">
                            <Menu.Button className="inline-flex gap-2 w-full px-3 py-3 border border-[#969696] justify-center rounded text-sm font-medium items-center">
                                <div className="flex gap-1 items-center">
                                    <div>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9414 4.32656L10.7555 0.76875C10.6031 0.309375 10.1742 0 9.68906 0H6.375V4.5H11.9695C11.9602 4.44141 11.9602 4.38281 11.9414 4.32656ZM5.625 0H2.31094C1.82578 0 1.39687 0.309375 1.24453 0.76875L0.0585938 4.32656C0.0398437 4.38281 0.0398437 4.44141 0.0304687 4.5H5.625V0ZM0 5.25V10.875C0 11.4961 0.503906 12 1.125 12H10.875C11.4961 12 12 11.4961 12 10.875V5.25H0Z" fill="#282828" />
                                        </svg>
                                    </div>
                                    <div className="text-neutral text-sm">
                                        Filter by status
                                    </div>
                                </div>
                                <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 7.5L0.303848 0.75L10.6962 0.75L5.5 7.5Z" fill="#A0A0A0" />
                                </svg>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <Popover className="relative z-10">
                        <div className="flex items-center">
                            <Popover.Button className={"relative inline-flex gap-2 w-full px-3 py-3 border border-[#969696] justify-center rounded text-sm font-medium items-center"} ref={setReferenceElement} onClick={() => setDateVisible(!dateVisible)} >
                                <div className="flex gap-1 items-center">
                                    <div>
                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12.6875C0 13.4121 0.575893 14 1.28571 14H10.7143C11.4241 14 12 13.4121 12 12.6875V5.25H0V12.6875ZM8.57143 7.32812C8.57143 7.14766 8.71607 7 8.89286 7H9.96429C10.1411 7 10.2857 7.14766 10.2857 7.32812V8.42188C10.2857 8.60234 10.1411 8.75 9.96429 8.75H8.89286C8.71607 8.75 8.57143 8.60234 8.57143 8.42188V7.32812ZM8.57143 10.8281C8.57143 10.6477 8.71607 10.5 8.89286 10.5H9.96429C10.1411 10.5 10.2857 10.6477 10.2857 10.8281V11.9219C10.2857 12.1023 10.1411 12.25 9.96429 12.25H8.89286C8.71607 12.25 8.57143 12.1023 8.57143 11.9219V10.8281ZM5.14286 7.32812C5.14286 7.14766 5.2875 7 5.46429 7H6.53571C6.7125 7 6.85714 7.14766 6.85714 7.32812V8.42188C6.85714 8.60234 6.7125 8.75 6.53571 8.75H5.46429C5.2875 8.75 5.14286 8.60234 5.14286 8.42188V7.32812ZM5.14286 10.8281C5.14286 10.6477 5.2875 10.5 5.46429 10.5H6.53571C6.7125 10.5 6.85714 10.6477 6.85714 10.8281V11.9219C6.85714 12.1023 6.7125 12.25 6.53571 12.25H5.46429C5.2875 12.25 5.14286 12.1023 5.14286 11.9219V10.8281ZM1.71429 7.32812C1.71429 7.14766 1.85893 7 2.03571 7H3.10714C3.28393 7 3.42857 7.14766 3.42857 7.32812V8.42188C3.42857 8.60234 3.28393 8.75 3.10714 8.75H2.03571C1.85893 8.75 1.71429 8.60234 1.71429 8.42188V7.32812ZM1.71429 10.8281C1.71429 10.6477 1.85893 10.5 2.03571 10.5H3.10714C3.28393 10.5 3.42857 10.6477 3.42857 10.8281V11.9219C3.42857 12.1023 3.28393 12.25 3.10714 12.25H2.03571C1.85893 12.25 1.71429 12.1023 1.71429 11.9219V10.8281ZM10.7143 1.75H9.42857V0.4375C9.42857 0.196875 9.23571 0 9 0H8.14286C7.90714 0 7.71429 0.196875 7.71429 0.4375V1.75H4.28571V0.4375C4.28571 0.196875 4.09286 0 3.85714 0H3C2.76429 0 2.57143 0.196875 2.57143 0.4375V1.75H1.28571C0.575893 1.75 0 2.33789 0 3.0625V4.375H12V3.0625C12 2.33789 11.4241 1.75 10.7143 1.75Z" fill="#282828" />
                                        </svg>
                                    </div>
                                    <div className="text-neutral text-sm">
                                        Filter by date
                                    </div>
                                </div>
                                <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 7.5L0.303848 0.75L10.6962 0.75L5.5 7.5Z" fill="#A0A0A0" />
                                </svg>
                            </Popover.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Popover.Panel
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper}
                                className="absolute z-40 text-black shadow-md">
                                <DateRangePicker
                                    ranges={dateRange}
                                    onChange={onChange}
                                />
                            </Popover.Panel>
                        </Transition>
                    </Popover> */}
                </div>
            </div>
            <div className="mt-6">
                <div className="space-y-8">

                    {
                        transactions?.length ?
                            <>
                                {transactions.map((t) => {
                                    return <TransactionItem key={t.created_at + "wallet" + t.id} transaction={t} />
                                })}
                                <div className="flex gap-2 items-center">
                                    <div className="flex">
                                        <button disabled={currentPage == 1} onClick={goToPrev} className="disabled:opacity-60 outline-none bg-zinc-200 px-1 py-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                            </svg>
                                        </button>
                                        <button disabled={(currentPage * perPage) >= total} onClick={goToNext} className="disabled:opacity-60 outline-none bg-zinc-200 px-1 py-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </button>
                                    </div>
                                    {
                                        loading && <svg className="w-8 h-8" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                            <path fill="black" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                <animateTransform
                                                    attributeName="transform"
                                                    attributeType="XML"
                                                    type="rotate"
                                                    dur="1s"
                                                    from="0 50 50"
                                                    to="360 50 50"
                                                    repeatCount="indefinite" />
                                            </path>
                                        </svg>
                                    }
                                </div>
                            </>
                            :
                            <div>
                                <div className="w-5/12 text-center mx-auto">
                                    <img src={getImage("no-transactions.png")} alt="" className="inline w-full" />
                                </div>
                                <div className="text-lg text-[#5A5A5A] text-center">
                                    There has been no transaction on your account
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

const UserDashboard = () => {
    const user = useAppSelector(state => state.auth.user);
    const dispatch = useAppDispatch();
    const wallets = useAppSelector(state => state.wallets.wallets);
    const handleProfile = async () => {
        try {
            const response = await getProfile();
            dispatch(updateUser({ user: response.data.data }));
        } catch (error) {

        }
    }

    useEffect(() => {
        handleProfile()
    }, [])
    return (
        <div id="dashboard">
            <div className="mt-8">
                {
                    !user?.profile_completed_at && <CompleteKYC />
                }
                <div className="">
                    <BalanceOverview />
                </div>
                <div className="mt-12">
                    <div className="flex md:flex-row flex-col gap-8">
                        <RateCalculator />
                        <Beneficiaries />
                    </div>
                </div>
                <div className="mt-24">
                    <NGNTransactionHistory />
                </div>
            </div>
        </div>
    )
}

export default UserDashboard