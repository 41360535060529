import moment from "moment";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../Hooks";
import { useEffect, useState } from "react";

const Navbar = () => {
    const user = useAppSelector(state => state.auth.user);

    return (
        <nav className=" inset-0 z-10 bg-[#FBFBFB]">
            <div className="py-3 px-4 md:px-12">
                <div className="flex items-center justify-between">
                    <div className="">
                        <div className="relative">
                            <div className="text-lg font-semibold capitalize">
                                Welcome {user?.first_name},
                            </div>
                            <div className="text-neutral">
                                {moment().format("MMM Do, YY")}
                            </div>
                        </div>
                    </div>


                    <div className="md:block hidden">
                        <div className="flex gap-4">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="18" cy="18" r="18" fill="#E2E2E2" />
                                <path d="M26 12.75C27.5188 12.75 28.75 11.5188 28.75 10C28.75 8.48122 27.5188 7.25 26 7.25C24.4812 7.25 23.25 8.48122 23.25 10C23.25 11.5188 24.4812 12.75 26 12.75Z" fill="#030602" />
                                <path opacity="0.4" d="M26 14C23.79 14 22 12.21 22 10C22 9.27 22.21 8.59 22.56 8H13C10.24 8 8 10.23 8 12.98V18.96V19.96C8 22.71 10.24 24.94 13 24.94H14.5C14.77 24.94 15.13 25.12 15.3 25.34L16.8 27.33C17.46 28.21 18.54 28.21 19.2 27.33L20.7 25.34C20.89 25.09 21.19 24.94 21.5 24.94H23C25.76 24.94 28 22.71 28 19.96V13.44C27.41 13.79 26.73 14 26 14Z" fill="#030602" />
                                <path d="M18 18C17.44 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.56 18 18 18Z" fill="#030602" />
                                <path d="M22 18C21.44 18 21 17.55 21 17C21 16.45 21.45 16 22 16C22.55 16 23 16.45 23 17C23 17.55 22.56 18 22 18Z" fill="#030602" />
                                <path d="M14 18C13.44 18 13 17.55 13 17C13 16.45 13.45 16 14 16C14.55 16 15 16.45 15 17C15 17.55 14.56 18 14 18Z" fill="#030602" />
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
        </nav>
    )
}

export const HomeNavbar = () => {
    const [showWhite, setShowWhite] = useState(false);
    const handleScroll = (e: Event) => {
        if (window.scrollY > 120) {
            setShowWhite(true);
            return;
        }
        setShowWhite(false);
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    return (
        <nav className={`fixed inset-0 z-10  ease-in-out transition-all ${showWhite ? "bg-white bg-opacity-70 b backdrop-blur-sm h-[62px]" : "h-[66px]"}`}>
            <div className="py-3 px-4 md:px-12">
                <div className="flex items-center justify-between">
                    <div className="">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.4888 17.4777C12.4888 18.7701 11.5513 19.1786 10.3996 19.1786H7.37946V15.6362H10.4598C11.7054 15.6429 12.4888 16.1585 12.4888 17.4777ZM11.9732 11.9665C11.9732 10.7812 11.0558 10.5 10.0379 10.5H7.38616V13.5H10.2455C11.2567 13.5 11.9732 13.058 11.9732 11.9665ZM20.8326 13.5201C19.6071 13.5201 18.7902 14.2835 18.7098 15.5089H22.875C22.7612 14.2701 22.1183 13.5201 20.8326 13.5201ZM30 3.21429V26.7857C30 28.5603 28.5603 30 26.7857 30H3.21429C1.43973 30 0 28.5603 0 26.7857V3.21429C0 1.43973 1.43973 0 3.21429 0H26.7857C28.5603 0 30 1.43973 30 3.21429ZM18.1942 10.2455H23.404V8.97991H18.1942V10.2455ZM15.3147 17.6317C15.3147 16.0179 14.5513 14.625 12.971 14.1763C14.1228 13.6272 14.7254 12.9911 14.7254 11.6987C14.7254 9.14062 12.817 8.51786 10.6138 8.51786H4.55357V21.375H10.7879C13.125 21.3616 15.3147 20.2433 15.3147 17.6317ZM25.4464 16.6406C25.4464 13.8884 23.8326 11.5915 20.9196 11.5915C18.0804 11.5915 16.1585 13.721 16.1585 16.5201C16.1585 19.4196 17.9866 21.4085 20.9196 21.4085C23.1429 21.4085 24.5826 20.4107 25.279 18.2746H23.0223C22.7746 19.0714 21.7768 19.4866 21 19.4866C19.5 19.4866 18.7165 18.6094 18.7165 17.1228H25.4263C25.433 16.9688 25.4464 16.8013 25.4464 16.6406Z" className={`${showWhite ? "fill-[#282828]" : "fill-white"}`} />
                        </svg>

                    </div>


                    <div className="md:block">
                        <div>
                            <Link to="/login" className="rounded-full inline-block py-2 px-3 border border-neutral">
                                <div className="flex items-center gap-1">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M9 1.5C7.035 1.5 5.4375 3.0975 5.4375 5.0625C5.4375 6.99 6.945 8.55 8.91 8.6175C8.97 8.61 9.03 8.61 9.075 8.6175C9.09 8.6175 9.0975 8.6175 9.1125 8.6175C9.12 8.6175 9.12 8.6175 9.1275 8.6175C11.0475 8.55 12.555 6.99 12.5625 5.0625C12.5625 3.0975 10.965 1.5 9 1.5Z" fill="#282828" />
                                        <path d="M12.81 10.6125C10.7175 9.2175 7.30497 9.2175 5.19747 10.6125C4.24497 11.25 3.71997 12.1125 3.71997 13.035C3.71997 13.9575 4.24497 14.8125 5.18997 15.4425C6.23997 16.1475 7.61997 16.5 8.99997 16.5C10.38 16.5 11.76 16.1475 12.81 15.4425C13.755 14.805 14.28 13.95 14.28 13.02C14.2725 12.0975 13.755 11.2425 12.81 10.6125Z" fill="#282828" />
                                    </svg>
                                    <div>Log in</div>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </nav>
    )
}

export default Navbar;