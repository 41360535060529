import { createBrowserRouter, RouterProvider } from "react-router-dom"
import UnauthApp, { AuthApp, loadingBarRef } from "../App"
import Home from "../Pages/Home"
import Terms from "../Pages/Terms"
import UserDashboard from "../Pages/User/Dashboard"
import LoanAndCredit from "../Pages/User/LoanandCredit"
import Login from "../Pages/User/Login"
import UserPaymentHistory from "../Pages/User/PaymentHistory"
import Services from "../Pages/User/Services"
import Signup from "../Pages/User/Signup"
import VirtualCards from "../Pages/User/VirtualCard"
import UserWallets from "../Pages/User/Wallets"
import SignUpPage from "../Pages/Auth/Signup"
import { ToastContainer } from "react-toastify"
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar"
import LoginPage from "../Pages/Auth/Login"
import AccountSettings from "../Pages/User/Account"
import HelpCenter from "../Pages/User/HelpCenter"
import PrivacyPolicy from "../Pages/Privacy"


const router = createBrowserRouter([
    {
        path: "",
        element: <UnauthApp />,
        errorElement: <></>,
        children: [
            {
                path: "/",
                index: true,
                element: <Home />,
            },
            {
                path: "/signup",
                element: <Home />
            },
            {
                path: "/terms-of-service",
                element: <Terms />
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy />
            },
            {
                path: "/login",
                element: <Home />

            }
        ],
    },
    {
        path: "",
        element: <AuthApp />,
        errorElement: <>Errors</>,
        children: [
            {
                path: "/dashboard",
                index: true,
                element: <UserDashboard />
            },
            {
                path: "/your-wallets",
                element: <UserWallets />
            },
            {
                path: "/services",
                element: <Services />,
            },
            {
                path: "/loan-and-credit",
                element: <LoanAndCredit />
            },
            {
                path: "/virtual-card",
                element: <VirtualCards />
            },
            {
                path: "/account-settings",
                element: <AccountSettings />
            },
            {
                path: "/help-center",
                element: <HelpCenter />
            },
        ]
    }
])

export const AppRoutes = () => {
    return (
        <>
            <LoadingBar color='#004F32' ref={loadingBarRef as React.RefObject<LoadingBarRef>} height={5} />
            <ToastContainer position='top-right' hideProgressBar />
            <RouterProvider
                router={router}
            />
        </>

    )
}