import { Link, useNavigate } from "react-router-dom"
import { Modal } from "../../../Components/Modal"
import { PasswordInput } from "../../../Components/PasswordInput";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { verifyUser, resendUserOTP, register, login } from "../../../Api/Auth";
import { APICall, getImage } from "../../../Utils";
import { authLogin, reset, setUserType } from "../../../Redux/Auth";
import { useAppDispatch } from "../../../Hooks";
import { resetBankState } from "../../../Redux/Banks";
import { resetBeneficiaryState } from "../../../Redux/Beneficiary";
import { resetWalletState, setWallets, updateWalletsAsync } from "../../../Redux/Wallets";
import { resetVirtualAccountState } from "../../../Redux/VirtualWallet";
import { getAllWallets } from "../../../Api/General";
import { useState } from "react";


const loginValidationSchema = Yup.object().shape({
    phone: (Yup.string() as any)?.phone("Phone number is invalid!").required("Phone number is required!"),
    password: Yup.string()
        .required('Password is required'),
});

const LoginPage: React.FC<{
    open: boolean;
    onClose: (val: boolean) => void;
}> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleGetWallets = async () => {
        try {
            const response = await getAllWallets();
            return response;

        } catch (err) {

        }
    }
    const handleLogin = async (values: any, setSubmitting: (val: boolean) => void) => {
        try {
            dispatch(reset());
            dispatch(resetBankState());
            dispatch(resetBeneficiaryState());
            dispatch(resetWalletState());
            dispatch(resetVirtualAccountState());

            setSubmitting(true);
            const response = await APICall(login, values);
            dispatch(authLogin({ token: response.data.data.token, user: response.data.data.user }));
            dispatch(setUserType("user"));
            try {
                const _response = await handleGetWallets() as any;
                dispatch(setWallets(_response.data.data));
            }
            catch (err) {
            }
            navigate("/dashboard")
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
        }
    }

    return (
        <>
            
            <Modal open={open} onClose={onClose} size="sm">
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={loginValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log("submitting")
                        handleLogin(values, setSubmitting)
                    }}
                >
                    {({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => (
                        <Form>
                            <div className="px-8 py-5">
                                <div>
                                    <div className="text-2xl text-left font-semibold text-black">
                                        Login into an account
                                    </div>
                                </div>
                                <div className="text-sm text-left">
                                    Begin your cross border payments with Paywithfish.
                                </div>

                                <div className="mt-6">
                                    <div className="space-y-6">

                                        <div className="flex-1 relative">
                                            <div className="relative">
                                                <div className="absolute h-full flex items-center px-3">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.4" d="M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z" fill="#292D32" />
                                                        <path d="M9.00008 9.6525C8.37008 9.6525 7.73258 9.4575 7.24508 9.06L4.89758 7.185C4.65758 6.99 4.61258 6.6375 4.80758 6.3975C5.00258 6.1575 5.35508 6.1125 5.59508 6.3075L7.94257 8.1825C8.51257 8.64 9.48007 8.64 10.0501 8.1825L12.3976 6.3075C12.6376 6.1125 12.9976 6.15 13.1851 6.3975C13.3801 6.6375 13.3426 6.9975 13.0951 7.185L10.7476 9.06C10.2676 9.4575 9.63008 9.6525 9.00008 9.6525Z" fill="#292D32" />
                                                    </svg>

                                                </div>
                                                <Field type="text" name="phone" placeholder="Phone" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                            </div>
                                            <ErrorMessage name="phone" component={"div"} className="text-red-600 text-sm text-left" />

                                        </div>
                                        <div className="flex-1 relative">
                                            <PasswordInput placeholder="Enter password" name="password"
                                                value={values.password}
                                                onChange={(e: any) => handleChange(e)}
                                                onBlur={handleBlur} />
                                            <ErrorMessage name="password" component={"div"} className="text-red-600 text-sm text-left" />

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6">

                                </div>
                                <div className="mt-6">
                                    <button type="submit" className="text-white rounded-lg py-2 bg-[#282828] w-full text-center">
                                        {isSubmitting ? <svg className="w-5 h-5 inline" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                            <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                <animateTransform
                                                    attributeName="transform"
                                                    attributeType="XML"
                                                    type="rotate"
                                                    dur="1s"
                                                    from="0 50 50"
                                                    to="360 50 50"
                                                    repeatCount="indefinite" />
                                            </path>
                                        </svg> : "Log in"
                                        }
                                    </button>
                                    <div className="mt-6">
                                        <p className="text-center">
                                            Don't have an account, <Link to="/signup" className="text-blue-600">Sign up</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Form>)}
                </Formik>
            </Modal>
        </ >
    )
}
export default LoginPage