import { Link } from "react-router-dom"

export const FooterDark = () => {
    return (
        <div className="w-full pt-12 pb-6 px-6 md:px-12 mt-28 bg-[#EFF0F3] text-white">
            <div>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4888 17.4777C12.4888 18.7701 11.5513 19.1786 10.3996 19.1786H7.37946V15.6362H10.4598C11.7054 15.6429 12.4888 16.1585 12.4888 17.4777ZM11.9732 11.9665C11.9732 10.7812 11.0558 10.5 10.0379 10.5H7.38616V13.5H10.2455C11.2567 13.5 11.9732 13.058 11.9732 11.9665ZM20.8326 13.5201C19.6071 13.5201 18.7902 14.2835 18.7098 15.5089H22.875C22.7612 14.2701 22.1183 13.5201 20.8326 13.5201ZM30 3.21429V26.7857C30 28.5603 28.5603 30 26.7857 30H3.21429C1.43973 30 0 28.5603 0 26.7857V3.21429C0 1.43973 1.43973 0 3.21429 0H26.7857C28.5603 0 30 1.43973 30 3.21429ZM18.1942 10.2455H23.404V8.97991H18.1942V10.2455ZM15.3147 17.6317C15.3147 16.0179 14.5513 14.625 12.971 14.1763C14.1228 13.6272 14.7254 12.9911 14.7254 11.6987C14.7254 9.14062 12.817 8.51786 10.6138 8.51786H4.55357V21.375H10.7879C13.125 21.3616 15.3147 20.2433 15.3147 17.6317ZM25.4464 16.6406C25.4464 13.8884 23.8326 11.5915 20.9196 11.5915C18.0804 11.5915 16.1585 13.721 16.1585 16.5201C16.1585 19.4196 17.9866 21.4085 20.9196 21.4085C23.1429 21.4085 24.5826 20.4107 25.279 18.2746H23.0223C22.7746 19.0714 21.7768 19.4866 21 19.4866C19.5 19.4866 18.7165 18.6094 18.7165 17.1228H25.4263C25.433 16.9688 25.4464 16.8013 25.4464 16.6406Z" fill="#068FB0" />
                </svg>
            </div>
            <div className="flex justify-between md:flex-row flex-col">
                <div className="md:w-6/12">
                    <div className="text-black text-4xl font-bold mt-2">
                        Created a free account to start spending globally.
                    </div>
                    <div className="mt-6">
                        <Link to="/signup" className="inline-block text-white font-bold bg-gradient-to-r from-[#ED2DF0] to-[#5A4F5A] px-12 py-3 rounded-2xl border border-[#D8D8D8]">
                            Get started now
                        </Link>
                    </div>
                </div>
                <div className="md:w-5/12 md:mt-0 mt-6">
                    <div className="flex gap-4 text-black">
                        <div className="bg-white px-6 py-2 rounded-2xl font-medium">
                            FAQs
                        </div>
                        <Link to="/privacy-policy" className="bg-white px-6 py-2 rounded-2xl font-medium">
                            Privacy Policy
                        </Link>
                        <Link to="/terms-of-service" className="block bg-white px-6 py-2 rounded-2xl font-medium">
                            Terms of service
                        </Link>
                    </div>
                    <div className="mt-8 text-black">
                        <div className="space-y-4">
                            <div className="flex gap-2">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z" fill="#292D32" />
                                    <path d="M9 9.65247C8.37 9.65247 7.7325 9.45748 7.245 9.05998L4.8975 7.18498C4.6575 6.98998 4.6125 6.63748 4.8075 6.39748C5.0025 6.15748 5.35501 6.11248 5.59501 6.30748L7.9425 8.18248C8.5125 8.63998 9.48 8.63998 10.05 8.18248L12.3975 6.30748C12.6375 6.11248 12.9975 6.14998 13.185 6.39748C13.38 6.63748 13.3425 6.99748 13.095 7.18498L10.7475 9.05998C10.2675 9.45748 9.63 9.65247 9 9.65247Z" fill="#292D32" />
                                </svg>
                                Hello@paywithfish.xyz
                            </div>
                            <div className="flex gap-2">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M8.8425 10.6575L6.39 13.11C6.12 12.87 5.8575 12.6225 5.6025 12.3675C4.83 11.5875 4.1325 10.77 3.51 9.915C2.895 9.06 2.4 8.205 2.04 7.3575C1.68 6.5025 1.5 5.685 1.5 4.905C1.5 4.395 1.59 3.9075 1.77 3.4575C1.95 3 2.235 2.58 2.6325 2.205C3.1125 1.7325 3.6375 1.5 4.1925 1.5C4.4025 1.5 4.6125 1.545 4.8 1.635C4.995 1.725 5.1675 1.86 5.3025 2.055L7.0425 4.5075C7.1775 4.695 7.275 4.8675 7.3425 5.0325C7.41 5.19 7.4475 5.3475 7.4475 5.49C7.4475 5.67 7.395 5.85 7.29 6.0225C7.1925 6.195 7.05 6.375 6.87 6.555L6.3 7.1475C6.2175 7.23 6.18 7.3275 6.18 7.4475C6.18 7.5075 6.1875 7.56 6.2025 7.62C6.225 7.68 6.2475 7.725 6.2625 7.77C6.3975 8.0175 6.63 8.34 6.96 8.73C7.2975 9.12 7.6575 9.5175 8.0475 9.915C8.3175 10.1775 8.58 10.4325 8.8425 10.6575Z" fill="black" />
                                    <path d="M16.4775 13.7475C16.4775 13.9575 16.44 14.175 16.365 14.385C16.3425 14.445 16.32 14.505 16.29 14.565C16.1625 14.835 15.9975 15.09 15.78 15.33C15.4125 15.735 15.0075 16.0275 14.55 16.215C14.5425 16.215 14.535 16.2225 14.5275 16.2225C14.085 16.4025 13.605 16.5 13.0875 16.5C12.3225 16.5 11.505 16.32 10.6425 15.9525C9.78 15.585 8.9175 15.09 8.0625 14.4675C7.77 14.25 7.4775 14.0325 7.2 13.8L9.6525 11.3475C9.8625 11.505 10.05 11.625 10.2075 11.7075C10.245 11.7225 10.29 11.745 10.3425 11.7675C10.4025 11.79 10.4625 11.7975 10.53 11.7975C10.6575 11.7975 10.755 11.7525 10.8375 11.67L11.4075 11.1075C11.595 10.92 11.775 10.7775 11.9475 10.6875C12.12 10.5825 12.2925 10.53 12.48 10.53C12.6225 10.53 12.7725 10.56 12.9375 10.6275C13.1025 10.695 13.275 10.7925 13.4625 10.92L15.945 12.6825C16.14 12.8175 16.275 12.975 16.3575 13.1625C16.4325 13.35 16.4775 13.5375 16.4775 13.7475Z" fill="black" />
                                </svg>

                                080123456789
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-3 mt-4 items-center">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5312 0H2.34375C1.72215 0 1.12601 0.24693 0.686468 0.686469C0.24693 1.12601 0 1.72215 0 2.34375L0 19.5312C0 20.1529 0.24693 20.749 0.686468 21.1885C1.12601 21.6281 1.72215 21.875 2.34375 21.875H9.04541V14.438H5.96924V10.9375H9.04541V8.26953C9.04541 5.23486 10.8521 3.55859 13.6191 3.55859C14.9443 3.55859 16.3301 3.79492 16.3301 3.79492V6.77344H14.8032C13.2988 6.77344 12.8296 7.70703 12.8296 8.66455V10.9375H16.188L15.6509 14.438H12.8296V21.875H19.5312C20.1529 21.875 20.749 21.6281 21.1885 21.1885C21.6281 20.749 21.875 20.1529 21.875 19.5312V2.34375C21.875 1.72215 21.6281 1.12601 21.1885 0.686469C20.749 0.24693 20.1529 0 19.5312 0Z" fill="#292D32" />
                        </svg>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.946 5.33081C7.84058 5.33081 5.33569 7.83569 5.33569 10.9412C5.33569 14.0466 7.84058 16.5515 10.946 16.5515C14.0515 16.5515 16.5564 14.0466 16.5564 10.9412C16.5564 7.83569 14.0515 5.33081 10.946 5.33081ZM10.946 14.5886C8.93921 14.5886 7.29858 12.9529 7.29858 10.9412C7.29858 8.92944 8.93433 7.2937 10.946 7.2937C12.9578 7.2937 14.5935 8.92944 14.5935 10.9412C14.5935 12.9529 12.9529 14.5886 10.946 14.5886ZM18.0945 5.10132C18.0945 5.82886 17.5085 6.40991 16.7859 6.40991C16.0584 6.40991 15.4773 5.82397 15.4773 5.10132C15.4773 4.37866 16.0632 3.79272 16.7859 3.79272C17.5085 3.79272 18.0945 4.37866 18.0945 5.10132ZM21.8103 6.42944C21.7273 4.67651 21.3269 3.12378 20.0427 1.84448C18.7634 0.565185 17.2107 0.164795 15.4578 0.0769043C13.6511 -0.0256348 8.23608 -0.0256348 6.42944 0.0769043C4.6814 0.159912 3.12866 0.560303 1.84448 1.8396C0.560303 3.1189 0.164795 4.67163 0.0769043 6.42456C-0.0256348 8.2312 -0.0256348 13.6462 0.0769043 15.4529C0.159912 17.2058 0.560303 18.7585 1.84448 20.0378C3.12866 21.3171 4.67651 21.7175 6.42944 21.8054C8.23608 21.908 13.6511 21.908 15.4578 21.8054C17.2107 21.7224 18.7634 21.322 20.0427 20.0378C21.322 18.7585 21.7224 17.2058 21.8103 15.4529C21.9128 13.6462 21.9128 8.23608 21.8103 6.42944ZM19.4763 17.3914C19.0955 18.3484 18.3582 19.0857 17.3962 19.4714C15.9558 20.0427 12.5378 19.9109 10.946 19.9109C9.35425 19.9109 5.9314 20.0378 4.49585 19.4714C3.53882 19.0906 2.80151 18.3533 2.41577 17.3914C1.84448 15.9509 1.97632 12.533 1.97632 10.9412C1.97632 9.34937 1.84937 5.92651 2.41577 4.49097C2.79663 3.53394 3.53394 2.79663 4.49585 2.41089C5.93628 1.8396 9.35425 1.97144 10.946 1.97144C12.5378 1.97144 15.9607 1.84448 17.3962 2.41089C18.3533 2.79175 19.0906 3.52905 19.4763 4.49097C20.0476 5.9314 19.9158 9.34937 19.9158 10.9412C19.9158 12.533 20.0476 15.9558 19.4763 17.3914Z" fill="#292D32" />
                        </svg>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.3125 0H1.55762C0.698242 0 0 0.708008 0 1.57715V20.2979C0 21.167 0.698242 21.875 1.55762 21.875H20.3125C21.1719 21.875 21.875 21.167 21.875 20.2979V1.57715C21.875 0.708008 21.1719 0 20.3125 0ZM6.61133 18.75H3.36914V8.31055H6.61621V18.75H6.61133ZM4.99023 6.88477C3.9502 6.88477 3.11035 6.04004 3.11035 5.00488C3.11035 3.96973 3.9502 3.125 4.99023 3.125C6.02539 3.125 6.87012 3.96973 6.87012 5.00488C6.87012 6.04492 6.03027 6.88477 4.99023 6.88477ZM18.7646 18.75H15.5225V13.6719C15.5225 12.4609 15.498 10.9033 13.8379 10.9033C12.1484 10.9033 11.8896 12.2217 11.8896 13.584V18.75H8.64746V8.31055H11.7578V9.73633H11.8018C12.2363 8.91602 13.2959 8.05176 14.873 8.05176C18.1543 8.05176 18.7646 10.2148 18.7646 13.0273V18.75Z" fill="#292D32" />
                        </svg>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.12109 8.30566L13.7695 10.9473L9.12109 13.5889V8.30566ZM21.875 2.34375V19.5312C21.875 20.8252 20.8252 21.875 19.5312 21.875H2.34375C1.0498 21.875 0 20.8252 0 19.5312V2.34375C0 1.0498 1.0498 0 2.34375 0H19.5312C20.8252 0 21.875 1.0498 21.875 2.34375ZM19.8242 10.9521C19.8242 10.9521 19.8242 8.04199 19.4531 6.64551C19.248 5.87402 18.6475 5.26855 17.8809 5.06348C16.499 4.6875 10.9375 4.6875 10.9375 4.6875C10.9375 4.6875 5.37598 4.6875 3.99414 5.06348C3.22754 5.26855 2.62695 5.87402 2.42188 6.64551C2.05078 8.03711 2.05078 10.9521 2.05078 10.9521C2.05078 10.9521 2.05078 13.8623 2.42188 15.2588C2.62695 16.0303 3.22754 16.6113 3.99414 16.8164C5.37598 17.1875 10.9375 17.1875 10.9375 17.1875C10.9375 17.1875 16.499 17.1875 17.8809 16.8115C18.6475 16.6064 19.248 16.0254 19.4531 15.2539C19.8242 13.8623 19.8242 10.9521 19.8242 10.9521Z" fill="#292D32" />
                        </svg>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5312 0H2.34375C1.0498 0 0 1.0498 0 2.34375V19.5312C0 20.8252 1.0498 21.875 2.34375 21.875H19.5312C20.8252 21.875 21.875 20.8252 21.875 19.5312V2.34375C21.875 1.0498 20.8252 0 19.5312 0ZM17.1436 7.75391C17.1533 7.89062 17.1533 8.03223 17.1533 8.16895C17.1533 12.4023 13.9307 17.2803 8.04199 17.2803C6.22559 17.2803 4.54102 16.7529 3.125 15.8447C3.38379 15.874 3.63281 15.8838 3.89648 15.8838C5.39551 15.8838 6.77246 15.376 7.87109 14.5166C6.46484 14.4873 5.2832 13.5645 4.87793 12.2949C5.37109 12.3682 5.81543 12.3682 6.32324 12.2363C4.8584 11.9385 3.75977 10.6494 3.75977 9.0918V9.05273C4.18457 9.29199 4.68262 9.43848 5.20508 9.45801C4.76583 9.16584 4.40575 8.76947 4.15697 8.30427C3.90818 7.83908 3.77843 7.31953 3.7793 6.79199C3.7793 6.19629 3.93555 5.64941 4.21387 5.17578C5.79102 7.11914 8.15918 8.38867 10.8154 8.52539C10.3613 6.35254 11.9873 4.58984 13.9404 4.58984C14.8633 4.58984 15.6934 4.97559 16.2793 5.60059C17.002 5.46387 17.6953 5.19531 18.3105 4.8291C18.0713 5.57129 17.5684 6.19629 16.9043 6.5918C17.5488 6.52344 18.1738 6.34277 18.75 6.09375C18.3154 6.7334 17.7686 7.2998 17.1436 7.75391Z" fill="#292D32" />
                        </svg>

                    </div>
                </div>
            </div>
            <div className="mt-8 text-center text-[#282828]">
                Copyright © Paywithfish. 2023. Paywithfish is a financial technology company, not a bank. Banking services are provided by licensed banking partners.
            </div>
        </div>
    )
}