import { useEffect, useState } from "react";
import { useStateManager } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { PasswordInput } from "../../../Components/PasswordInput";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { APICall } from "../../../Utils";
import { changePassword, changePin } from "../../../Api/Auth";
import { BeneficiariesModel } from "../../../Models/Beneficiaries";
import { deleteBeneficiary } from "../../../Api/General";
import { updateBeneficiariesAsync } from "../../../Redux/Beneficiary";
import { CreateBeneficiaryModal, DeleteModal } from "../Components";
import moment from "moment";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;



const passwordSchema = Yup.object().shape({
    current_password: Yup.string()
        .required("Current password is required"),
    password: Yup.string()
        .matches(passwordRules, { message: "Please create a stronger password!" })
        .required("Password is required"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match!')
        .required("Confirm password is required!")

});

const pinSchema = Yup.object().shape({
    current_pin: Yup.string().min(4, "Pin must be 4 digits").max(4, "Pin must be 4 digits")
        .required("Current pin is required"),
    pin: Yup.string().min(4, "Pin must be 4 digits").max(4, "Pin must be 4 digits")
        .required("Pin is required"),
    pin_confirmation: Yup.string().min(4, "Pin must be 4 digits").max(4, "Pin must be 4 digits")
        .oneOf([Yup.ref('pin')], 'Pins must match!')
        .required("Confirm pin is required!")

});


const AccountSettings = () => {
    const [activeTab, setActiveTab] = useState(1);
    const user = useAppSelector(state => state.auth.user);
    const [passwordReset, setPasswordReset] = useState({ current_password: "", password: "", password_confirmation: "" });

    const beneficiaries = useAppSelector(state => state.beneficiaries.beneficairies);
    const [selectedBeneficiary, setSelectedBeneficairy] = useState<BeneficiariesModel | null>(null)
    const [showCreateBeneficiary, setShowCreateBeneficiary] = useState(false);
    const [showDeleteBeneficiary, setShowDeleteBeneficiary] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const handleChangePassword = async (values: any, setSubmitting: (val: boolean) => void) => {
        try {
            try {
                setSubmitting(true);
                const response = await APICall(changePassword, values, true);
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
            }
        } catch (error) {

        }
    }

    const handleChangePin = async (values: any, setSubmitting: (val: boolean) => void) => {
        try {
            try {
                setSubmitting(true);
                const response = await APICall(changePin, values, true);
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
            }
        } catch (error) {

        }
    }

    const handleGetBeneficiaries = async () => {
        try {
            dispatch(updateBeneficiariesAsync());
        } catch (error) {

        }
    }

    const handleUpdateBeneficiary = async () => {
        try {

        } catch (error) {

        }
    }

    const handleDeleteBeneficiary = async () => {
        try {
            if (!selectedBeneficiary || loading) return;
            const response = await APICall(deleteBeneficiary, selectedBeneficiary.id, true)
            setShowDeleteBeneficiary(false);
            handleGetBeneficiaries();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleGetBeneficiaries();
    }, [showCreateBeneficiary])

    return (
        <div id="dashboard">
            <CreateBeneficiaryModal beneficiary={selectedBeneficiary as any} open={showCreateBeneficiary} onClose={() => { setSelectedBeneficairy(null); setShowCreateBeneficiary(false) }} />
            <DeleteModal open={showDeleteBeneficiary} onClose={() => { setSelectedBeneficairy(null); setShowDeleteBeneficiary(false) }} message="Delete Beneficiary" onConfirm={handleDeleteBeneficiary} />

            <div className="mt-8">
                <div className="font-semibold text-2xl">
                    Manage your account
                </div>
                <div className="mt-4">
                    <div className="flex gap-8">
                        <div onClick={() => setActiveTab(1)} className={`text-sm pb-2 cursor-pointer ${activeTab == 1 ? "text-[#068FB0] border-b-2 border-[#068FB0]" : "text-[#787878]"}`}>
                            Personal profile
                        </div>
                        <div onClick={() => setActiveTab(2)} className={`text-sm pb-2 cursor-pointer ${activeTab == 2 ? "text-[#068FB0] border-b-2 border-[#068FB0]" : "text-[#787878]"}`}>
                            Security settings
                        </div>
                        <div onClick={() => setActiveTab(3)} className={`text-sm pb-2 cursor-pointer ${activeTab == 3 ? "text-[#068FB0] border-b-2 border-[#068FB0]" : "text-[#787878]"}`}>
                            Manage beneficairies
                        </div>
                    </div>
                </div>
                <div className="mt-8">
                    <div className="border rounded-lg bg-white p-6  drop-shadow-[0px_0px_3px_rgba(0,0,0,0.25)]">
                        {
                            (() => {
                                switch (activeTab) {
                                    case 1:
                                        return (
                                            <div>
                                                <div className="font-semibold text-2xl">
                                                    Personal information
                                                </div>

                                                <div className="mt-4 flex md:flex-row flex-col-reverse">
                                                    <div className="md:pr-12 md:w-7/12 md:mt-0 mt-6 md:border-r pb-12">
                                                        <div className="space-y-4">
                                                            <div className="flex gap-8">
                                                                <div>
                                                                    <div>
                                                                        First name
                                                                    </div>
                                                                    <input value={user?.first_name} type="text" name="first_name" placeholder="First name" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        Last name
                                                                    </div>
                                                                    <input value={user?.last_name} type="text" name="last_name" placeholder="Last name" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-8">
                                                                <div>
                                                                    <div>
                                                                        Email address
                                                                    </div>
                                                                    <input value={user?.email} type="text" name="email" placeholder="Email address" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        Phone number
                                                                    </div>
                                                                    <input value={user?.phone} type="text" name="phone" placeholder="Phone" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-8">
                                                                <div>
                                                                    <div>
                                                                        BVN
                                                                    </div>
                                                                    <input value={""} readOnly type="text" name="bvn" placeholder="BVN" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        Date of birth
                                                                    </div>
                                                                    <input value={user?.date_of_birth} readOnly type="text" name="date_of_birth" placeholder="Date of birth" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-8">
                                                                <div>
                                                                    <div>
                                                                        Address
                                                                    </div>
                                                                    <input value={""} type="text" name="address" placeholder="Address" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        Country
                                                                    </div>
                                                                    <input value={"Nigeria"} readOnly type="text" name="country" placeholder="Country" className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <div className="mt-16">
                                                            <div className="text-center">
                                                                {
                                                                    user?.image ? <img src={"data:image/png;base64," + user.image} alt="" className="w-[130px] shadow-lg rounded-full inline" /> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-[130px] h-[130px] inline fill-slate-300 drop-shadow-[0px_3px_2px_rgba(0,0,0,0.25)]" viewBox="0 0 16 16">
                                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                                        </svg>
                                                                }
                                                            </div>
                                                            <div className="text-center mt-8">
                                                                <button className="text-sm text-[#ED2DF0] font-medium px-2 bg-[#FFD8FF] rounded-xl py-[0.18rem]">Change profile picture</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                    case 2:
                                        return (
                                            <>
                                                <div>
                                                    <div className="font-semibold text-2xl">
                                                        Change your password
                                                    </div>

                                                    <div className="mt-4 ">
                                                        <div className="md:w-10/12">
                                                            <Formik
                                                                initialValues={{
                                                                    current_password: "",
                                                                    password: "",
                                                                    password_confirmation: ""
                                                                }}
                                                                validationSchema={passwordSchema}
                                                                onSubmit={(values, { setSubmitting }) => {

                                                                    handleChangePassword(values, setSubmitting)
                                                                }}
                                                            >
                                                                {({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => (
                                                                    <Form>
                                                                        <div className="">
                                                                            <div className="flex gap-4 md:gap-8 md:flex-row flex-col">
                                                                                <div className="flex-1">
                                                                                    <div>
                                                                                        Enter current password
                                                                                    </div>
                                                                                    <PasswordInput
                                                                                        name="current_password"
                                                                                        value={values.current_password}
                                                                                        onChange={(e: any) => handleChange(e)}
                                                                                        onBlur={handleBlur} />
                                                                                    <ErrorMessage name="current_password" component={"div"} className="text-red-600 text-sm text-left" />

                                                                                </div>
                                                                                <div className="flex-1">
                                                                                    <div>
                                                                                        Enter new password
                                                                                    </div>
                                                                                    <PasswordInput
                                                                                        name="password"
                                                                                        value={values.password}
                                                                                        onChange={(e: any) => handleChange(e)}
                                                                                        onBlur={handleBlur} />
                                                                                    <ErrorMessage name="password" component={"div"} className="text-red-600 text-sm text-left" />
                                                                                </div>
                                                                                <div className="flex-1">
                                                                                    <div>
                                                                                        Confirm new password
                                                                                    </div>
                                                                                    <PasswordInput
                                                                                        name="password_confirmation"
                                                                                        value={values.password_confirmation}
                                                                                        onChange={(e: any) => handleChange(e)}
                                                                                        onBlur={handleBlur} />
                                                                                    <ErrorMessage name="password_confirmation" component={"div"} className="text-red-600 text-sm text-left" />
                                                                                </div>
                                                                            </div>

                                                                            <div className="mt-8">
                                                                                <button type="submit" className="text-sm py-3 px-6 rounded-lg text-white bg-[#068FB0] disabled:bg-opacity-50">
                                                                                    {isSubmitting ? <svg className="w-5 h-5" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                                                                        <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                                            <animateTransform
                                                                                                attributeName="transform"
                                                                                                attributeType="XML"
                                                                                                type="rotate"
                                                                                                dur="1s"
                                                                                                from="0 50 50"
                                                                                                to="360 50 50"
                                                                                                repeatCount="indefinite" />
                                                                                        </path>
                                                                                    </svg> : "Save changes"}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Form>)}
                                                            </Formik>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="mt-12">
                                                    <div className="font-semibold text-2xl">
                                                        Change your pin
                                                    </div>

                                                    <div className="mt-4 ">
                                                        <div className="md:w-10/12">
                                                            <Formik
                                                                initialValues={{
                                                                    current_pin: "",
                                                                    pin: "",
                                                                    pin_confirmation: ""
                                                                }}
                                                                validationSchema={pinSchema}
                                                                onSubmit={(values, { setSubmitting }) => {

                                                                    handleChangePin(values, setSubmitting)
                                                                }}
                                                            >
                                                                {({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => (
                                                                    <Form>
                                                                        <div className="">
                                                                            <div className="flex gap-4 md:gap-8 md:flex-row flex-col">
                                                                                <div className="flex-1">
                                                                                    <div>
                                                                                        Enter current pin
                                                                                    </div>
                                                                                    <PasswordInput
                                                                                        name="current_pin"
                                                                                        value={values.current_pin}
                                                                                        onChange={(e: any) => handleChange(e)}
                                                                                        onBlur={handleBlur} />
                                                                                    <ErrorMessage name="current_pin" component={"div"} className="text-red-600 text-sm text-left" />

                                                                                </div>
                                                                                <div className="flex-1">
                                                                                    <div>
                                                                                        Enter new pin
                                                                                    </div>
                                                                                    <PasswordInput
                                                                                        name="pin"
                                                                                        value={values.pin}
                                                                                        onChange={(e: any) => handleChange(e)}
                                                                                        onBlur={handleBlur} />
                                                                                    <ErrorMessage name="pin" component={"div"} className="text-red-600 text-sm text-left" />
                                                                                </div>
                                                                                <div className="flex-1">
                                                                                    <div>
                                                                                        Confirm new pin
                                                                                    </div>
                                                                                    <PasswordInput
                                                                                        name="pin_confirmation"
                                                                                        value={values.pin_confirmation}
                                                                                        onChange={(e: any) => handleChange(e)}
                                                                                        onBlur={handleBlur} />
                                                                                    <ErrorMessage name="pin_confirmation" component={"div"} className="text-red-600 text-sm text-left" />
                                                                                </div>
                                                                            </div>

                                                                            <div className="mt-8">
                                                                                <button type="submit" className="text-sm py-3 px-6 rounded-lg text-white bg-[#068FB0] disabled:bg-opacity-50">
                                                                                    {isSubmitting ? <svg className="w-5 h-5" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                                                                        <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                                            <animateTransform
                                                                                                attributeName="transform"
                                                                                                attributeType="XML"
                                                                                                type="rotate"
                                                                                                dur="1s"
                                                                                                from="0 50 50"
                                                                                                to="360 50 50"
                                                                                                repeatCount="indefinite" />
                                                                                        </path>
                                                                                    </svg> : "Save changes"}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Form>)}
                                                            </Formik>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>);
                                    case 3:
                                        return (
                                            <>
                                                <div>
                                                    <div className="flex justify-between">
                                                        <div className="font-semibold ">
                                                            Beneficiaries
                                                        </div>
                                                        <div className="font-semibold ">
                                                            <button onClick={() => setShowCreateBeneficiary(true)} className="flex gap-1 items-center text-[#188B4E]">
                                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.875 4.625H6.375V1.125C6.375 0.892936 6.28281 0.670376 6.11872 0.506282C5.95462 0.342187 5.73206 0.25 5.5 0.25C5.26794 0.25 5.04538 0.342187 4.88128 0.506282C4.71719 0.670376 4.625 0.892936 4.625 1.125V4.625H1.125C0.892936 4.625 0.670376 4.71719 0.506282 4.88128C0.342187 5.04538 0.25 5.26794 0.25 5.5C0.25 5.73206 0.342187 5.95462 0.506282 6.11872C0.670376 6.28281 0.892936 6.375 1.125 6.375H4.625V9.875C4.625 10.1071 4.71719 10.3296 4.88128 10.4937C5.04538 10.6578 5.26794 10.75 5.5 10.75C5.73206 10.75 5.95462 10.6578 6.11872 10.4937C6.28281 10.3296 6.375 10.1071 6.375 9.875V6.375H9.875C10.1071 6.375 10.3296 6.28281 10.4937 6.11872C10.6578 5.95462 10.75 5.73206 10.75 5.5C10.75 5.26794 10.6578 5.04538 10.4937 4.88128C10.3296 4.71719 10.1071 4.625 9.875 4.625Z" fill="#188B4E" />
                                                                </svg>
                                                                <span>
                                                                    Add new beneficairy
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overflow-x-auto mt-8 md:h-auto h-96">
                                                    <table className="border-collapse table-auto w-full whitespace-nowrap">
                                                        <thead className="bg-[#282828] text-base  text-white text-left border-none">
                                                            <tr>
                                                                <th className="py-3 pl-6 font-semibold text-sm">Customer name</th>
                                                                <th className="py-3 pl-6 font-semibold text-sm">Date created</th>
                                                                <th className="py-3 pl-6 font-semibold text-sm">Account no</th>
                                                                <th className="py-3 pl-6 font-semibold text-sm">Bank name</th>
                                                                <th className="py-3 pl-6 font-semibold text-sm">Currency</th>
                                                                <th className="py-3 pl-6 font-semibold text-sm">Account type</th>
                                                                <th className="py-3 pl-6 font-semibold text-sm">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="">
                                                            {
                                                                beneficiaries?.map((val) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className="py-3 pl-6 text-sm">
                                                                                {val.entity_type == "individual" ? val.first_name + " " + val.last_name : val.company_name}
                                                                            </td>
                                                                            <td className="py-3 pl-6 text-sm">
                                                                                {
                                                                                    moment(val.updated_at).format("DD/MM/YYYY")
                                                                                }
                                                                            </td>
                                                                            <td className="py-3 pl-6 text-sm">
                                                                                {
                                                                                    val.account_number
                                                                                }
                                                                            </td>
                                                                            <td className="py-3 pl-6 text-sm">
                                                                                {
                                                                                    val.bank_name
                                                                                }
                                                                            </td>
                                                                            <td className="py-3 pl-6 text-sm">
                                                                                {
                                                                                    val.currency
                                                                                }
                                                                            </td>
                                                                            <td className="py-3 pl-6 text-sm">
                                                                                {val.entity_type}
                                                                            </td>
                                                                            <td className="py-3 pl-6 text-sm">
                                                                                <div className="flex gap-1">
                                                                                    <button onClick={() => { setSelectedBeneficairy(val); setShowCreateBeneficiary(true) }} className="text-white py-1 px-2 rounded-lg bg-[#4896D1]">
                                                                                        Edit
                                                                                    </button>
                                                                                    <button onClick={() => { setSelectedBeneficairy(val); setShowDeleteBeneficiary(true) }} className="text-white py-1 px-2 rounded-lg bg-[#E33838]">
                                                                                        Delete
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>

                                        )
                                    default:
                                        break;
                                }
                            })()
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings;