import { getImage } from "../../../Utils"

const VirtualCards = () => {
    return (
        <div id="dashboard">
            <div className="mt-8">
                <div className="mt-12">
                    <div className="border rounded-lg bg-white p-6 md:w-11/12">
                        <div>
                            <div className="text-xl font-bold">
                                Virtual Card
                            </div>
                        </div>
                        <div className="mt-2 text-center">
                            <img src={getImage("virtual-card.png")} alt="" className="inline w-[418px]" />
                        </div>
                        <div className="text-neutral text-lg text-center md:w-6/12 mx-auto mb-24">
                            <div>
                                We make international payment easy and seamless. Pay for bills like Spotify, Starlink, Facebook Ad, WES, and many more.
                            </div>
                            <div className="text-center mt-4">
                                <span className="bg-[#FFD8FF] text-[#ED2DF0] font-medium ml-2 px-2.5 py-0.5 rounded-full text-sm">
                                    Coming soon!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VirtualCards