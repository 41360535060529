import { Tab } from '@headlessui/react'
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldAttributes } from 'formik';
import * as Yup from 'yup';
import { useRef, useState } from "react";

export const PasswordInput: React.FC<FieldAttributes<any>> = (props) => {
    const [visible, setVisible] = useState(false);
    const ref = useRef<any>();

    return (
        <div className="relative ">
            <div className="absolute h-full flex items-center px-3">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" fill="#292D32" />
                    <path d="M11.8126 8.0475V7.5C11.8126 6.8025 11.8126 4.6875 9.00009 4.6875C6.18759 4.6875 6.18759 6.8025 6.18759 7.5V8.0475C5.27259 8.25 4.96509 8.8425 4.96509 10.125V10.875C4.96509 12.525 5.47509 13.035 7.12509 13.035H10.8751C12.5251 13.035 13.0351 12.525 13.0351 10.875V10.125C13.0351 8.8425 12.7276 8.25 11.8126 8.0475ZM9.00009 11.325C8.54259 11.325 8.17511 10.9575 8.17511 10.5C8.17511 10.0425 8.54259 9.675 9.00009 9.675C9.45759 9.675 9.82507 10.0425 9.82507 10.5C9.82507 10.9575 9.45759 11.325 9.00009 11.325ZM10.6876 7.965H7.31259V7.5C7.31259 6.405 7.58259 5.8125 9.00009 5.8125C10.4176 5.8125 10.6876 6.405 10.6876 7.5V7.965Z" fill="#292D32" />
                </svg>
            </div>

            <input
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                type={!visible ? "password" : "text"} placeholder={props.placeholder} className="w-full border rounded-lg bg-[#F5F5F5] text-[#BEBEBE] border-[#BEBEBE] text-sm py-3 px-8" />
            <div className="flex h-full items-center px-4 absolute top-0 right-0">
                {
                    visible ? <svg onClick={() => setVisible(!visible)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 cursor-pointer">
                        <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                        <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                        <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                    </svg> :
                        <svg onClick={() => {
                            console.log(ref.current)
                            setVisible(!visible)
                        }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 cursor-pointer">
                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                            <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
                        </svg>
                }
            </div>
        </div>
    )
}

const loginValidationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required'),
    username: Yup.string().email('Invalid email').required('Email Required'),
});