import { useEffect } from "react";
import { getImage } from "../../Utils";
import { Tab } from '@headlessui/react'

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="mt-24 mb-24 md:mb-48 md:mt-32">
            <div className="container mx-auto px-6 md:px-12">
                <div className="text-left">
                    <div className="mt-12 md:text-lg">
                        <h1 className="font-semibold text-[#068FB0]">TERMS OF USE</h1>

                        <div className="mt-8"> 1. Consent to Website Terms</div>
                        <p className=" !leading-loose"> These Website Terms ("Terms") are entered into by and between you and Fish Global LTD (“PayWithFish,” "we," "us"). The Terms govern your access to and use of PayWithFish.com, including any content offered on or through the PayWithFish.com domains (the "Website"), regardless of your means of access and use (including web-based, mobile, and via a mobile application), whether as a guest or a registered user.</p>
                        <p className=" !leading-loose"> Please read these Terms carefully before you start to use the Website. By using the Website, you accept and agree to be bound and abide by these Terms. If you do not agree to these Terms, you do not have PayWithFish’s permission to access or use the Website.</p>

                        <div className="mt-8">2. Changes to Website Terms</div>
                        <p className=" !leading-loose"> We may revise and update these Terms at our sole discretion. All changes are effective immediately and apply to all access to and use of the Website after they are made. When we make changes, we will update the “last modified” date in the footer below these Terms to reflect the date upon which changes were made. Your continued use of the Website following the posting of revised Terms means that you accept and agree to the changes.</p>

                        <div className="mt-8">3. Permitted Uses of PayWithFish.com</div>
                        <p className=" !leading-loose">You may use the Website, including any information, writings, images and/or other content that you see, hear or experience on the Website only for your own lawful purposes, such as to learn about PayWithFish products and services, and in compliance with these Terms.</p>

                        <div className="mt-8">4. Prohibited Uses of PayWithFish.com</div>
                        <p className=" !leading-loose"> By using the Website, you agree, without limitation, not:</p>
                        <ul className="list-disc">
                            <li className="ml-8 !leading-loose"> To violate any federal, state, local or international law or regulation in your use of the Website;</li>
                            <li className="ml-8 !leading-loose"> To impersonate or attempt to impersonate a PayWithFish employee, another user, or any other person or entity;</li>
                            <li className="ml-8 !leading-loose"> To suggest or imply, in any manner not expressly authorized by PayWithFish, endorsement by, affiliation with, or any relationship, partnership, or association of any sort with PayWithFish, including by imitating the look and feel of the Website, by infringing upon PayWithFish’s trademarks or other intellectual property rights, or by using an email address that suggests an affiliation with PayWithFish;</li>
                            <li className="ml-8 !leading-loose"> To access confidential business information, including payer exchange rates, for any purpose not expressly authorized by PayWithFish;</li>
                            <li className="ml-8 !leading-loose"> To transmit any advertising or promotional material, including any junk mail or solicitation;</li>
                            <li className="ml-8 !leading-loose"> To engage in any other conduct that restricts, interferes, or inhibits anyone else's use or enjoyment of the Website, or to use the Website in any manner that could disable, overburden, damage, or impair the Website;</li>
                            <li className="ml-8 !leading-loose"> To harm or expose to liability PayWithFish or users of the Website;</li>
                            <li className="ml-8 !leading-loose"> To use any automatic device, or manual process or means to access the Website for any unauthorized purpose, including monitoring or copying any Website material;</li>
                            <li className="ml-8 !leading-loose"> To introduce any viruses or like material on the Website that is malicious or disruptive or harmful in any way, or to attack the Website in any other way;</li>
                            <li className="ml-8 !leading-loose"> To attempt to gain unauthorized access to, interfere with, damage or disrupt any part of the Website, the server(s) on which the Website is stored, or any server, computer or database connected to the Website;</li>
                            <li className="ml-8 !leading-loose"> To otherwise attempt to interfere with the proper working of the Website.</li>
                        </ul>
                        <div className="mt-8"> 5. Suspension and Termination</div>
                        <p className=" !leading-loose"> PayWithFish alone will determine whether your use of the Website complies with these Terms.You acknowledge and agree that if your use of the Website violates these Terms, we may take any combination of the following actions:</p>
                        <ul className="list-disc">
                            <li className="ml-8 !leading-loose"> Issue a warning;</li>
                            <li className="ml-8 !leading-loose"> Temporarily or permanently suspend your right to use the Website.</li>
                            <li className="ml-8 !leading-loose"> Take legal action against you for any suspected illegal or unauthorized use of the Website;</li>
                            <li className="ml-8 !leading-loose"> Report and describe your behavior and relay any information that we have about you to law enforcement authorities in the Nigeria, or internationally.</li>
                        </ul>
                        <div className="mt-8">6. Intellectual Property Rights</div>
                        <p className=" !leading-loose"> The Website and its contents, features and functionality are owned or licensed by PayWithFish and are protected by Nigeria and international copyright, trademark, trade secret and other intellectual property laws. These Terms permit you to use the Website only for your own personal use. No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted to you are reserved by PayWithFish. Any use of the Website not expressly permitted by these Terms may be considered a violation of copyright, trademark and other laws.</p>
                        <p className=" !leading-loose"> You may not reproduce, distribute, modify, create derivative works of, publicly display, republish, download, store or transmit any of the material, or copies of any of the material, on our Website (including but not limited to using any text, illustrations, photographs, video or audio sequences or any graphics), except (i) in the case of temporary, automatic storage performed by your computer or web browser or (ii) as contemplated and/or authorized by PayWithFish, such as downloading and storing publicly available press releases and other materials available on PayWithFish’s Investor Relations page or other resource pages.</p>

                        <div className="mt-8">7. Your Contributions</div>
                        <p className=" !leading-loose"> If you share, store, upload or otherwise process your information, data, documents, and other content (collectively “Contributions”) via the PayWithFish Website, which may include but is not limited to requesting a demo, asking a question, contacting PayWithFish Support or PayWithFish Sales, you consent to PayWithFish processing your Contributions in accordance with our Privacy Policy. By submitting Contributions, you grant PayWithFish and our affiliates, agents and service providers the right to use, reproduce, modify, display, distribute and otherwise disclose your Contributions to third parties as permitted by applicable law and our Privacy Policy. You represent that your Contributions comply with these Terms, that you own all rights in and to your Contributions and have the right to grant the license granted above to us and our affiliates, agents and service providers, and that you are responsible for your Contributions, including their legality, accuracy and appropriateness.</p>
                        <p className=" !leading-loose"> We are not responsible or liable to any third party for your Contributions. You agree and acknowledge that we have the right:</p>
                        <p className=" !leading-loose"> To disclose information about you to any third party who claims that your Contributions violate their rights;</p>
                        <p className=" !leading-loose"> To fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone sharing Contributions on or through the Website.</p>

                        <div className="mt-8">8. Content Standards</div>
                        <p className=" !leading-loose"> Your Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Your Contributions must not contain:</p>
                        <ul className="list-disc">
                            <li className="ml-8 !leading-loose"> Defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable material;</li>
                            <li className="ml-8 !leading-loose"> Sexually explicit or violent material;</li>
                            <li className="ml-8 !leading-loose"> Material that is discriminatory based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                            <li className="ml-8 !leading-loose"> Material that infringes on any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person;</li>
                            <li className="ml-8 !leading-loose"> Material that is deceptive, promotes illegal activity, or causes annoyance, inconvenience or anxiety or is likely to upset, embarrass, alarm or annoy another person;</li>
                            <li className="ml-8 !leading-loose"> Material that impersonates another person or misrepresent your identity or affiliation with any person or organization, including PayWithFish;</li>
                            <li className="ml-8 !leading-loose"> Advertising or commercial material.</li>
                        </ul>
                        <div className="mt-8">9. Disclaimers</div>
                        <p className=" !leading-loose">The Website and its content are provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied. We update the content on the Website regularly, but we do not certify that the information on the Website is fully complete or up-to-date except where required by law. Likewise, functionality of the Website (including but not limited to your means of access to and use of the Website) may vary based upon said means of access or use.</p>

                        <div className="mt-8"> 10. Third-Party Content</div>
                        <p className=" !leading-loose"> The Website may include content provided by third parties. All statements and/or opinions expressed in such content are solely the opinions and the responsibility of the person or entity providing the content and do not necessarily reflect the opinion of PayWithFish.</p>
                        <p className=" !leading-loose"> If and when the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. We do not control the contents of those sites or resources, and we accept no responsibility for their substance or accuracy or for any loss or damage that may arise from your use of them. If you decide to access any of the third party sites linked to from the Website, you do so at your own risk.</p>

                        <div className="mt-8">11. Downloading Files from PayWithFish.com</div>
                        <p className=" !leading-loose">  We do not guarantee that files available for download from the Website will be free of viruses or other virus-like material. You should implement procedures to satisfy your particular requirements for anti-virus protection and for maintaining a means for any reconstruction of any lost data. PayWithFish is not liable for any loss or damage caused by viruses or other technologically harmful material that may infect your computer hardware, computer software, or data because of your use of the Website or any services or items obtained through the Website.</p>

                        <div className="mt-8">12. Limitations on Liability and Indemnification</div>
                        <p className=" !leading-loose">  A. Limitation on Liability</p>
                        <p className=" !leading-loose">  IN NO EVENT SHALL PAYWITHFISH, ITS AFFILIATES, AGENTS, EMPLOYEES, OFFICERS OR DIRECTORS BE LIABLE TO YOU FOR DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOSSES, OR EXPENSES UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE OR ANY SITES LINKED TO THROUGH THE WEBSITE, INCLUDING ANY ERROR, INTERRUPTION, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR SYSTEM FAILURE, WHETHER OR NOT PAYWITHFISH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                        <p className=" !leading-loose">  B. Indemnification</p>
                        <p className=" !leading-loose">  YOU AGREE TO INDEMNIFY AND HOLD HARMLESS PAYWITHFISH, ITS AFFILIATES, AND ITS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, SUCCESSORS AND ASSIGNS FROM AND AGAINST ANY CLAIMS, LIABILITIES, DAMAGES, JUDGMENTS, AWARDS, LOSSES, COSTS, EXPENSES OR FEES (INCLUDING REASONABLE ATTORNEYS' FEES) ARISING OUT OF OR RELATING TO YOUR VIOLATION OF THESE TERMS OR YOUR USE OF THE WEBSITE, INCLUDING BUT NOT LIMITED TO ANY USE OF THE WEBSITE’S CONTENT OTHER THAN AS AUTHORIZED IN THESE TERMS, OR YOUR USE OF ANY INFORMATION OBTAINED FROM THE WEBSITE.</p>

                        <div className="mt-8"> 13. General Provisions</div>
                        <p className=" !leading-loose">   These Terms and your use of the Website are governed by, and will be interpreted in accordance with, the laws of the Commonwealth of Massachusetts without regard to conflict of law principles. You agree to the sole and exclusive jurisdiction and venue of the federal or state courts located within Suffolk County, Massachusetts for the purpose of litigating any dispute.</p>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Terms;