import { useRef, useEffect } from "react";
import { loadingBarRef } from "../App";
import { toast } from "react-toastify";

export function getAbsoluteUrl(path: string) {
    return process.env.PUBLIC_URL + path;
}

export function getIcon(name: string) {
    return process.env.PUBLIC_URL + `/media/icons/${name}`;
}

export function getImage(name: string) {
    return process.env.PUBLIC_URL + `/media/images/${name}`;
}

export function getLogo(name: string) {
    return process.env.PUBLIC_URL + `/media/logos/${name}`;
}

export function getSVG(name: string) {
    return process.env.PUBLIC_URL + `/media/svg/${name}`;
}

export const formatToCurrency = (amount: number | string) => {
    return Intl.NumberFormat('en-GB', {
    }).format(Number(amount))
};

export const APICall = async (fn: (args?: any) => Promise<any>, args?: any, showSuccessToast?: boolean) => {
    try {
        loadingBarRef.current?.continuousStart();
        const response = await fn(args)
        if (showSuccessToast)
            toast(response.data.message, { type: "success" });
        loadingBarRef.current?.complete();
        return response;
    } catch (error: any) {
        if (error.response) {
            toast(error.response.data.message, { type: "error" });
        }
        loadingBarRef.current?.complete();
        throw error;
    }
}

export async function dataURLtoFile(dataurl: string, filename: string) {
    const res = await fetch(dataurl);
    const blob = await res.blob();
    return new File([blob], filename, { type: "image/png" })
}


function abbreviateNumber(value: number) {
    let newValue = value.toString();
    if (value >= 1000) {
        let suffixes = ["", "k", "m", "b", "t"];
        let suffixNum = Math.floor(("" + value).length / 3);
        let shortValue: string | number = 0;
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
            let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
}

export const useDimensions = (ref: any) => {
    const dimensions = useRef({ width: 0, height: 0 });

    useEffect(() => {
        dimensions.current.width = ref.current.offsetWidth;
        dimensions.current.height = ref.current.offsetHeight;
    }, []);

    return dimensions.current;
};


export const API_URL = "https://paywithfish.ddns.net/api"
